import Questions from './Questions'

import FileAttachmentField from './FileAttachments/FileAttachmentField'

function AttachmentsBody() {
    return (
        <>
            <p>Please attach any relevant Specialist or Hospital Reports</p>

            <FileAttachmentField />

            <Questions />
        </>
    )
}

export default AttachmentsBody
