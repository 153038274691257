import Grid from '@mui/material/Grid'

import LabItem from './LabItem'
import { SectionNoteText } from '../../../fieldComponents'
import { EmptyTypography } from '../../../styledComponents/StyledTableRow'
import useArrayUtils from '../../../../../../hooks/useArrayUtils'

function LaboratoryStudiesBody({ values }) {
    const { isArrayEmpty } = useArrayUtils()

    return (
        <Grid container spacing={2}>
            {isArrayEmpty(values) ? (
                <EmptyTypography
                    variant='body2'
                    sx={{ px: 2 }}
                >
                    No information available from previous 5 years
                </EmptyTypography>
            ) : (
                <>
                    <SectionNoteText>
                        Items retrieved from 5 years previous
                    </SectionNoteText>
                    {values.map((lab, index) => <LabItem key={index} index={index} lab={lab} />)}
                </>
            )}
        </Grid>
    )
}

export default LaboratoryStudiesBody