import Grid from '@mui/material/Grid'

export default function Logo({ encodedImg }) {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <img src={`data:image/jpeg;base64,${encodedImg}`} style={{ maxHeight: 100, maxWdth: 300, margin: 'auto' }} />
            </Grid>
        </Grid>
    )
}