import moment from 'moment'

import PatientDetails from '../components/PatientDetails'
import DoctorDetails from '../components/DoctorDetails'
import SocialHistory from '../components/SocialHistory'
import HeartDiseaseDetails from './HeartDiseaseDetails'
import Medications from '../components/Medications'
import ClinicalExaminations from '../components/ClinicalExamination'
import Attachments from '../components/Attachments'

function HeartDisease({ data }) {

    if (!data) return null

    return (
        <div>
            <h2>{data.request.formName}</h2>
            <hr />
            <p>Report generated: {moment().format('DD/MM/yyyy')}</p>
            <p>Policy No: {data.request.primaryReferenceNumber}</p>
            <hr />
            <PatientDetails data={data} />
            <hr />
            <DoctorDetails data={data} />
            <hr />
            <h3>Recent Summary</h3>
            <br />
            <SocialHistory data={data} />
            <br />
            <ClinicalExaminations exam={data.conditionSpecific.mostRecentSummary.clinicalExamination} />
            <br />
            <Medications data={data} />
            <hr />
            <HeartDiseaseDetails data={data} />
            <hr />
            <Attachments data={data} />
        </div>
    )
}

export default HeartDisease
