import Component from './Stroke'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./stroke.css');
var css = cssModule.default;

const strokeConfig = {
    component: Component,
    css: css
}

export default strokeConfig