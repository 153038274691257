import { useState } from 'react'
import { useRecoilState } from 'recoil'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

import { isSubmittingAtom } from './requestState'

export default function SubmitDialog({ show, onSubmit, onClose }) {
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [isSubmitting, setIsSubmitting] = useRecoilState(isSubmittingAtom)

    if (!show) {
        return null
    }

    const termsCheckbox = (
        <Checkbox
            disabled={isSubmitting}
            checked={termsAccepted}
            onChange={e => setTermsAccepted(e.target.checked)}
        />
    )

    const termsLabel = (
        <>
            By clicking submit you agree to the
            {' '}
            <Link
                href='https://clanwilliamhealth.com/socrates-tcs/'
                color='primary'
                underline='always'
                target='_blank'
                rel='noreferrer'
            >
                Website Terms of Use
            </Link>
        </>
    )

    const onSubmitClicked = () => {
        if (!termsAccepted) {
            setTermsAccepted(true)
        }

        setIsSubmitting(true)
        onSubmit()
    }

    return (
        <Dialog
            open={show}
        >
            <DialogTitle>Submit request to insurer</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <FormControlLabel
                        label={termsLabel}
                        control={termsCheckbox}
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant='contained'
                    onClick={onSubmitClicked}
                    loading={isSubmitting}
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}