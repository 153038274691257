import RenderField from './RenderField'

function ClinicalExaminations({ exam }) {
    return (
        <>
            <h5>Clinical Examination</h5>

            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Value</th>
                        <th>Date recorded</th>
                    </tr>
                </thead>
                <tbody>
                    {!exam && (
                        <tr><td colSpan={4}>No information available</td></tr>
                    )}
                    {exam && (
                        <>
                            <tr>
                                <RenderField
                                    label='Systolic'
                                    field={exam.systolicBloodPressure}
                                />
                            </tr>
                            <tr>
                                <RenderField
                                    label='Diastolic'
                                    field={exam.diastolicBloodPressure}
                                />
                            </tr>
                            <tr>
                                <RenderField
                                    label='Height'
                                    field={exam.height}
                                />
                            </tr>
                            <tr>
                                <RenderField
                                    label='Weight'
                                    field={exam.weight}
                                />
                            </tr>
                            <tr>
                                <RenderField
                                    label='BMI'
                                    field={exam.bmi}
                                />
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </>
    )
}

export default ClinicalExaminations
