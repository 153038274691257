import AppGlobalConfig from './AppGlobalConfig'
import AppRoutes from './AppRoutes'
import { RecoilRoot } from 'recoil'

export default function App() {
    return (
        <div className="App">
            <RecoilRoot>
                <AppGlobalConfig>
                    <AppRoutes />
                </AppGlobalConfig>
            </RecoilRoot>
        </div>
    );
}
