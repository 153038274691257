import Component from './MultipleSclerosis'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./multipleSclerosis.css');
var css = cssModule.default;

const multipleSclerosisConfig = {
    component: Component,
    css: css
}

export default multipleSclerosisConfig