import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import OutputDetails from '../components/output/OutputDetails'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function RespiratoryDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Respiratory Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>Nature of respiratory condition:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.natureOfCondition.referToAttachments}>
                        <p>
                            {questions.natureOfCondition.value != ''
                                ? questions.natureOfCondition.value
                                : questions.natureOfCondition.details
                            }
                        </p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='2'>
                    <Question>Date of diagnosis:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.diagnosis.referToAttachments}>
                        <p>{formatDateMonthYear(questions.diagnosis.subQuestions.dateofDiagnosis)}</p>
                        <Question>Date of last exacerbation:</Question>
                        <p>{formatDateMonthYear(questions.diagnosis.subQuestions.dateOfLastExacerbation)}</p>
                        <Question>Constant/Recurrent:</Question>
                        <p>{questions.diagnosis.subQuestions.constantOrRecurrent}</p>
                        <Question>Length of last symptoms:</Question>
                        <p>{questions.diagnosis.subQuestions.lengthOfLastSymptoms}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Is the condition:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.condition.referToAttachments}>
                        <p>{questions.condition.subQuestions.severity}</p>

                        <Question>Please describe your patient’s functional capacity/exercise tolerance:</Question>
                        <p>{questions.condition.subQuestions.functionalCapacity}</p>

                        <Question
                            answer={questions.condition.subQuestions.symptomsProgression}
                        >
                            Are symptoms:
                        </Question>

                        <Question>Please describe your patient’s stage as per the NYHA?</Question>
                        <p>{questions.condition.subQuestions.describeNYHAStage}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='4'>
                    <Question>Please confirm the date & results of last CXR, PFTs (FEV1 and/or PEF), CT scan:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.recentResults.referToAttachments}>
                        <OutputDetails
                            details={questions.recentResults.details}
                        />
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='5'>
                    <Question>Has your patient required inpatient treatment for their respiratory condition?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.inpatientTreatmentRequired.referToAttachments}>
                        <p>{questions.inpatientTreatmentRequired.value}</p>
                        {questions.inpatientTreatmentRequired.value === 'Yes' && (
                            <>
                                <Question>If yes, please confirm details</Question>
                                <p>{questions.inpatientTreatmentRequired.details}</p>
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='6'>
                    <Question>Does your patient smoke?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.smoking.referToAttachments}>
                        <p>{questions.smoking.subQuestions.isSmoker}</p>


                        {questions.smoking.subQuestions.isSmoker === 'Yes' && (
                            <>
                                <Question>If yes, how many per day?</Question>
                                <p>{questions.smoking.subQuestions.cigarettesPerDay}</p>
                            </>
                        )}

                        {questions.smoking.subQuestions.isSmoker === 'No' && (
                            <>
                                <Question>Are they a previous smoker?</Question>
                                <p>{questions.smoking.subQuestions.isPreviousSmoker}</p>

                                {questions.smoking.subQuestions.isPreviousSmoker === 'Yes' && (
                                    <>
                                        <Question>If yes, how many per day for how long?</Question>
                                        <p>{questions.smoking.subQuestions.previousSmokerDetails}</p>

                                        <Question>Date patient ceased smoking:</Question>
                                        <p>{formatDateMonthYear(questions.smoking.subQuestions.dateCeasedSmoking)}</p>
                                    </>
                                )}
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='7'>
                    <Question>Has your patient required surgery?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.patientSurgery.referToAttachments}>
                        <p>{questions.patientSurgery.value}</p>
                        {questions.patientSurgery.value === 'Yes' && (
                            <>
                                <Question>If yes, please confirm details</Question>
                                <p>{questions.patientSurgery.details}</p>
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='8'>
                    <Question>Any associated medical conditions or complications?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyAssociatedConditionsOrComplications.referToAttachments}>
                        <p>{questions.anyAssociatedConditionsOrComplications.value}</p>
                        {questions.anyAssociatedConditionsOrComplications.value === 'Yes' && (
                            <>
                                <Question>If yes, please confirm details</Question>
                                <p>{questions.anyAssociatedConditionsOrComplications.details}</p>
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='9'>
                    <Question>Has the condition caused any time off work?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyTimeOffWorkCaused.referToAttachments}>
                        <p>{questions.anyTimeOffWorkCaused.value}</p>
                        {questions.anyTimeOffWorkCaused.value === 'Yes' && (
                            <>
                                <Question>If yes, please confirm details</Question>
                                <p>{questions.anyTimeOffWorkCaused.details}</p>
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}