import { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'


import DatePicker from '../../../../../fieldComponents/table/TableDatePicker'
import IncludableTableRow from '../../../../../fieldComponents/table/IncludableTableRow'
import StyledNoBottomBorderTableCell from '../../../../../styledComponents/StyledNoBottomBorderTableCell'
import { splitDateAndNote, combineDateAndNote } from '../../generalHistoryUtils'


export default function NewItem({ item, onChange, onRemove }) {

    const [isEditing, setIsEditing] = useState(false)
    const [initialValues, setInitialValues] = useState({
        date: null,
        note: null
    })

    const validationSchema = Yup.object({
        date: Yup.string()
            .ensure()
            .required('Date is required')
            .test('isValidDate', 'Date must be in format DD/MM/YYYY', (val) => {
                if (!val) {
                    return false
                }

                return moment(val, 'DD/MM/yyyy', true).isValid()
            }),
        note: Yup.string().required('Note is required')
    })

    useEffect(() => {
        const { date, note } = splitDateAndNote(item.value)

        setInitialValues({
            date: date,
            note: note
        })
    }, [item])

    function moveCaretToEnd(e) {
        // Move caret to end of text when input gets focus (appears at start by default)
        var tempValue = e.target.value
        e.target.value = ''
        e.target.value = tempValue
    }

    function onSubmit(values, { resetForm }) {
        const updatedItem = {
            value: combineDateAndNote(values.date, values.note),
            addedOnPortal: true
        }

        onChange(updatedItem)

        // Reset form values
        resetForm(initialValues)

        // Set editing flag to false
        setIsEditing(false)
    }

    return (
        <IncludableTableRow isEditing={isEditing}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {(formik) => {
                    if (isEditing) {
                        return (
                            <TableCell colSpan={4}>
                                <Table size="small">
                                    <TableRow>
                                        <StyledNoBottomBorderTableCell sx={{ width: '200px' }}>
                                            <DatePicker
                                                date={formik.values.date}
                                                onChange={date => formik.setFieldValue('date', date)}
                                                error={formik.errors.date && formik.touched.date}
                                                helperText={formik.touched.date && formik.errors.date}
                                            />
                                        </StyledNoBottomBorderTableCell>
                                        <StyledNoBottomBorderTableCell>
                                            <TextField
                                                size="small"
                                                multiline
                                                fullWidth
                                                name='note'
                                                onFocus={moveCaretToEnd}
                                                value={formik.values.note}
                                                onChange={formik.handleChange}
                                                error={formik.errors.note && formik.touched.note}
                                                helperText={formik.touched.note && formik.errors.note}
                                            />
                                        </StyledNoBottomBorderTableCell>
                                        <StyledNoBottomBorderTableCell colSpan={2}>
                                            <Stack direction="row">
                                                <IconButton
                                                    color='success'
                                                    onClick={formik.handleSubmit}
                                                >
                                                    <DoneIcon />
                                                </IconButton>
                                                <IconButton
                                                    color='error'
                                                    onClick={() => {
                                                        // Reset form values
                                                        formik.resetForm()
                                                        // Leave edit mode
                                                        setIsEditing(false)
                                                    }}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </Stack>
                                        </StyledNoBottomBorderTableCell>
                                    </TableRow>
                                </Table>
                            </TableCell>
                        )
                    } else {
                        return (
                            <>
                                <TableCell>
                                    {formik.values.date}
                                </TableCell>
                                <TableCell>
                                    {formik.values.note}
                                </TableCell>
                                <TableCell align="center" padding="checkbox">
                                    <IconButton color='primary' onClick={() => setIsEditing(true)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center" padding="checkbox">
                                    <IconButton size="small" color="error" onClick={onRemove}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </>
                        )
                    }
                }}
            </Formik>
        </IncludableTableRow>
    )
}