import { useRecoilValue } from 'recoil'
import { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Dialog from '@mui/material/Dialog'

import { errorAtom } from './state/errorState'

export default function ErrorNotification() {
    const [open, setOpen] = useState(false)
    const errorState = useRecoilValue(errorAtom)

    useEffect(() => {
        if (errorState !== undefined) {
            setOpen(true)
        }
    }, [errorState])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    if (open) {
        return (
            <>
                <Dialog open={open}>
                    <Snackbar
                        sx={{ height: "100%" }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={open}
                        onClose={handleClose}
                    >
                        <Alert
                            onClose={handleClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            <AlertTitle>Error</AlertTitle>
                            {errorState.header}
                            <br />
                            {errorState.message}
                        </Alert>
                    </Snackbar>
                </Dialog>
            </>
        )
    }

    return null
}
