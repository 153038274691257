import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import LabFilter from './LabFilter'
import LabList from './LabList'

export default function LabSearch(props) {
    const open = props.open || false
    const [selectedItems, setSelectedItems] = useState([])

    const handleClose = () => {
        props.onClose()
        setSelectedItems([])
    }

    const handleSelectAndClose = () => {
        props.onLabsSelected(selectedItems)
        handleClose()
    }

    return (
        <Dialog open={open} onClose={handleClose}
            fullWidth
            maxWidth='md'
            PaperProps={{
                sx: {
                    height: '90vh'
                }
            }}
        >
            <DialogTitle>Lab Search</DialogTitle>
            <DialogContent>
                <LabFilter />
                <LabList onSelectionChange={setSelectedItems} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    onClick={handleSelectAndClose}
                >
                    Select items
                </Button>
            </DialogActions>
        </Dialog>
    )
}