import moment from 'moment'

export default () => {

    const formatDate = (date) => {
        return moment(date).format('DD/MM/yyyy')
    }

    return {
        formatDate
    }
}

export const formatDateMonthYear = (date) => {
    return moment(date, 'DD/MM/yyyy').format('MMMM YYYY')
}