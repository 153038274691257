import { useRecoilState, useRecoilValue } from 'recoil'

import useRequestDataBuilder from './useRequestDataBuilder'
import usePdfUtils from './usePdfUtils'
import useArrayUtils from './useArrayUtils'
import useBufferUtils from './useBufferUtils'

import { requestAtom, completedRequestAtom } from '../views/request/requestState'
import { attachmentFilesAtom } from '../views/request/wizard/formComponents/attachments/state'

export default function useReportGenerator() {
    const [completedRequest, setCompletedRequest] = useRecoilState(completedRequestAtom)

    const attachments = useRecoilValue(attachmentFilesAtom)
    const requestState = useRecoilValue(requestAtom)

    const builder = useRequestDataBuilder()
    const pdfUtils = usePdfUtils()
    const { isArrayEmpty } = useArrayUtils()
    const { convertArrayBufferToBase64String, convertBase64StringToArrayBuffer } = useBufferUtils()

    async function ensureReportGenerated() {
        //TODO: AJ - Check if report was generated already

        // Call the report generator to build the report
        const report = await generate()

        // Save the report to state
        setCompletedRequest({
            ...completedRequest,
            reportData: report.data,
            reportPdf: report.base64
        })
    }

    /**
     * Generate output pdf for report
     * @returns Object containing:
     * - report data in json format
     * - report pdf in base64 string format
     */
    async function generate() {
        // Build up the data model for the report
        const formData = builder.build()

        const title = generateTitle(formData)

        // Configure pdf metadata
        const pdfBuilderData = {
            formType: requestState.form.type,
            title: title,
            formData: formData
        }

        // Generate the pdf for the report
        var reportBuffer = await pdfUtils.generateOutputPdfBuffer(pdfBuilderData)

        // Check for attachments, if they exist append them to the report
        if (!isArrayEmpty(attachments)) {
            // Convert attachments from base64 to buffers
            let attachmentBuffers = attachments.map(a => convertBase64StringToArrayBuffer(a.content))

            // Merge report & attachments into single pdf            
            reportBuffer = await pdfUtils.mergePdfs([ reportBuffer, ...attachmentBuffers], title)
        }

        // Convert pdf from buffer into base64 string
        const base64 = convertArrayBufferToBase64String(reportBuffer)

        const report = {
            data: formData,
            base64: base64
        }

        return report
    }

    function generateTitle(formData){
        // Extract patient name
        const patientName = formData.patient.patientName
        const fullname = `${patientName.firstname} ${patientName.surname}`

        // Include patient name + form name in title
        return `${fullname} - ${requestState.form.name}`
    }

    return {
        ensureReportGenerated
    }
}
