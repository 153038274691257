export default function LaboratoryStudy({ lab }) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Test</th>
                    <th>Result</th>
                    <th>Units</th>
                    <th>Range</th>
                    <th>Abnormal</th>
                </tr>
            </thead>
            <tbody>
                {lab.results.map(r => (
                    <tr key={r.identifier}>
                        <td>{r.identifier.text}</td>
                        <td>{r.value}</td>
                        <td>{r.units.text}</td>
                        <td>{r.range}</td>
                        <td>{r.abnormalFlags}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
