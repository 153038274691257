import { useRecoilValue } from 'recoil'
import { isUserTypeDoctorSelector } from '../../../state/pmsState'
import { isConditionSpecific } from '../../../hooks/formTypeUtils'

import { FORM_CODES } from '../../../constants'

import {
    pmarConfig,
    missingInformationConfig,
    tumourConfig,
    mentalHealthConfig,
    strokeConfig,
    multipleSclerosisConfig,
    sarcoidosisConfig,
    heartDiseaseConfig,
    hepatitisConfig,
    kidneyConfig,
    respiratoryConfig,
    diabetesConfig
} from './forms'

export default () => {
    const isUserDoctorType = useRecoilValue(isUserTypeDoctorSelector)

    const getFormByType = (formType) => {
        // Get object containing the components for the formType specified
        const formConfig = ({
            [FORM_CODES.pmar]: pmarConfig,
            [FORM_CODES.mir]: missingInformationConfig,
            [FORM_CODES.tumour]: tumourConfig,
            [FORM_CODES.mentalHealth]: mentalHealthConfig,
            [FORM_CODES.stroke]: strokeConfig,
            [FORM_CODES.multipleSclerosis]: multipleSclerosisConfig,
            [FORM_CODES.sarcoidosis]: sarcoidosisConfig,
            [FORM_CODES.heartDisease]: heartDiseaseConfig,
            [FORM_CODES.hepatitis]: hepatitisConfig,
            [FORM_CODES.kidney]: kidneyConfig,
            [FORM_CODES.respiratory]: respiratoryConfig,
            [FORM_CODES.diabetes]: diabetesConfig
        })[formType]

        if (!isUserDoctorType) {
            // If logged on user is not a doctor, hide any steps that are restricted to a doctor user type
            // This is desiged to stop the nurse user opening the Submit/Payment tab and accessing the
            // payment dropdown with IBAN details etc.
            formConfig.steps = formConfig.steps.filter(e => e.restrictToDoctor === false)
        }

        // Check if form is a Condition Specific Questionnaire (these need to be handled differently when saving)
        formConfig.isConditionSpecific = isConditionSpecific(formType)

        return formConfig
    }


    return {
        getFormByType
    }
}