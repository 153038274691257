import { atom, selector } from 'recoil'

export const requestLoadingAtom = atom({
    key: 'requestLoadingState',
    default: true
})

export const requestViewStateAtom = atom({
    key: 'requestViewState',
    default: {
        activeStep: 0
    }
})

export const activeStepSelector = selector({
    key: 'activeStep',
    get: ({ get }) => get(requestViewStateAtom).activeStep,
    set: ({ set, get }, newActiveStep) => {
        set(requestViewStateAtom, {
            ...get(requestViewStateAtom),
            activeStep: newActiveStep
        })
    }
})

export const patientAtom = atom({
    key: 'patientState',
    default: {}
})

export const practiceAtom = atom({
    key: 'practiceState',
    default: {}
})

export const requestAtom = atom({
    key: 'request',
    default: {}
})

export const requestIdSelector = selector({
    key: 'requestId',
    get: ({ get }) => get(requestAtom).id
})

export const paymentDetailsAtom = atom({
    key: 'paymentDetails',
    default: {
        account: {},
        fee: { includeVat: false, net: 0, vatRate: 0, vatAmount: 0, gross: 0 }
    }
})

export const isSubmittingAtom = atom({
    key: 'isSubmitting',
    default: false
})

export const completedRequestAtom = atom({
    key: 'completedRequestData',
    default: {
        reportData: null,
        reportPdf: null,
        invoicePdf: null
    }
})
