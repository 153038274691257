import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'

import { DependentInput } from '../../fieldComponents'
import { hepatitisTypeOptions, yesNoOptions } from '../../../../../constants'

export default function HepatitisQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date of diagnosis?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.dateOfDiagnosis.value'
                        disabled={formik.values.questions?.dateOfDiagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='dateOfDiagnosis' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Please confirm exact type of hepatitis
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.typeOfHepatitis.value'
                        disabled={formik.values.questions?.typeOfHepatitis.referToAttachments}
                        options={hepatitisTypeOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='typeOfHepatitis' />
                </Grid>

                <DependentInput condition={formik.values.questions?.typeOfHepatitis.referToAttachments == false && formik.values.questions.typeOfHepatitis.value == ''}>
                    <Grid item xs={1} />
                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            Other
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <MyTextField
                            name='questions.typeOfHepatitis.details'
                            disabled={formik.values.questions?.typeOfHepatitis?.referToAttachments}
                        />
                    </Grid>
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please provide details of most recent serology results
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='detailsOfSerologyResults' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.detailsOfSerologyResults.value'
                        disabled={formik.values.questions?.detailsOfSerologyResults?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Has your patient had a liver ultrasound or fibroscan?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='hadLiverScan' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <MyRadioGroup
                        name='questions.hadLiverScan.value'
                        disabled={formik.values.questions?.hadLiverScan?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <DependentInput condition={formik.values.questions.hadLiverScan.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm results
                        </Typography>

                        <MyTextField
                            name='questions.hadLiverScan.details'
                            disabled={formik.values.questions?.hadLiverScan?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        5.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please provide date and results of most recent liver function and alpha feta protein tests
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='detailsOfLiverFunction' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.detailsOfLiverFunction.value'
                        disabled={formik.values.questions?.detailsOfLiverFunction?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        6.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        How often do they attend the liver clinic?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='howOftenAttendingLiverClinic' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.howOftenAttendingLiverClinic.value'
                        disabled={formik.values.questions?.howOftenAttendingLiverClinic?.referToAttachments}
                    />
                </Grid>
            </Grid>

        </Grid>
    )
}