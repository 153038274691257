const questions = {
    typeOfDiabetes: {
        value: '',
        referToAttachments: false
    },
    dateOfDiagnosis: {
        value: '',
        referToAttachments: false
    },
    results: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            hbA1c: {
                unknown: false,
                results: []
            },
            cholesterol: {
                unknown: false,
                results: []
            }
        }
    },
    anyEvidenceOf: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            retinopathy: '',
            nephropathy: '',
            neuropathy: '',
            ihd: '',
            peripheralVascularDisease: '',
            details: ''
        }
    },
    commentOnPatient: {
        value: '',
        referToAttachments: false
    },
    anyOtherRiskFactors: {
        value: '',
        referToAttachments: false,
        details: ''
    }
}

export default questions
