import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import LabResults from '../components/output/LabResults'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function DiabetesDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Diabetes Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>Please confirm the type of diabetes?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.typeOfDiabetes.referToAttachments}>
                        <p>{questions.typeOfDiabetes.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='2'>
                    <Question>Please confirm the date of diagnosis?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.dateOfDiagnosis.referToAttachments}>
                        <p>{formatDateMonthYear(questions.dateOfDiagnosis.value)}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Please give the following information:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.results.referToAttachments}>
                        <br />
                        <Question>HbA1c results:</Question>
                        <LabResults
                            unknown={questions.results.subQuestions.hbA1c.unknown}
                            results={questions.results.subQuestions.hbA1c.results}
                        />
                        <br />
                        <Question>Cholesterol results:</Question>
                        <LabResults
                            unknown={questions.results.subQuestions.cholesterol.unknown}
                            results={questions.results.subQuestions.cholesterol.results}
                        />
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>


                <QuestionWrapper number='4'>
                    <Question>Is there any evidence of the following?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyEvidenceOf.referToAttachments}>
                        <Question
                            newLine
                            answer={questions.anyEvidenceOf.subQuestions.retinopathy}
                        >
                            Retinopathy:
                        </Question>
                        <Question
                            newLine
                            answer={questions.anyEvidenceOf.subQuestions.nephropathy}
                        >
                            Nephropathy:
                        </Question>
                        <Question
                            newLine
                            answer={questions.anyEvidenceOf.subQuestions.neuropathy}
                        >
                            Neuropathy:
                        </Question>
                        <Question
                            newLine
                            answer={questions.anyEvidenceOf.subQuestions.ihd}
                        >
                            IHD:
                        </Question>
                        <Question
                            newLine
                            answer={questions.anyEvidenceOf.subQuestions.peripheralVascularDisease}
                        >
                            Peripheral vascular disease:
                        </Question>

                        {Object.values(questions.anyEvidenceOf.subQuestions).includes('Yes') && (
                            <Question
                                newLine
                                answerOnNewLine
                                answer={questions.anyEvidenceOf.subQuestions.details}
                            >
                                If yes to any, please give details:
                            </Question>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>


                <QuestionWrapper number='5'>
                    <Question>Please comment on your patient’s insight into his/her condition, compliance with treatment and overall control of diabetes.</Question>
                    <ReferrableQuestionWrapper isReferred={questions.commentOnPatient.referToAttachments}>
                        <p>{questions.commentOnPatient.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>


                <QuestionWrapper number='6'>
                    <Question>Does your patient have any other cardiovascular risk factors that you are aware of?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyOtherRiskFactors.referToAttachments}>
                        <p>{questions.anyOtherRiskFactors.value}</p>

                        <Question
                            show={questions.anyOtherRiskFactors.value === 'Yes'}
                            answer={questions.anyOtherRiskFactors.details}
                        >
                            If yes, please outline:
                        </Question>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}