import Component from './Diabetes'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./diabetes.css');
var css = cssModule.default;

const diabetesConfig = {
    component: Component,
    css: css
}

export default diabetesConfig