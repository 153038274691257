import { useRecoilValue } from 'recoil'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { requestAtom } from '../../../requestState'
import { InsurerNote, SectionNoteText } from '../../fieldComponents'
import SummaryComponent from './summary/Summary'

export default function ConditionSpecific({ conditionSpecificNote, Questions }) {

    // Get request context to access insurer note for request
    const request = useRecoilValue(requestAtom)

    return (
        <Stack spacing={4}>

            <Grid container spacing={3}>
                {/* Output insurer notes */}
                {request.insurerNotes && (
                    <Grid item container>
                        <InsurerNote note={request.insurerNotes} />
                    </Grid>
                )}


                <Grid item>
                    <SectionNoteText>
                        {conditionSpecificNote}
                    </SectionNoteText>
                </Grid>
            </Grid>

            <SummaryComponent />

            <Grid container spacing={1} alignItems='baseline'>
                <Grid item xs={12} mb={4}>
                    <Typography variant='body2'>
                        Please answer the following questions or click the adjacent button to “refer to the attached reports” and include them at the <strong><i>File Attachments</i></strong> stage.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    {Questions}
                </Grid>
            </Grid>
            
        </Stack>
    )
}