import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import DetailsControl from '../../formComponents/conditionSpecific/details/DetailsControl'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'

import { DependentInput } from '../../fieldComponents'
import { yesNoOptions } from '../../../../../constants'

export default function MentalHealthQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please confirm the exact diagnosis?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='confirmExactDiagnosis' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.confirmExactDiagnosis.value'
                        disabled={formik.values.questions?.confirmExactDiagnosis?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        What were the presenting symptoms?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='presentingSymptoms' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.presentingSymptoms.value'
                        disabled={formik.values.questions?.presentingSymptoms?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        When did symptoms first occur?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.dateOfFirstSymptoms.value'
                        disabled={formik.values.questions?.dateOfFirstSymptoms?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='dateOfFirstSymptoms' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date of last symptoms?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.dateOfLastSymptoms.value'
                        disabled={formik.values.questions?.dateOfLastSymptoms?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='dateOfLastSymptoms' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        5.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Within the past five years please confirm:
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='past5Years' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid container item xs={10} rowSpacing={1}>
                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            a.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            number of episodes
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MyTextField
                            name='questions.past5Years.subQuestions.numOfEpisodes.value'
                            disabled={formik.values.questions?.past5Years?.referToAttachments}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            b.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            has there been any hospital admissions?
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MyRadioGroup
                            name='questions.past5Years.subQuestions.anyInpatientAdmissions.value'
                            disabled={formik.values.questions?.past5Years?.referToAttachments}
                            options={yesNoOptions}
                        />
                    </Grid>

                    <DependentInput condition={formik.values.questions.past5Years.subQuestions.anyInpatientAdmissions.value == 'Yes'}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2'>
                                number of inpatient admissions
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <MyTextField
                                name='questions.past5Years.subQuestions.anyInpatientAdmissions.subQuestions.numOfInpatientAdmissions.value'
                                disabled={formik.values.questions?.past5Years?.referToAttachments}
                            />
                        </Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2'>
                                date of last admission: from:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <DateField
                                name='questions.past5Years.subQuestions.anyInpatientAdmissions.subQuestions.dateOfLastAdmissionFrom.value'
                                disabled={formik.values.questions?.past5Years?.referToAttachments}
                            />
                        </Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2'>
                                date of last admission: to:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <DateField
                                name='questions.past5Years.subQuestions.anyInpatientAdmissions.subQuestions.dateOfLastAdmissionTo.value'
                                disabled={formik.values.questions?.past5Years?.referToAttachments}
                            />
                        </Grid>
                    </DependentInput>

                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            c.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            any episodes of self-harm, suicidal ideation or attempts?
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MyRadioGroup
                            name='questions.past5Years.subQuestions.anyEpisodesOfSelfHarmEtc.value'
                            disabled={formik.values.questions?.past5Years?.referToAttachments}
                            options={yesNoOptions}
                        />
                    </Grid>

                    <DependentInput condition={formik.values.questions.past5Years.subQuestions.anyEpisodesOfSelfHarmEtc.value == 'Yes'}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Typography variant='body2' gutterBottom>
                                If yes, please provide details and dates
                            </Typography>

                            <DetailsControl
                                name='questions.past5Years.subQuestions.anyEpisodesOfSelfHarmEtc.details'
                                disabled={formik.values.questions?.past5Years?.referToAttachments}
                            />
                        </Grid>
                    </DependentInput>

                </Grid>

            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        6.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Has the mental health disorder caused any absence from work greater than two weeks in the last five years?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyRelatedAbsenceFromWork' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <MyRadioGroup
                        name='questions.anyRelatedAbsenceFromWork.value'
                        disabled={formik.values.questions?.anyRelatedAbsenceFromWork?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <DependentInput condition={formik.values.questions.anyRelatedAbsenceFromWork.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please provide details and dates
                        </Typography>

                        <DetailsControl
                            name='questions.anyRelatedAbsenceFromWork.details'
                            disabled={formik.values.questions?.anyRelatedAbsenceFromWork?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        7.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Are there any other relevant features which may influence the prognosis of the disorder such as any history of alcohol or substance abuse, co-existing physical illness, behaviour abnormalities etc?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyRelevantFeaturesInfluencingPrognosis' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <MyRadioGroup
                        name='questions.anyRelevantFeaturesInfluencingPrognosis.value'
                        disabled={formik.values.questions?.anyRelevantFeaturesInfluencingPrognosis?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <DependentInput condition={formik.values.questions.anyRelevantFeaturesInfluencingPrognosis.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please provide details and dates
                        </Typography>

                        <DetailsControl
                            name='questions.anyRelevantFeaturesInfluencingPrognosis.details'
                            disabled={formik.values.questions?.anyRelevantFeaturesInfluencingPrognosis?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        8.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please comment on your patient’s current mental health
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='commentOnCurrentMentalHealth' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.commentOnCurrentMentalHealth.value'
                        disabled={formik.values.questions?.commentOnCurrentMentalHealth?.referToAttachments}
                    />
                </Grid>
            </Grid>

        </Grid>
    )
}
