export default () => {

    /**
     * Attempts to get instance of the EO Browser control to invoke functions on
     * @returns EO Browser instance
     */
    const getBrowserInstance = () => {
        const eoBrowser = window["eoapi"]

        if (eoBrowser) {
            return eoBrowser
        } else {
            //console.error('Attempting to get instance of embedded browser failed. Instance of EO Browser does not exist')
            return null
        }
    }

    /**
     * Calls the PMS app to get details of the logged in user
     * @returns details object with following detaiils: firstName, lastName, medCouncilNo, isUserDoctor
     */
    const invokeGetPmsUser = () => {
        const browser = getBrowserInstance()

        let user = null

        if (browser) {
            user = JSON.parse(browser.extInvoke("PmsLoggedInUserDetails"));
        }
        
        return user
    }

    /**
     * Calls the PMS app to raise the attach file handler
     */
    const invokeAttachFiles = () => {
        const browser = getBrowserInstance()

        if (browser) {
            browser.extInvoke("attachFiles")
        }
    }

    /**
     * Calls PMS app to notify report is saved/submitted & to close the browser
     * @param {*} requestId id of the request
     * @param {*} isSubmitted true if request is submitted & now complete, false if just saving to continue later
     */
    const invokeCloseBrowser = (requestId, isSubmitted = false) => {
        const browser = getBrowserInstance()

        if (browser) {
            const body = {
                requestId: requestId,
                actionType: isSubmitted ? "submitted" : "saved"
            }

            browser.extInvoke("requestSaved", JSON.stringify(body))
        }
    }

    // /**
    //  * Calls the PMS app to raise the attach notes handler
    //  * @returns 
    //  */
    // const invokeAttachNotes = () => {
    //     const browser = getBrowserInstance()

    //     if (browser) {
    //         return browser.extInvoke("attachNotes")
    //     } else {
    //         return null
    //     }
    // }

    return {
        invokeGetPmsUser,
        invokeAttachFiles,
        invokeCloseBrowser,
        //invokeAttachNotes
    }
}