import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Outlet } from 'react-router-dom'

export default function Layout() {
    return (
        <>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Container maxWidth="md" component="main" sx={{ marginTop: '30px' }}>
                <Outlet />
            </Container>
        </>
    )
}