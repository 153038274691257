import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'

export default function SarcoidosisDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Sarcoidosis Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>Please confirm the staging of the disease at initial diagnosis:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.initialStage.referToAttachments}>
                        <p>{questions.initialStage.value}</p>

                        <Question
                            show={questions.initialStage.other != ''}
                            answer={questions.initialStage.other}
                        >
                            Other:
                        </Question>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>


                <QuestionWrapper number='2'>
                    <Question>Have any complications ever manifested?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.complications.referToAttachments}>
                        <p>{questions.complications.value}</p>

                        {questions.complications.value === 'Yes' && (
                            <>
                                <QuestionWrapper>
                                    <Question
                                        answer={questions.complications.details}
                                    >
                                        If yes, please specify:
                                    </Question>
                                </QuestionWrapper>

                                <QuestionWrapper>
                                    <Question
                                        show={questions.complications.details === 'Other'}
                                        answer={questions.complications.other}
                                    >
                                        If other, please give details:
                                    </Question>
                                </QuestionWrapper>
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Based on the latest specialist review, are the lesions:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.lesions.referToAttachments}>
                        <p>{questions.lesions.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='4'>
                    <Question>Does the current treatment regimen include any of the following:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.treatment.referToAttachments}>
                        <QuestionWrapper>
                            <Question
                                answer={questions.treatment.corticosteroids}
                            >
                                Corticosteroids:
                            </Question>
                        </QuestionWrapper>

                        <QuestionWrapper>
                            <Question
                                answer={questions.treatment.secondLineAgents}
                            >
                                Anti-TNFs, DMARDs or any other second line agents:
                            </Question>
                        </QuestionWrapper>

                        <QuestionWrapper>
                            <Question show={questions.treatment.secondLineAgents === 'Yes'}>
                                If yes, please confirm details:
                            </Question>
                            <p>{questions.treatment.other}</p>
                        </QuestionWrapper>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}