import Component from './MissingInformation'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./missingInformation.css');
var css = cssModule.default;

const missingInformationConfig = {
    component: Component,
    css: css
}

export default missingInformationConfig