import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'

import SocialHistorySummary from './SocialHistorySummary'
import ClinicalExamSummary from './ClinicalExamSummary'
import MedicationsSummary from './MedicationsSummary'

export default function TempSummaryComponent() {
    return (
        <Stack>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <SocialHistorySummary />
                </Grid>
                <Grid item xs={6}>
                    <ClinicalExamSummary />
                </Grid>
                <Grid item xs={12}>
                    <MedicationsSummary />
                </Grid>
            </Grid>
        </Stack>
    )
}
