import { useState } from 'react'
import { useField } from 'formik'
import ToggleButton from '@mui/material/ToggleButton'
import Tooltip from '@mui/material/Tooltip';
import ForwardIcon from '@mui/icons-material/Forward'
import UploadFileIcon from '@mui/icons-material/UploadFile'

export default function ReferToAttachmentsButton({ name }) {
    const [field, _, helpers] = useField(`questions.${name}`)
    const [previousValue, setPreviousValue] = useState('')

    const onButtonToggle = (e, val) => {

        const referToAttachments = !val

        if (referToAttachments === true) {
            // Get the current value of the field (second 'value' here is the property on the model)
            const currentValue = field.value.value

            // If a value is set, store the current before updating
            // Allows user to retrieve the current value if clicked by mistake
            if (currentValue) {
                setPreviousValue(currentValue)
            }

            // Set referToAttachments=true & text to 'Refer to attachments'
            helpers.setValue({
                ...field.value,
                referToAttachments: true,
                value: 'Refer to attachments'
            })
        } else if (referToAttachments === false) {
            // Set referToAttachments = false and restore previous text
            helpers.setValue({
                ...field.value,
                referToAttachments: false,
                value: previousValue ?? ''
            })

            // Clear previous value
            setPreviousValue('')

            // Mark field as not touched so the validation error does not show immediately
            helpers.setTouched(`questions.${name}.value`, false)
        }
    }

    const tooltip = (
        <>
            <p><u>Refer to attached reports:</u></p>
            <p>When selected, this states the answer to this question will be supplied in an attachment added under the <strong>File Attachments</strong> tab.</p>
        </>
    )

    return (
        <Tooltip title={tooltip} enterDelay={500}>
            <ToggleButton
                variant='contained'
                value={field.value?.referToAttachments}
                selected={field.value?.referToAttachments}
                onChange={onButtonToggle}
                size='small'
                style={{ height: '30px' }}
            >
                <ForwardIcon />
                <UploadFileIcon />
            </ToggleButton>
        </Tooltip>
    )
}