import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'

export default function TableDatePicker({ date, onChange, ...props }) {
    const format = 'DD/MM/yyyy'

    if (date) {
        // If date is not null, convert it into moment instance
        date = moment(date, format)
    }

    return (
        <DatePicker
            value={date}
            onChange={(value) => {
                if (value) {
                    // Convert moment instance to string
                    value = moment(value).format(format)
                }

                onChange(value)
            }}
            inputFormat={format}
            renderInput={(params) => <TextField {...params} size='small' {...props} />}
        />
    )
}