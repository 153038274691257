import { useRecoilValue } from 'recoil'

import { activeStepSelector } from '../../requestState'

export default (steps) => {
    const activeStep = useRecoilValue(activeStepSelector)

    const totalSteps = () => {
        return steps.length
    }

    const isLastStep = () => {
        return activeStep === totalSteps() - 1
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }


    return {
        totalSteps,
        isLastStep,
        scrollToTop
    }
}