import useArrayUtils from '../../../../../../../hooks/useArrayUtils'

export default function GeneralHistoryItem({ name, data }) {
    const { isArrayEmpty } = useArrayUtils()

    return (
        <>
            <h5>{name}</h5>
            {isArrayEmpty(data) && (
                <p>No information available</p>
            )}
            {data.map((h, idx) => (
                <p key={idx}>{h.value}</p>
            ))}
        </>
    )
}
