import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function KidneyDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Kidney and Urinary Disorder Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>What was the exact diagnosis?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.diagnosis.referToAttachments}>
                        <p>{questions.diagnosis.subQuestions.exactDiagnosis}</p>

                        <Question>Any underlying causes?</Question>
                        <p>{questions.diagnosis.subQuestions.anyUnderlyingCauses}</p>

                        <Question>What were the presenting symptoms?</Question>
                        <p>{questions.diagnosis.subQuestions.presentingSymptoms}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='2'>
                    <Question>Date of diagnosis:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.symptoms.referToAttachments}>
                        <p>{formatDateMonthYear(questions.symptoms.subQuestions.dateOfDiagnosis)}</p>

                        <Question>Date of last symptoms:</Question>
                        <p>{formatDateMonthYear(questions.symptoms.subQuestions.dateOfLastSymptoms)}</p>

                        <Question>Nature of last symptoms:</Question>
                        <p>{questions.symptoms.subQuestions.natureOfLastSymptoms}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Please confirm the date and result of the latest RFT’s (creatinine, urea, eGFR, ACR, PCR), blood pressure and urinalysis</Question>
                    <ReferrableQuestionWrapper isReferred={questions.rftBloodPressureUrinalysisResults.referToAttachments}>
                        <p>{questions.rftBloodPressureUrinalysisResults.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='4'>
                    <Question>Date and results of any other investigations? (cystoscopy, CT scan etc.)</Question>
                    <ReferrableQuestionWrapper isReferred={questions.otherInvestigationResults.referToAttachments}>
                        <p>{questions.otherInvestigationResults.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='5'>
                    <Question>Has your patient required inpatient treatment or surgery?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.inPatientTreatmentRequired.referToAttachments}>
                        <p>{questions.inPatientTreatmentRequired.value}</p>

                        <Question
                            show={questions.inPatientTreatmentRequired.value === 'Yes'}
                            answer={questions.inPatientTreatmentRequired.details}
                        >
                            If yes, please confirm details
                        </Question>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='6'>
                    <Question>Any associated medical conditions or complications?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyAssociatedMedicalConditions.referToAttachments}>
                        <p>{questions.anyAssociatedMedicalConditions.value}</p>

                        <Question
                            show={questions.anyAssociatedMedicalConditions.value === 'Yes'}
                            answer={questions.anyAssociatedMedicalConditions.details}
                        >
                            If yes, please confirm details
                        </Question>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='7'>
                    <Question>Has the condition caused any time off work??</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyTimeOffWorkCaused.referToAttachments}>
                        <p>{questions.anyTimeOffWorkCaused.value}</p>
                        <Question
                            show={questions.anyTimeOffWorkCaused.value === 'Yes'}
                            answer={questions.anyTimeOffWorkCaused.details}
                        >
                            If yes, please confirm details
                        </Question>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}