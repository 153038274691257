import { ThemeProvider, createTheme } from '@mui/material/styles'

const irishLifeTheme = createTheme({
    palette: {
        primary: {
            main: '#5261AC'
        }
    },
    typography: {
        body2Bold: {
            color: '#5261AC',
            fontSize: '0.875rem',
            fontWeight: 600,
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
        }
      },
})

export default function IrishLifeTheme({ children }) {
    return (
        <ThemeProvider theme={irishLifeTheme}>
            {children}
        </ThemeProvider>
    )
}