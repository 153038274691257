import { forwardRef } from 'react'
import { useRecoilState } from 'recoil'

import atom from './state'
import validation from './validation'

import FormikWrapper from '../generic/FormikWrapper'
import GeneralHistoryBody from './component/GeneralHistoryBody'

function GeneralHistoryComponent({ onSubmit }, ref) {
    const [state, setState] = useRecoilState(atom)

    // Read initial values from state
    const initialValues = JSON.parse(JSON.stringify(state))

    return (
        <FormikWrapper
            initialValues={initialValues}
            validation={validation}
            onSubmit={onSubmit}
            setState={setState}
            ref={ref}
        >
            {formikProps => <GeneralHistoryBody {...formikProps} />}
        </FormikWrapper>
    )
}

export default forwardRef(GeneralHistoryComponent)