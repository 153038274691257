import moment from 'moment'

export default function LabResults({ unknown, results }) {

    if (unknown) {
        return <p>Results are unknown</p>
    } else if (results.length >= 1) {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Identifier</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((lab, idx) => (
                        <tr key={idx}>
                            <td>{moment(lab.date).format('DD/MM/YYYY')}</td>
                            <td>{lab.identifier}</td>
                            <td>{lab.value} {lab.units}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    } else {
        return null
    }
}