import * as yup from 'yup'

const SocialHistoryValidation = yup.object({
    questions: yup.object({

        nicotineReplacementTherapy: yup.object({
            value: yup.string()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(['true', 'false', 'unknown'])
        }),

        nicotineReplacementMostRecentlyRecordedDate: yup.object()
            .when('nicotineReplacementTherapy.value', {
                is: 'true',
                then: yup.object({
                    value: yup.date()
                        .typeError('Please enter a valid date')
                        .required('Please enter a valid date')
                })
            }),

        historyOfDrugsUse: yup.object({
            value: yup.string()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(['true', 'false', 'unknown'])
        }),

        historyOfDrugsUseNotes: yup.object()
            .when('historyOfDrugsUse.value', {
                is: 'true',
                then: yup.object({
                    value: yup.string()
                        .nullable()
                        .required('Please enter a note')
                })
            }),

        historyOfAlcoholUseStartDate: yup.object()
            .when('historyOfDrugsUse.value', {
                is: 'true',
                then: yup.object({
                    value: yup.date()
                        .typeError('Please enter a date')
                        .nullable()
                })
            }),

        historyOfAlcoholUseEndDate: yup.object()
            .when('historyOfDrugsUse.value', {
                is: 'true',
                then: yup.object({
                    value: yup.date()
                        .typeError('Please enter a date')
                        .nullable()
                })
            }),

        historyOfDrugsUseStartDate: yup.object()
            .when('historyOfDrugsUse.value', {
                is: 'true',
                then: yup.object({
                    value: yup.date()
                        .typeError('Please enter a date')
                        .nullable()
                })
            }),

        historyOfDrugsUseEndDate: yup.object()
            .when('historyOfDrugsUse.value', {
                is: 'true',
                then: yup.object({
                    value: yup.date()
                        .typeError('Please enter a date')
                        .nullable()
                })
            })
    })
});

export default SocialHistoryValidation