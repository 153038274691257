import { useState } from 'react'
import { Field } from 'formik'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import ExpanderMenu from './ExpanderMenu'

export default function Expander({ header, binding, children }) {
    const [open, setOpen] = useState(true)

    return (
        <Field name={binding}>
            {(props) => {
                function onSelectAll() {
                    var items = props.field.value

                    // Mark each item as included
                    items.forEach(item => item.include = true)

                    props.form.setFieldValue(items)
                }

                function onDeselectAll() {
                    var items = props.field.value

                    // Mark each item as excluded
                    items.forEach(item => item.include = false)

                    props.form.setFieldValue(items)
                }

                return (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow sx={{ '&& > *': { borderBottom: 'unset' } }}>
                                    <TableCell sx={{ width: 100 }}>
                                        <IconButton
                                            size="small"
                                            onClick={() => setOpen(!open)}
                                        >
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <strong>
                                            {header}
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <ExpanderMenu
                                            onTickAll={onSelectAll}
                                            onUntickAll={onDeselectAll}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ py: 0 }} colSpan={3}>
                                        <Collapse in={open} timeout="auto" unmountOnExit sx={{ py: 2 }}>
                                            {children}
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }}
        </Field>
    )
}
