import AppTheme from './AppTheme'
import AppDateLocalization from './AppDateLocalization'

export default function AppGlobalConfig({ children }) {
    return (
        <AppTheme>
            <AppDateLocalization>
                {children}
            </AppDateLocalization>
        </AppTheme>
    )
}