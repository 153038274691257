import {
    SocialHistoryComponent,
    GeneralHistoryComponent,
    LaboratoryStudies,
    Medications,
    Attachments,
    Review,
    Submit
} from '../../formComponents'

import IntroductionComponent from './PmarIntroduction'

const pmarConfig = {
    title: 'Private Medical Attendants Report',
    steps: [
        {
            label: "Introduction",
            key: 'Introduction',
            component: IntroductionComponent,
            hasForm: false,
            restrictToDoctor: false
        },
        {
            label: 'Social History',
            key: 'SocialHistory',
            component: SocialHistoryComponent,
            hasForm: true,
            restrictToDoctor: false
        },
        {
            label: 'Medical History',
            key: 'GeneralHistory',
            component: GeneralHistoryComponent,
            hasForm: true,
            restrictToDoctor: false
        },
        {
            label: 'Lab Studies',
            key: 'LaboratoryStudies',
            component: LaboratoryStudies,
            hasForm: true,
            restrictToDoctor: false
        },
        {
            label: 'Medications',
            key: 'Medications',
            component: Medications,
            hasForm: true,
            restrictToDoctor: false
        },
        {
            label: 'File Attachments',
            key: 'Attachments',
            component: Attachments,
            hasForm: true,
            restrictToDoctor: false
        },
        {
            label: 'Review',
            key: 'Review',
            component: Review,
            hasForm: false,
            restrictToDoctor: false
        },
        {
            label: 'Submit',
            key: 'Submit',
            component: Submit,
            hasForm: false,
            restrictToDoctor: true
        },
    ]
}

export default pmarConfig