import { useSetRecoilState } from 'recoil'

import { pmsUserAtom } from '../state/pmsState'
import useBrowserFunctions from './useBrowserFunctions'
import useUrlFunctions from './useUrlFunctions'

export default () => {
    const browserFunctions = useBrowserFunctions()
    const urlFunctions = useUrlFunctions()
    const setPmsUserState = useSetRecoilState(pmsUserAtom)

    const getPmsUser = () => {
        // Try get PMS user details from url params first (HealthOne PMS), otherwise get from browser control (Socrates & HPM PMS)
        const pmsUser = urlFunctions.getPmsUserFromUrl() ?? browserFunctions.invokeGetPmsUser()

        if (pmsUser != null) {
            const user = {
                firstName: pmsUser.firstName,
                lastName: pmsUser.lastName,
                mcn: pmsUser.medCouncilNo,
                isDoctor: pmsUser.isUserDoctor
            }

            setPmsUserState(user)
        }
    }
    
    return {
        getPmsUser
    }
}