const questions = {
    diagnosis: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            exactDiagnosis: '',
            anyUnderlyingCauses: '',
            presentingSymptoms: ''
        }
    },
    symptoms: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            dateOfDiagnosis: '',
            dateOfLastSymptoms: '',
            natureOfLastSymptoms: ''
        }
    },
    rftBloodPressureUrinalysisResults: {
        value: '',
        referToAttachments: false
    },
    otherInvestigationResults: {
        value: '',
        referToAttachments: false
    },
    inPatientTreatmentRequired: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    anyAssociatedMedicalConditions: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    anyTimeOffWorkCaused: {
        value: '',
        referToAttachments: false,
        details: ''
    }
}

export default questions
