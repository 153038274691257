import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'

import { DependentInput } from '../../fieldComponents'
import { yesNoOptions } from '../../../../../constants'

export default function KidneyQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        What was the exact diagnosis?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='diagnosis' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.diagnosis.subQuestions.exactDiagnosis'
                        disabled={formik.values.questions?.diagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={2} />


                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Any underlying causes?
                    </Typography>
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.diagnosis.subQuestions.anyUnderlyingCauses'
                        disabled={formik.values.questions?.diagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={2} />


                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        What were the presenting symptoms?
                    </Typography>
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.diagnosis.subQuestions.presentingSymptoms'
                        disabled={formik.values.questions?.diagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={2} />
            </Grid>


            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date of diagnosis:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.symptoms.subQuestions.dateOfDiagnosis'
                        disabled={formik.values.questions?.symptoms?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='symptoms' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date of last symptoms:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.symptoms.subQuestions.dateOfLastSymptoms'
                        disabled={formik.values.questions?.symptoms?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Nature of last symptoms
                    </Typography>
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.symptoms.subQuestions.natureOfLastSymptoms'
                        disabled={formik.values.questions?.symptoms?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please confirm the date and result of the latest RFT’s (creatinine, urea, eGFR, ACR, PCR), blood pressure and urinalysis
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='rftBloodPressureUrinalysisResults' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.rftBloodPressureUrinalysisResults.value'
                        disabled={formik.values.questions?.rftBloodPressureUrinalysisResults?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Date and results of any other investigations? (cystoscopy, CT scan etc.)
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='otherInvestigationResults' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.otherInvestigationResults.value'
                        disabled={formik.values.questions?.otherInvestigationResults?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        5.
                    </Typography>
                </Grid>

                <Grid item xs={7}>
                    <Typography variant='body2'>
                        Has your patient required inpatient treatment or surgery?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyRadioGroup
                        name='questions.inPatientTreatmentRequired.value'
                        disabled={formik.values.questions?.inPatientTreatmentRequired?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='inPatientTreatmentRequired' />
                </Grid>

                <DependentInput condition={formik.values.questions.inPatientTreatmentRequired.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm details
                        </Typography>

                        <MyTextField
                            name='questions.inPatientTreatmentRequired.details'
                            disabled={formik.values.questions?.inPatientTreatmentRequired?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        6.
                    </Typography>
                </Grid>

                <Grid item xs={7}>
                    <Typography variant='body2'>
                        Any associated medical conditions or complications?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyRadioGroup
                        name='questions.anyAssociatedMedicalConditions.value'
                        disabled={formik.values.questions?.anyAssociatedMedicalConditions?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyAssociatedMedicalConditions' />
                </Grid>

                <DependentInput condition={formik.values.questions.anyAssociatedMedicalConditions.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm details
                        </Typography>

                        <MyTextField
                            name='questions.anyAssociatedMedicalConditions.details'
                            disabled={formik.values.questions?.anyAssociatedMedicalConditions?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        7.
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant='body2'>
                        Has the condition caused any time off work?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyRadioGroup
                        name='questions.anyTimeOffWorkCaused.value'
                        disabled={formik.values.questions?.anyTimeOffWorkCaused?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyTimeOffWorkCaused' />
                </Grid>

                <DependentInput condition={formik.values.questions.anyTimeOffWorkCaused.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm details
                        </Typography>

                        <MyTextField
                            name='questions.anyTimeOffWorkCaused.details'
                            disabled={formik.values.questions?.anyTimeOffWorkCaused?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

        </Grid>
    )
}