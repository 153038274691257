import { useRecoilState } from 'recoil'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'

import { labSearchTermAtom } from '../../../formComponents/laboratoryStudies/state'

export default function LabFilter() {
    const [searchTerm, setSearchTerm] = useRecoilState(labSearchTermAtom)
    const [text, setText] = useState(searchTerm || '')

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            onFilter()
            e.preventDefault();
        }
    }

    function onFilter() {
        setSearchTerm(text)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Search by identifier"
                    fullWidth
                    size='small'
                    value={text}
                    onChange={e => setText(e.target.value)}
                    onKeyPress={onKeyPress}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={onFilter}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </Grid >
    )
}