import moment from 'moment'

export default (data) => {
    const formatCurrency = (num) => `€${num.toFixed(2)}`

    return {
        content: [
            { text: 'Invoice', style: 'header' },
            {
                columns: [
                    {
                        stack: [
                            { text: 'Bill To:', bold: true },
                            'Claimsure Slainte Ireland',
                            '3094 Lake Drive',
                            'Citywest Business Campus',
                            'Dublin 24'
                        ]
                    },
                    {
                        layout: 'noBorders',
                        table: {
                            widths: [80, '*'],
                            body: [
                                [
                                    { text: 'Invoice No:', bold: true },
                                    data.invoiceNo
                                ],
                                [
                                    { text: 'GP Name:', bold: true },
                                    data.doctor?.name || ''
                                ],
                                [
                                    { text: 'GP Phone:', bold: true },
                                    data.doctor?.phone || ''
                                ],
                                [
                                    { text: 'GP Address:', bold: true },
                                    {
                                        stack: [
                                            data.doctor.address?.streetAddress,
                                            data.doctor.address?.otherDesignation,
                                            data.doctor.address?.city,
                                            data.doctor.address?.province,
                                            data.doctor.address?.country,
                                            data.doctor.address?.postCode
                                        ]
                                    }
                                ],
                                [
                                    { text: 'CWH A/C No:', bold: true },
                                    data.cwhAccountNumber
                                ],
                                [
                                    { text: 'IBAN:', bold: true },
                                    data.account.iban
                                ],
                                [
                                    { text: 'VAT No:', bold: true },
                                    data.account.vatNumber
                                ]
                            ]
                        }
                    },
                ],
                style: 'container'
            },
            {
                text: [
                    { text: 'Date:  ', bold: true }, `${moment().format('DD/MM/YYYY')}`
                ]
            },
            {
                table: {
                    headerRows: 1,
                    widths: ['auto', '*', '*', '*'],
                    body: [
                        [
                            { text: 'Description', style: 'tableHeader' },
                            { text: 'Net', style: 'tableHeader' },
                            { text: `VAT @ ${data.fee.vatRate}`, style: 'tableHeader' },
                            { text: 'Total', style: 'tableHeader' }
                        ],
                        [
                            `${data.formName}`,
                            `${formatCurrency(data.fee.net)}`,
                            `${formatCurrency(data.fee.vatAmount)}`,
                            `${formatCurrency(data.fee.gross)}`
                        ],
                    ]
                },
                style: 'container'
            }
        ],
        defaultStyle: {
            fontSize: 11,
            lineHeight: 1.3
        },
        styles: {
            header: {
                fontSize: 22,
                bold: true,
                alignment: 'center',
                margin: [10, 10]
            },
            container: {
                margin: [0, 20, 0, 50]
            },
            tableHeader: {
                bold: true
            }
        }
    }
}