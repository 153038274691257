export function splitDateAndNote(value) {
    // The note is supplied in format '[Date]  Note', eg. '[01/01/2000] Some note here'
    // Split this up to display them in seperate table columns and improve user experience when modifying the note

    // Get index of '[' & ']' to split the date & note
    var startIndex = value.indexOf('[')
    var endIndex = value.indexOf(']')

    // Extract date from value (startIndex +1 to remove the '[' part from the date)
    var date = value.slice(startIndex + 1, endIndex)

    // Extract the note (endIndex +2 to remove the '[ ' part from the note)
    var note = value.substring(endIndex + 2)

    return {
        date: date,
        note: note
    }
}

export function combineDateAndNote(date, note) {
    // Combine date & note and return in format '[Date]  Note', eg. '[01/01/2000] Some note here'
    return `[${date}] ${note}`
}

export function getUserName(user) {
    if (user == null) {
        return null
    }

    let text = []

    if (user.prefix) {
        text.push(user.prefix)
    }

    if (user.firstname) {
        text.push(user.firstname)
    }

    if (user.surname) {
        text.push(user.surname)
    }

    if (user.suffix) {
        text.push(user.suffix)
    }

    return text.join(' ').trim()
}