import Typography from '@mui/material/Typography'

import IntroductionBase from './IntroductionBase'

export default function ConditionSpecificIntroduction() {

    const openingContent = (
        <>
            <br />
            Your patient has applied for insurance with Irish Life Assurance, and we need you to complete this condition specific questionnaire.<br />
            <br />
            Your patient has given us permission below to collect their medical information from you. You do not need to meet the patient to complete the questionnaire.<br />
            <br />
            Section 50 of the Irish Data Protection Act also allows for health data to be processed in connection with an insurance policy.<br />
            <br />
        </>
    )

    const feeContent = (
        <>
            Electronic Questionnaire Fee - €70<br />
            <br />
        </>
    )

    const contactContent = (
        <>
            If you have any questions or if you require any assistance with returning this form please contact your Data Processor Clanwilliam Health on <Typography variant="body2Bold">+35389 4284224</Typography>.
        </>
    )

    return (
        <IntroductionBase
            openingContent={openingContent}
            feeContent={feeContent}
            contactContent={contactContent}
        />
    )
}