import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { grey, blueGrey, blue } from '@mui/material/colors'
import Typography from '@mui/material/Typography'

export const DisabledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: blueGrey[50],
    "& td": {
        color: blueGrey[400]
    }
}))

export const EditingTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: blue[50],
    borderBottom: "2px solid " + blueGrey[200],
    borderTop: "2px solid " + blueGrey[200],
    borderLeft: "2px solid " + blueGrey[200],
    borderRight: "2px solid " + blueGrey[200],
    borderRadius: "50%",
    "> td": {
        padding: theme.spacing(2),
    }
}))

export const EmptyTableRow = styled(TableRow)(({ theme }) => ({
    "& td": {
        color: grey[600]
    }
}))

export const EmptyTypography = styled(Typography)(({ theme }) => ({
    color: grey[600]
}))
