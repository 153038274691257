import * as yup from 'yup'

const GeneralHistoryValidation = yup.object({
    questions: yup.object({

        mainPractitioner: yup.object({
            value: yup.string()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(['true', 'false'])
        }),

        mainPractitionerNotes: yup.object()
            .when('mainPractitioner.value', {
                is: 'false',
                then: yup.object({
                    value: yup.string()
                        .nullable()
                        .required('Please enter a note')
                })
            }),

        earliestRecordDate: yup.object({
            value: yup.date()
                .typeError("Please enter a valid date")
                .required()
        }),

        awaitingInvestigations: yup.object({
            value: yup.string()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(['true', 'false'])
        }),

        awaitingInvestigationsNotes: yup.object()
            .when('awaitingInvestigations.value', {
                is: 'true',
                then: yup.object({
                    value: yup.string()
                        .nullable()
                        .required('Please enter a note')
                })
            }),
    })
});

export default GeneralHistoryValidation