import mentalHealthModel from '../views/request/wizard/forms/MentalHealth/model'
import tumourModel from '../views/request/wizard/forms/Tumour/model'
import strokeModel from '../views/request/wizard/forms/Stroke/model'
import multipleSclerosisModel from '../views/request/wizard/forms/MultipleSclerosis/model'
import sarcoidosisModel from '../views/request/wizard/forms/Sarcoidosis/model'
import heartDiseaseModel from '../views/request/wizard/forms/HeartDisease/model'
import hepatitisModel from '../views/request/wizard/forms/Hepatitis/model'
import kidneyModel from '../views/request/wizard/forms/Kidney/model'
import respiratoryModel from '../views/request/wizard/forms/Respiratory/model'
import diabetesModel from '../views/request/wizard/forms/Diabetes/model'

import { CONDITION_SPECIFIC_FORM_CODES } from '../constants'

export function isConditionSpecific(formCode) {
    // Get codes of Condition Specific forms
    const formCodes = Object.values(CONDITION_SPECIFIC_FORM_CODES)

    // Check current form is a Condition Specific type
    return formCodes.includes(formCode)
}

export function mapConditionSpecificData(formType, csData) {
    if (csData.questions === null) {
        // We need to populate the Questions property for a Condition Specific Questionnaire when loading for the first time

        const questions = mapFormToModel(formType)

        console.log('Initializing new report, adding questions for CSR', questions)

        return {
            ...csData,
            questions: questions
        }
    } else if (typeof csData.questions === 'string') {
        // Questions property is saved to database as a string value
        // We need to parse this into a JSON object when reloading
        return {
            ...csData,
            questions: JSON.parse(csData.questions)
        }
    }
}

function mapFormToModel(formType) {
    const formModelConfig = ({
        [CONDITION_SPECIFIC_FORM_CODES.mentalHealth]: mentalHealthModel,
        [CONDITION_SPECIFIC_FORM_CODES.tumour]: tumourModel,
        [CONDITION_SPECIFIC_FORM_CODES.stroke]: strokeModel,
        [CONDITION_SPECIFIC_FORM_CODES.multipleSclerosis]: multipleSclerosisModel,
        [CONDITION_SPECIFIC_FORM_CODES.sarcoidosis]: sarcoidosisModel,
        [CONDITION_SPECIFIC_FORM_CODES.heartDisease]: heartDiseaseModel,
        [CONDITION_SPECIFIC_FORM_CODES.hepatitis]: hepatitisModel,
        [CONDITION_SPECIFIC_FORM_CODES.kidney]: kidneyModel,
        [CONDITION_SPECIFIC_FORM_CODES.respiratory]: respiratoryModel,
        [CONDITION_SPECIFIC_FORM_CODES.diabetes]: diabetesModel
    }[formType])

    return formModelConfig
}
