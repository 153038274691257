import React, { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Paper from '@mui/material/Paper'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import { requestViewStateAtom, activeStepSelector } from '../../requestState'
import RequestInfoBar from '../forms/RequestInfoBar'
import StepperNavigation from './StepperNavigation'
import useStepperFunctions from './useStepperFunctions'

import useCloneUtils from '../../../../hooks/useCloneUtils'

function FormStepper({ config, onSave, onSubmit }) {
    const { title, steps } = config

    const formRef = useRef()
    const [viewState, setViewState] = useRecoilState(requestViewStateAtom)
    const activeStep = useRecoilValue(activeStepSelector)
    const { scrollToTop } = useStepperFunctions(steps)
    const { deepClone } = useCloneUtils()

    // Use active step from view state, or if no step exists for this index, use the last step
    // This covers cases where a non-doctor user opens a request that has previously been opened as far as the Submit screen and then 'Saved for Later'.
    // Non-doctor users are restricted from opening the Submit tab so the step is removed from the form config when loading.
    const activeComponent = steps?.[activeStep] ?? steps[steps.length -1]


    useEffect(() => {
        scrollToTop()
    }, [activeStep])

    const handleNext = () => {
        if (formRef.current) {
            // If the component contains a form, submit the form
            formRef.current.handleSubmit()
        } else {
            // If no form in component, move to the next step
            const updatedViewState = moveToNextStep()

            onSaveProgress(null, updatedViewState)
        }
    }

    const handlePrevious = () => {
        const updatedViewState = {
            ...viewState,
            activeStep: activeStep - 1
        }

        onSaveProgress(null, updatedViewState)
    }

    const handleSaveAndClose = () => {
        // Get the current values of the formik form for the displayed component
        const values = deepClone(formRef.current?.values)

        // Check if active tab contains a form and is Condition Specific Questionnaire.
        // If so we need to serialize the questions field before saving to the backend
        if (activeComponent.hasForm && config.isConditionSpecific) {
            values.questions = JSON.stringify(values.questions)
        }

        // Save to backend
        var isClosing = true
        onSaveProgress(values, viewState, isClosing)
    }

    const moveToNextStep = () => {
        // Create new view state
        const updatedViewState = {
            ...viewState,
            activeStep: activeStep + 1
        }

        return updatedViewState
    }

    const onStepSubmit = (values) => {
        const updatedViewState = moveToNextStep()

        onSaveProgress(values, updatedViewState)
    }

    const onSaveProgress = (values, updatedViewState, isClosing = false) => {
        const key = steps[activeStep].key

        // Save to backend
        onSave(key, values, updatedViewState, isClosing)

        // Modify the app state
        setViewState(updatedViewState)
    }

    const navButtons = (
        <StepperNavigation
            steps={steps}
            activeStep={activeStep}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            handleSave={handleSaveAndClose}
            handleSubmit={onSubmit}
        />
    )

    return (
        <Paper variant='outlined' sx={{ mb: 4, width: '100%' }}>
            <Typography variant="h4" align='center' gutterBottom sx={{ mt: 3, mb: 1 }}>{title}</Typography>
            <RequestInfoBar />
            <Stepper alternativeLabel activeStep={activeStep} sx={{ mb: 2, px: 2 }}>
                {steps.map(({ label }) => (
                    <Step
                        key={label}
                        sx={{
                            '& .MuiStepLabel-root .Mui-active': {
                                color: '#11cb5f'
                            }
                        }}>
                        <StepLabel>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            {navButtons}
            <Container sx={{ mt: 4, mb: 2 }}>
                <activeComponent.component
                    onSubmit={onStepSubmit}
                    ref={activeComponent.hasForm ? formRef : null}
                />
            </Container>
            {navButtons}
        </Paper>
    )
}

export default FormStepper
