import { string, number, object, array } from 'yup'

import { yesNoValidOptions } from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object().shape({

        angiogramResult: object({
            subQuestions: object().when('referToAttachments', {
                is: false,
                then: object({
                    dateOf: string()
                        .ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate(),
                    bloodVesselsOccluded: string()
                        .nullable()
                        .required('Please enter details'),
                    percentageBlockageInVessels: object({
                        //TODO: AJ - Validate percentage is between 0-100
                        //TODO: AJ - Can enter text with letters currently, restrict to numbers
                        lms: string()
                            .nullable()
                            .required('Please enter details'),
                        lad: string()
                            .nullable()
                            .required('Please enter details'),
                        rca: string()
                            .nullable()
                            .required('Please enter details'),
                        cx: string()
                            .nullable()
                            .required('Please enter details')
                    })
                })
            })
        }),

        medicallyManaged: object({
            subQuestions: object().when('referToAttachments', {
                is: false,
                then: object({
                    angioplasty: string().required()
                        .required('Please select a value')
                        .oneOf(yesNoValidOptions),
                    angioplastyDetails: object().when('angioplasty', {
                        is: 'Yes',
                        then:
                            object({
                                numberOfVessels: string().required('Number of vessels treated is a required field.'),
                                date: string()
                                    .ensure()
                                    .required('Date is required')
                                    .isValidDate()
                                    .isPastDate()
                            })
                    }),
                    bypass: string().required()
                        .required('Please select a value')
                        .oneOf(yesNoValidOptions),
                    bypassDetails: object().when('bypass', {
                        is: 'Yes',
                        then:
                            object({
                                numberOfVessels: string().required('Number of vessels treated is a required field.'),
                                date: string()
                                    .ensure()
                                    .required('Date is required')
                                    .isValidDate()
                                    .isPastDate()
                            })
                    }),
                    medicallyTreated: string().required()
                        .required('Please select a value')
                        .oneOf(yesNoValidOptions)
                })
            })
        }),

        anyOtherRiskFactors: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: array().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: array().of(
                    object({
                        note: string().required('Note is a required field.'),
                        date: string()
                            .ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate()
                    })
                ).min(1, 'Please enter details regarding the risk factors')
            })
        }),

        commentOnCurrentCardiacHealth: object({
            value: string()
                .nullable()
                .required('Please enter details')
        })

    })
})

export default validation
