import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { FastField, useFormikContext } from 'formik'

export default function Questions() {
    const { _, touched, errors } = useFormikContext()

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='body2'>
                    Please enter your reply here:
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FastField
                    as={TextField}
                    size='small'
                    fullWidth
                    multiline
                    name='comments.value'
                    error={errors?.comments?.value && touched?.comments?.value}
                    helperText={touched?.comments?.value && errors?.comments?.value}
                />
            </Grid>
        </Grid>
    )
}