export default function DependentInput({ condition, children }) {
    if (condition) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return null
    }
}