import { useState } from 'react'

export default (apiFunc) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)

    const request = async (...args) => {
        setLoading(true)

        try {
            const result = await apiFunc(...args)
            setData(result.data)

            return result
        } catch (error) {
            //setErrorState(error.response.data.error)
            //console.error('Server Error response payload:', error.response.data.error.message)
            throw error
        } finally {
            setLoading(false)
        }
    }

    return {
        data,
        loading,
        request
    }
}
