import moment from 'moment'

import { RenderFieldWithUnits } from '../RenderField'
import useArrayUtils from '../../../../../../../hooks/useArrayUtils'

function ClinicalExaminations({ exams }) {
    const { isArrayEmpty } = useArrayUtils()

    return (
        <>
            <h5>Clinical Examinations</h5>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Systolic</th>
                        <th>Diastolic</th>
                        <th>Pulse</th>
                        <th>Height</th>
                        <th>Weight</th>
                        <th>BMI</th>
                    </tr>
                </thead>
                <tbody>
                    {isArrayEmpty(exams) && (
                        <tr><td colSpan={7}>No information available</td></tr>
                    )}
                    {exams.map((exam, idx) => (
                        <tr key={idx}>
                            <td><strong>{moment(exam.observationDateTime).format('DD/MM/yyyy')}</strong></td>
                            <RenderFieldWithUnits>{exam.systolicBloodPressure}</RenderFieldWithUnits>
                            <RenderFieldWithUnits>{exam.diastolicBloodPressure}</RenderFieldWithUnits>
                            <RenderFieldWithUnits>{exam.pulse}</RenderFieldWithUnits>
                            <RenderFieldWithUnits>{exam.height}</RenderFieldWithUnits>
                            <RenderFieldWithUnits>{exam.weight}</RenderFieldWithUnits>
                            <RenderFieldWithUnits>{exam.bmi}</RenderFieldWithUnits>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default ClinicalExaminations
