import Component from './HeartDisease'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./heartDisease.css');
var css = cssModule.default;

const heartDiseaseConfig = {
    component: Component,
    css: css
}

export default heartDiseaseConfig