import { useField } from 'formik'
import TextField from '@mui/material/TextField'
import RadioGroupInput from '../../fieldComponents/RadioGroupInput'
import MonthAndYearDatePicker from './MonthAndYearDatePicker'

function MyTextField({ name, disabled, label }) {
    const [
        { value, onChange },    // field
        { error, touched },     // meta
        { }                     // helpers
    ] = useField(name)

    return (
        <TextField
            label={label}
            size="small"
            multiline
            fullWidth
            name={name}
            value={value}
            onChange={onChange}
            error={error && touched}
            helperText={touched && error}
            disabled={disabled}
        />
    )
}

function MyRadioGroup({ name, disabled, options }) {
    const [
        { value, onChange },    // field
        { error, touched },     // meta
        { }                     // helpers
    ] = useField(name)

    return (
        <RadioGroupInput
            name={name}
            options={options}
            error={error && touched}
            helperText={touched && error}
            disabled={disabled}
        />
    )
}

export {
    MonthAndYearDatePicker as DateField,
    MyTextField,
    MyRadioGroup
}
