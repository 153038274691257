import { string, object, array } from 'yup'

import {
    yesNoValidOptions,
    respiratoryConditionValidOptions,
    respiratoryConstantRecurrentValidOptions,
    respiratorySeverityValidOptions,
    respiratorySymptomsValidOptions
} from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object().shape({

        natureOfCondition: object({
            value: string()
                .nullable()
                .oneOf(respiratoryConditionValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && (value === '' || value == null),
                then: string()
                    .nullable()
                    .required('Please enter other details when no option is selected')
            })
        }),

        diagnosis: object({
            subQuestions: object()
                .when('referToAttachments', {
                    is: false,
                    then: object({
                        dateofDiagnosis: string().ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate(),
                        dateOfLastExacerbation: string().ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate(),
                        constantOrRecurrent: string()
                            .required('Please select a value')
                            .oneOf(respiratoryConstantRecurrentValidOptions),
                        lengthOfLastSymptoms: string().required('Please enter details')
                    })
                })
        }),

        condition: object({
            subQuestions: object()
                .when('referToAttachments', {
                    is: false,
                    then: object({
                        severity: string()
                            .required('Please select a value')
                            .oneOf(respiratorySeverityValidOptions),
                        functionalCapacity: string().required('Please enter details'),
                        symptomsProgression: string()
                            .required('Please select a value')
                            .oneOf(respiratorySymptomsValidOptions),
                        describeNYHAStage: string().required('Please enter details')
                    })
                })
        }),

        recentResults: object({
            details: array().when('referToAttachments', {
                is: false,
                then: array().of(
                    object({
                        note: string().required('Note is a required field.'),
                        date: string()
                            .ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate()
                    })
                ).min(1, 'Please enter details')
            })
        }),

        inpatientTreatmentRequired: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        }),

        smoking: object({
            subQuestions: object()
                .when('referToAttachments', {
                    is: false,
                    then: object({
                        isSmoker: string()
                            .required('Please select a value')
                            .oneOf(yesNoValidOptions),
                        cigarettesPerDay: string().when('isSmoker', {
                            is: 'Yes',
                            then: string().required('Please enter details')
                        }),
                        isPreviousSmoker: string().when('isSmoker', {
                            is: 'No',
                            then: string().required('Please select a value').oneOf(yesNoValidOptions)
                        }),
                        previousSmokerDetails: string().when('isPreviousSmoker', {
                            is: 'Yes',
                            then: string().required('Please enter details')
                        }),
                        dateCeasedSmoking: string().when('isPreviousSmoker', {
                            is: 'Yes',
                            then: string().ensure()
                                .required('Date is required')
                                .isValidDate()
                                .isPastDate()
                        }),
                    })
                })
        }),

        patientSurgery: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        }),

        anyAssociatedConditionsOrComplications: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        }),

        anyTimeOffWorkCaused: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        })

    })
})

export default validation