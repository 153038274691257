import { useField } from 'formik'
import moment from 'moment'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'

export default function MonthAndYearDatePicker({ name, disabled, label, ...props }) {

    const [
        { value },              // field
        { error, touched },     // meta
        { setValue }            // helpers
    ] = useField(name)

    const format = 'DD/MM/yyyy'

    const date = moment(value, format)

    return (
        <>
            <DatePicker
                views={['month', 'year']}
                value={date}
                onChange={(date) => {
                    if (date) {
                        // Convert moment instance to string
                        date = moment(date).format(format)
                    }

                    setValue(date)
                }}
                label={label}
                disabled={disabled}
                renderInput={(params) =>
                (
                    <TextField
                        {...params}
                        {...props}
                        size='small'
                        error={error && touched}
                        helperText={touched && error}
                    />
                )}
            />
        </>
    )
}