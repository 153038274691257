import moment from 'moment'

import LaboratoryStudy from './LaboratoryStudy'
import useArrayUtils from '../../../../../../../hooks/useArrayUtils'

function LaboratoryStudies({ data }) {
    const { isArrayEmpty } = useArrayUtils()

    const labs = data.laboratoryStudies

    return (
        <>
            <h3>Lab Studies</h3>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {isArrayEmpty(labs) && (
                        <tr><td colSpan={2}>No information available</td></tr>
                    )}
                    {labs.map((lab, index) => (
                        <div key={index}>
                            <tr>
                                <td>{moment(lab.observationDateTime).format('DD/MM/yyyy')}</td>
                                <td>{lab.identifier.text}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <LaboratoryStudy lab={lab} />
                                </td>
                            </tr>
                        </div>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default LaboratoryStudies
