import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import { FastField, useFormikContext, ErrorMessage } from 'formik'

import { DependentInput, RadioGroupInput, DateInput, InfoPopover } from '../../../../fieldComponents'

const radioOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" }
]

export default function Questions() {
    const { values, touched, errors } = useFormikContext()

    return (
        <Grid container spacing={2} sx={{ m: 1, mb: 3 }}>
            <Grid item xs={4}>
                <Typography variant='body2'>
                    Are you the patient's main practitioner?
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <RadioGroupInput
                    name='questions.mainPractitioner.value'
                    options={radioOptions}
                    error={errors?.questions?.mainPractitioner?.value && touched?.questions?.mainPractitioner?.value}
                    helperText={touched?.questions?.mainPractitioner?.value && errors?.questions?.mainPractitioner?.value}
                />
            </Grid>

            <DependentInput condition={values.questions.mainPractitioner.value === 'false'}>
                <Grid item xs={4}>
                    <Typography variant='body2'>
                        If no, please provide an explanation
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FastField
                        as={TextField}
                        size='small'
                        fullWidth
                        multiline
                        name='questions.mainPractitionerNotes.value'
                        error={errors?.questions?.mainPractitionerNotes?.value && touched?.questions?.mainPractitionerNotes?.value}
                        helperText={touched?.questions?.mainPractitionerNotes?.value && errors?.questions?.mainPractitionerNotes?.value}
                    />
                    {/* <ErrorMessage
                        name='questions.mainPractitionerNotes.value'
                        component="div"
                        className="field-error"
                    /> */}
                </Grid>
            </DependentInput>

            <Grid item xs={4}>
                <Typography variant='body2'>
                    How far do the records you hold go back?
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <FastField
                    as={DateInput}
                    name='questions.earliestRecordDate.value'
                    error={errors?.questions?.earliestRecordDate?.value && touched?.questions?.earliestRecordDate?.value}
                    helperText={touched?.questions?.earliestRecordDate?.value && errors?.questions?.earliestRecordDate?.value}
                />
                <InfoPopover>
                    <Alert severity="info">
                        This is populated based on the earliest record contained in the Practice Management system for this patient.
                        <br />
                        If you hold paper records dating back earlier please feel free to update this field.
                    </Alert>
                </InfoPopover>
            </Grid>

            <Grid item xs={4}>
                <Typography variant='body2'>
                    Are the results of any referrals or tests awaited?
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <RadioGroupInput
                    name='questions.awaitingInvestigations.value'
                    options={radioOptions}
                    error={errors?.questions?.awaitingInvestigations?.value && touched?.questions?.awaitingInvestigations?.value}
                    helperText={touched?.questions?.awaitingInvestigations?.value && errors?.questions?.awaitingInvestigations?.value}
                />
            </Grid>

            <DependentInput condition={values.questions.awaitingInvestigations.value === 'true'}>
                <Grid item xs={4}>
                    <Typography variant='body2'>
                        If yes, please outline
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FastField
                        as={TextField}
                        size='small'
                        fullWidth
                        multiline
                        name='questions.awaitingInvestigationsNotes.value'
                        error={errors?.questions?.awaitingInvestigationsNotes?.value && touched?.questions?.awaitingInvestigationsNotes?.value}
                        helperText={touched?.questions?.awaitingInvestigationsNotes?.value && errors?.questions?.awaitingInvestigationsNotes?.value}
                    />
                </Grid>
            </DependentInput>

        </Grid>
    )
}
