import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import GenericDialog from '../../../GenericDialog'

const styles = {
    iframe: {
        display: 'block',
        background: '#000',
        border: 'none',
        height: '100%',
        width: '100%'
    }
}

export default function Invoice({ show, data, onClose }) {

    if (!show) {
        return null
    }

    const title = () => {
        return (
            <Stack spacing={2} direction="row" alignItems="center">
                <Typography variant="h5">
                    Invoice
                </Typography>
                <Typography variant="body2">
                    View the generated invoice below
                </Typography>
            </Stack>
        )
    }

    const content = () => {
        return (
            <iframe
                src={`data:application/pdf;base64,${data}`}
                style={styles.iframe}
            />
        )
    }

    const buttons = () => {
        return (
            <>
                <Button variant="contained" color="error" onClick={onClose}>Download PDF</Button>
            </>
        )
    }

    return (
        <GenericDialog
            open={show}
            title={title}
            content={content}
            buttons={buttons}
        />
    )

}