const questions = {
    angiogramResult: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            dateOf: '',
            bloodVesselsOccluded: '',
            percentageBlockageInVessels: {
                lms: '',
                lad: '',
                rca: '',
                cx: ''
            },
        }
    },
    medicallyManaged: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            angioplasty: '',
            angioplastyDetails: { numberOfVessels: '', date: '' },
            bypass: '',
            bypassDetails: { numberOfVessels: '', date: '' },
            medicallyTreated: ''
        }
    },
    anyOtherRiskFactors: {
        value: '',
        referToAttachments: false,
        details: [{ note: '', date: '' }]
    },
    commentOnCurrentCardiacHealth: {
        value: '',
        referToAttachments: false
    }
}

export default questions
