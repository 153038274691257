import { useRecoilValue } from 'recoil'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import ErrorIcon from '@mui/icons-material/Error'

import { errorAtom } from '../../state/errorState'

export default function RequestError() {
    const errorState = useRecoilValue(errorAtom)

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            marginY={5}
        >
            <ErrorIcon sx={{ fontSize: 40, color: '#d32f2f' }} />
            <Typography variant="h6" align='center'>
                {errorState?.header}
            </Typography>
            <Typography variant='body' align='center'>
                {errorState?.message}
            </Typography>

        </Stack>
    )
}