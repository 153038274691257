import moment from 'moment'

import PatientDetails from '../components/PatientDetails'
import DoctorDetails from '../components/DoctorDetails'
import MissingInformationDetails from './MissingInformationDetails'

function MissingInformation({ data }) {

    if (!data) return null

    return (
        <div>
            <h2>{data.request.formName}</h2>
            <hr />
            <p>Report generated: {moment().format('DD/MM/yyyy')}</p>
            <p>Policy No: {data.request.primaryReferenceNumber}</p>
            <hr />
            <PatientDetails data={data} />
            <hr />
            <DoctorDetails data={data} />
            <hr />
            <MissingInformationDetails data={data} />
        </div>
    )
}

export default MissingInformation
