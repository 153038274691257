import Grid from '@mui/material/Grid'

import { SectionNoteText } from '../../../fieldComponents'
import MedicationSection from './MedicationSection'

export default function MedicationsBody({ values }) {

    const currentMedications = values.currentMedications

    return (
        <Grid container spacing={2}>
            <SectionNoteText>
                Items retrieved from 5 years previous
            </SectionNoteText>

            <MedicationSection
                name="Current Medications"
                binding='currentMedications'
                data={currentMedications}
            />
        </Grid>
    )
}
