import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import OutputDetails from '../components/output/OutputDetails'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function HeartDiseaseDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Heart Disease Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>Please confirm angiogram result?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.angiogramResult.referToAttachments}>
                        <QuestionsContainer>
                            <QuestionWrapper number='a'>
                                <Question
                                    answer={formatDateMonthYear(questions.angiogramResult.subQuestions.dateOf)}
                                >
                                    Date of
                                </Question>
                            </QuestionWrapper>
                            <QuestionWrapper number='b'>
                                <Question
                                    answer={questions.angiogramResult.subQuestions.bloodVesselsOccluded}
                                >
                                    Blood vessels occluded
                                </Question>
                            </QuestionWrapper>
                            <QuestionWrapper number='c'>
                                <Question>% of blockage in each vessel</Question>
                                <Question
                                    answer={questions.angiogramResult.subQuestions.percentageBlockageInVessels.lms}
                                    suffix='%'
                                    newLine
                                >
                                    LMS:
                                </Question>
                                <Question
                                    answer={questions.angiogramResult.subQuestions.percentageBlockageInVessels.lad}
                                    suffix='%'
                                    newLine
                                >
                                    LAD:
                                </Question>
                                <Question
                                    answer={questions.angiogramResult.subQuestions.percentageBlockageInVessels.rca}
                                    suffix='%'
                                    newLine
                                >
                                    RCA:
                                </Question>
                                <Question
                                    answer={questions.angiogramResult.subQuestions.percentageBlockageInVessels.cx}
                                    suffix='%'
                                    newLine
                                >
                                    CX:
                                </Question>
                            </QuestionWrapper>
                        </QuestionsContainer>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>


                <QuestionWrapper number='2'>
                    <Question>How was this medically managed?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.medicallyManaged.referToAttachments}>
                        <QuestionsContainer>
                            <QuestionWrapper number='a'>
                                <Question
                                    answer={questions.medicallyManaged.subQuestions.angioplasty}
                                >
                                    Angioplasty
                                </Question>

                                {questions.medicallyManaged.subQuestions.angioplasty === 'Yes' && (
                                    <>
                                        <QuestionWrapper>
                                            <Question>If yes, please confirm date and number of vessels treated</Question>
                                        </QuestionWrapper>
                                        <QuestionWrapper>
                                            <Question answer={formatDateMonthYear(questions.medicallyManaged.subQuestions.angioplastyDetails.date)}>Date:</Question>
                                        </QuestionWrapper>
                                        <QuestionWrapper>
                                            <Question answer={questions.medicallyManaged.subQuestions.angioplastyDetails.numberOfVessels}>Number of vessels treated:</Question>
                                        </QuestionWrapper>
                                    </>
                                )}
                            </QuestionWrapper>
                            <QuestionWrapper number='b'>
                                <Question
                                    answer={questions.medicallyManaged.subQuestions.bypass}
                                >
                                    Bypass
                                </Question>

                                {questions.medicallyManaged.subQuestions.bypass === 'Yes' && (
                                    <>
                                        <QuestionWrapper>
                                            <Question>If yes, please confirm date and number of vessels treated</Question>
                                        </QuestionWrapper>
                                        <QuestionWrapper>
                                            <Question answer={formatDateMonthYear(questions.medicallyManaged.subQuestions.bypassDetails.date)}>Date:</Question>
                                        </QuestionWrapper>
                                        <QuestionWrapper>
                                            <Question answer={questions.medicallyManaged.subQuestions.bypassDetails.numberOfVessels}>Number of vessels treated:</Question>
                                        </QuestionWrapper>
                                    </>
                                )}
                            </QuestionWrapper>
                            <QuestionWrapper number='c'>
                                <Question answer={questions.medicallyManaged.subQuestions.medicallyTreated}>
                                    Medically treated
                                </Question>
                            </QuestionWrapper>
                        </QuestionsContainer>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Are there any other relevant cardiac risk factors such as smoking, BMI or other cardiac or vascular disease?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyOtherRiskFactors.referToAttachments}>
                        <p>{questions.anyOtherRiskFactors.value}</p>
                        {questions.anyOtherRiskFactors.value === 'Yes' && (
                            <>
                                <Question>If yes, please provide details and dates</Question>
                                <OutputDetails
                                    details={questions.anyOtherRiskFactors.details}
                                />
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>
                
                <QuestionWrapper number='4'>
                    <Question>Please comment on your patients current cardiac health?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.commentOnCurrentCardiacHealth.referToAttachments}>
                        <p>{questions.commentOnCurrentCardiacHealth.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}
