import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

export default function InsurerNote({ note }) {
    return (
        <>
            <Typography variant="body2"
                sx={{ mx: 1, mb: 0 }}
            >
                Underwriters note:
            </Typography>

            <Paper
                sx={{
                    padding: 2,
                    borderRadius: 2,
                    backgroundColor: '#fafafa',
                    fontFamily: 'monospace',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: 1.5,
                }}
            >
                {note}
            </Paper>
        </>
    )
}