import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { Field } from 'formik'

import Expander from '../../../fieldComponents/expander/Expander'
import IncludableTableRow from '../../../fieldComponents/table/IncludableTableRow'

function LabItem(props) {
    const { lab, index } = props;

    const header = (
        <Stack
            direction='row'
            justifyContent='space-around'
        >
            <strong>{moment(lab.observationDateTime).format('DD/MM/yyyy')}</strong>
            <span>{lab.identifier.text}</span>
        </Stack>
    )

    return (
        <Expander header={header} binding={`[${index}].results`}>
            <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                    Results
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Test</TableCell>
                            <TableCell align="right">Result</TableCell>
                            <TableCell align="right">Units</TableCell>
                            <TableCell align="right">Range</TableCell>
                            <TableCell align="right">Abnormal Flags</TableCell>
                            <TableCell align="right">Include</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lab.results.map((result, idx) => (
                            <IncludableTableRow key={result.identifier.text} isIncluded={result.include}>
                                <TableCell scope="row">{result.identifier.text}</TableCell>
                                <TableCell align="right">{result.value}</TableCell>
                                <TableCell align="right">{result.units.text}</TableCell>
                                <TableCell align="right">{result.range}</TableCell>
                                <TableCell align="right">{result.abnormalFlags}</TableCell>
                                <TableCell align="right">
                                    <Field
                                        type="checkbox"
                                        name={`[${index}].results[${idx}].include`}
                                    />
                                </TableCell>
                            </IncludableTableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Expander>
    )


    //TODO: AJ - I'm leaving below in as it has the Inlcude checkbox for the entire lab item
    // Need to add this back in, will do it when I'm doing the rest of the sections


    // return (
    //     <React.Fragment>
    //         <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
    //             <TableCell>
    //                 <IconButton

    //                     size="small"
    //                     onClick={() => setOpen(!open)}
    //                 >
    //                     {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    //                 </IconButton>
    //             </TableCell>
    //             <TableCell component="th" scope="row">
    //                 {moment(lab.observationDateTime).format('DD/MM/yyyy')}
    //             </TableCell>
    //             <TableCell component="th" scope="row">
    //                 {lab.identifier.text}
    //             </TableCell>
    //             <TableCell align="right">
    //                 <Field
    //                     type="checkbox"
    //                     name={`laboratoryStudies[${index}].include`}
    //                 />
    //             </TableCell>
    //         </TableRow>
    //         <TableRow>
    //             <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
    //                 <Collapse in={open} timeout="auto" unmountOnExit>
    //                     <Box sx={{ margin: 1 }}>
    //                         <Typography variant="h6" gutterBottom component="div">
    //                             Results
    //                         </Typography>
    //                         <Table size="small">
    //                             <TableHead>
    //                                 <TableRow>
    //                                     <TableCell>Test</TableCell>
    //                                     <TableCell align="right">Result</TableCell>
    //                                     <TableCell align="right">Units</TableCell>
    //                                     <TableCell align="right">Range</TableCell>
    //                                     <TableCell align="right">Abnormal Flags</TableCell>
    //                                     <TableCell align="right">Include</TableCell>
    //                                 </TableRow>
    //                             </TableHead>
    //                             <TableBody>
    //                                 {lab.results.map((result, idx) => (
    //                                     <TableRow key={result.identifier.text}>
    //                                         <TableCell component="th" scope="row">{result.identifier.text}</TableCell>
    //                                         <TableCell align="right">{result.value}</TableCell>
    //                                         <TableCell align="right">{result.units.text}</TableCell>
    //                                         <TableCell align="right">{result.range}</TableCell>
    //                                         <TableCell align="right">{result.abnormalFlags}</TableCell>
    //                                         <TableCell align="right">
    //                                             <Field
    //                                                 type="checkbox"
    //                                                 name={`laboratoryStudies[${index}].results[${idx}].include`}
    //                                             />
    //                                         </TableCell>
    //                                     </TableRow>
    //                                 ))}
    //                             </TableBody>
    //                         </Table>
    //                     </Box>
    //                 </Collapse>
    //             </TableCell>
    //         </TableRow>
    //     </React.Fragment>
    // );
}

export default LabItem