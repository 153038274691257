export default () => {

    const filterExcludedItems = (data) => {
        // Filter items excluded by user
        data.generalHistory = filterGeneralHistory(data.generalHistory)
        data.laboratoryStudies = filterLabStudies(data.laboratoryStudies)
        data.medications = filterMedications(data.medications)
        data.conditionSpecific = filterConditionSpecific(data.conditionSpecific)

        return data
    }

    const filterItem = (data) => {
        return data.filter(data => data.include === true || data.addedOnPortal === true)
    }

    const filterGeneralHistory = (data) => {
        data.clinicalExaminations = filterItem(data.clinicalExaminations)
        data.historyOfPresentIllness = filterItem(data.historyOfPresentIllness)
        data.historyOfPastIllness = filterItem(data.historyOfPastIllness)
        data.historyOfFamilyMemberDiseases = filterItem(data.historyOfFamilyMemberDiseases)
        data.additionalRelevantInformation = filterItem(data.additionalRelevantInformation)
        data.socialWelfareCerts = filterItem(data.socialWelfareCerts)

        return data
    }

    const filterLabStudies = (labs) => {
        // filter the lab
        labs = filterItem(labs)

        // filter lab items
        labs.forEach(lab => lab.results = filterItem(lab.results))

        // filter any labs with no items included
        labs = labs.filter(lab => lab.results.length > 0)

        return labs
    }

    const filterMedications = (data) => {
        data.currentMedications = filterItem(data.currentMedications)
        data.previousMedications = filterItem(data.previousMedications)

        return data
    }

    const filterConditionSpecific = (data) => {
        if ('medications' in data) {
            // Filter out current medications
            data.medications.currentMedications = filterItem(data.medications.currentMedications)
        }

        return data
    }


    return {
        filterExcludedItems
    }
}