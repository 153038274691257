import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import FormStepper from './wizard/formStepper/FormStepper'
import Invoice from './invoice/Invoice'
import SubmitDialog from './SubmitDialog'
import ErrorNotification from '../../ErrorNotification'

import {
    requestAtom,
    requestLoadingAtom,
    paymentDetailsAtom,
    completedRequestAtom,
    isSubmittingAtom,
    practiceAtom,
} from './requestState'
import { pmsUserAtom } from '../../state/pmsState'
import { errorAtom } from '../../state/errorState'
import useRequestState from '../../state/useRequestState'

import useApi from '../../api/useApi'
import requestApi from '../../api/requests'

import useBrowserFunctions from '../../hooks/useBrowserFunctions'
import useFormMapper from './wizard/useFormMapper'
import usePdfUtils from '../../hooks/usePdfUtils'

import usePms from '../../hooks/usePms'
import useHealthOneCallbacks from '../../hooks/useHealthOneCallbacks'


function Request() {
    const [showSubmitDialog, setShowSubmitDialog] = useState(false)

    let navigate = useNavigate()
    let { requestId } = useParams()

    const pms = usePms()

    const { setLoading, setData } = useRequestState()
    const setErrorState = useSetRecoilState(errorAtom)
    const pmsUser = useRecoilValue(pmsUserAtom)

    const getRequestApi = useApi(requestApi.getRequest)
    const updateRequestApi = useApi(requestApi.updateRequest)
    const submitRequestApi = useApi(requestApi.submitRequest)
    const healthOneCallbacks = useHealthOneCallbacks()
    const browserFunctions = useBrowserFunctions()
    const pdfUtils = usePdfUtils()
    const requestState = useRecoilValue(requestAtom)
    const requestLoadingState = useRecoilValue(requestLoadingAtom)
    const paymentDetails = useRecoilValue(paymentDetailsAtom)
    const completedRequest = useRecoilValue(completedRequestAtom)
    const setIsSubmitting = useSetRecoilState(isSubmittingAtom)
    const practiceState = useRecoilValue(practiceAtom)

    const formMapper = useFormMapper()

    const [invoice, setInvoice] = useState({
        show: false,
    })

    useEffect(() => {
        setLoading(true)

        // Load request from backend
        const fetch = async () => await getRequestApi.request(requestId)

        fetch()
            .then(result => setData(result.data))
            .catch(error => {
                // Save error message in state
                setErrorState({
                    header: 'Failed to retrieve the request.',
                    message: error.response.data.error.message
                })

                // Redirect to error page
                navigate('/request/error')
            })
            .finally(() => setLoading(false))

        // Call the PMS to get logged in user details
        pms.getPmsUser()
    }, [])

    /***
     * - Generate invoice
     * - Save data to backend
     */
    function onSubmit() {
        const doctorName = `Dr. ${pmsUser.firstName} ${pmsUser.lastName}`

        const invoiceData = {
            account: {
                iban: paymentDetails.account.iban,
                vatNumber: paymentDetails.account.vatNumber
            },
            fee: paymentDetails.fee,
            formName: requestState.form.name,
            invoiceNo: requestState.suremedReferenceId,
            cwhAccountNumber: requestState.practice.cwhAccountNumber,
            doctor: {
                name: doctorName,
                address: practiceState?.address,
                phone: practiceState?.contactDetails?.telephoneNumber
            }
        }

        // Generate Invoice PDF with callback to receive the base64 data
        pdfUtils.generateInvoicePdf(invoiceData, base64 =>
            // Save to backend (data + output pdf + invoice pdf)
            onSaveCompletedRequest(base64)
        )
    }

    /**
     * Save the progress of the report as it's filled out
     * - key : specifies the current section which the values are for
     * - values : data to save for this section
     * - viewState : current active section & completed sections
     * - isClosing : specifies if browser is to close after after save is complete
     */
    async function onSaveSection(key, values, viewState, isClosing) {
        const body = {
            patientData: { [key]: values },
            viewState: JSON.stringify(viewState)
        }

        await updateRequestApi
            .request(requestId, body)
            .then(async () => {
                // Saved to backened successfully

                // Check if browser is to be closed
                if (isClosing) {
                    // Close EO Browser in Socrates & HPM
                    browserFunctions.invokeCloseBrowser(requestId)

                    // Invoke callback to notify HealthOne to close
                    await invokeHealthOneSaveForLater()
                }
            })
            .catch(error => {
                setErrorState({
                    header: 'Failed to save the request section.',
                    message: error.response.data.error.message
                })
            })
    }

    /**
     * Submit the completed report
     * - previewPdf : the generated preview pdf in base64 format
     * - invoicePdf : the generated invoice pdf in base64 format
     * - paymentAccountId : the id of the account to be paid
     */
    async function onSaveCompletedRequest(invoicePdf) {
        const body = {
            base64Report: completedRequest.reportPdf,
            base64Invoice: invoicePdf,
            paymentAccountId: paymentDetails.account.accountId
        }

        await submitRequestApi
            .request(requestId, body)
            .then(() => {
                console.log('Request successfully submitted to backend')

                setShowSubmitDialog(false)

                // Show the Invoice
                setInvoice({
                    ...invoice,
                    show: true,
                    pdf: invoicePdf
                })
            })
            .catch(error => {
                console.error('Error submitting request to backend:', error.response.data.error.message)

                setErrorState({
                    header: 'Failed to submit the request.',
                    message: error.response.data.error.message
                })

                throw error
            })
            .finally(() => setIsSubmitting(false))
    }

    /**
     * Callback to api for HealthOne PMS systems.
     * Allows HealthOne system to hook into callback and close the web browser control when request is 
     * saved for later as HealthOne can not hook into JS callback events used by Socrates & HPM
     */
    async function invokeHealthOneSaveForLater() {
        await healthOneCallbacks.invokeSaveForLater(requestId)
            .catch(error => {
                setErrorState({
                    header: 'Failed to save the request for later.',
                    message: error.response.data.error.message
                })
            })
    }

    /**
     * Callback to api for HealthOne PMS systems.
     * Allows HealthOne system to hook into callback and close the web browser control when request is 
     * completed as HealthOne can not hook into JS callback events used by Socrates & HPM
     */
    async function invokeHealthOneComplete() {
        await healthOneCallbacks.invokeComplete(requestId)
            .catch(error => {
                setErrorState({
                    header: 'Failed to complete the request.',
                    message: error.response.data.error.message
                })
            })
    }

    /**
     * Close the embedded browser after the invoice is closed
     */
    function onCloseInvoice() {
        setInvoice({
            ...invoice,
            show: false
        })

        // Call PMS to close the browser with submit flag = true
        browserFunctions.invokeCloseBrowser(requestId, true)

        // Make callback to api to notify HealthOne PMS system request is completed & to close browser
        invokeHealthOneComplete()
    }

    if (requestLoadingState) {
        return (
            <Stack
                alignItems="center"
                spacing={3}
            >
                <CircularProgress />
            </Stack>
        )
    } else {
        return (
            <>
                <FormStepper
                    config={formMapper.getFormByType(requestState.form.type)}
                    onSave={onSaveSection}
                    onSubmit={() => {
                        // Ensure payment account has been selected before showing submit dialog
                        if (paymentDetails.account.accountId) {
                            setShowSubmitDialog(true)
                        } else {
                            // Show error message if not
                            setErrorState({
                                header: 'Please select a payment account before submitting'
                            })
                        }
                    }}
                />
                <Invoice
                    show={invoice.show}
                    data={invoice.pdf}
                    onClose={onCloseInvoice}
                />
                <SubmitDialog
                    show={showSubmitDialog}
                    onClose={() => setShowSubmitDialog(false)}
                    onSubmit={onSubmit}
                />

                <ErrorNotification />
            </>
        )
    }
}

export default Request
