import RenderField from './RenderField'

function SocialHistory({ data }) {

    const observation = data.conditionSpecific.mostRecentSummary.socialHistory

    return (
        <>
            <h5>Social History</h5>

            <table>
                <thead>
                    <tr>
                        <th />
                        <th>Item</th>
                        <th>Value</th>
                        <th>Date recorded</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th rowSpan='3'>Tobacco</th>
                        <RenderField
                            label='History of Tobacco'
                            field={observation.historyOfTobaccoUse}
                        />
                    </tr>
                    <tr>
                        <RenderField
                            label='Cigs per day'
                            field={observation.cigarettesSmokedPerDay}
                        />
                    </tr>
                    <tr>
                        <RenderField
                            label='Years smoking'
                            field={observation.yearsSmoking}
                        />
                    </tr>
                    <tr>
                        <th>Alcohol</th>
                        <RenderField
                            label='Units per week'
                            field={observation.unitOfAlcoholPerWeek}
                        />
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default SocialHistory
