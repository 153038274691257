import React from 'react'
import { TextField } from 'formik-mui';

function TextInput(props) {
    return (
        <TextField
            {...props}
            size="small"
        />
    )
}

export default TextInput
