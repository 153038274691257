import TableCell from '@mui/material/TableCell'
import { EmptyTableRow } from '../../styledComponents/StyledTableRow'

const defaultMessage = 'No information available'

export default function ({ message = defaultMessage, colSpan }) {
    return (
        <EmptyTableRow>
            <TableCell colSpan={colSpan}>
                {message}
            </TableCell>
        </EmptyTableRow>
    )
}
