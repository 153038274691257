import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'
import LabField from './LabPicker/LabField'

import { DependentInput } from '../../fieldComponents'
import { yesNoOptions } from '../../../../../constants'


export default function DiabetesQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>
            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please confirm the type of diabetes?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='typeOfDiabetes' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.typeOfDiabetes.value'
                        disabled={formik.values.questions?.typeOfDiabetes?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Please confirm the date of diagnosis?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.dateOfDiagnosis.value'
                        disabled={formik.values.questions?.dateOfDiagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='dateOfDiagnosis' />
                </Grid>
            </Grid>

            <Grid item container rowSpacing={3}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please give the following information:
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='results' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={9}>
                    <LabField
                        label='HbA1c (last 3 results):'
                        name='questions.results.subQuestions.hbA1c'
                        searchText='hbA1c'
                        disabled={formik.values.questions?.results?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={2} />

                <Grid item xs={1} />
                <Grid item xs={9}>
                    <LabField
                        label='Cholesterol (latest result):'
                        name='questions.results.subQuestions.cholesterol'
                        searchText='cholesterol'
                        disabled={formik.values.questions?.results?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={2} />
            </Grid>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Is there any evidence of the following?
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyEvidenceOf' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Retinopathy:
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.anyEvidenceOf.subQuestions.retinopathy'
                        disabled={formik.values.questions?.anyEvidenceOf.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Nephropathy:
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.anyEvidenceOf.subQuestions.nephropathy'
                        disabled={formik.values.questions?.anyEvidenceOf.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Neuropathy:
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.anyEvidenceOf.subQuestions.neuropathy'
                        disabled={formik.values.questions?.anyEvidenceOf.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        IHD:
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.anyEvidenceOf.subQuestions.ihd'
                        disabled={formik.values.questions?.anyEvidenceOf.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Peripheral vascular disease:
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.anyEvidenceOf.subQuestions.peripheralVascularDisease'
                        disabled={formik.values.questions?.anyEvidenceOf.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <DependentInput
                    condition={
                        formik.values.questions?.anyEvidenceOf.referToAttachments == false
                        && Object.values(formik.values.questions.anyEvidenceOf.subQuestions).includes('Yes')
                    }
                >
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography variant='body2'>
                            If yes to any, please give details:
                        </Typography>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={1} />
                    <Grid item xs={9}>
                        <MyTextField
                            name='questions.anyEvidenceOf.subQuestions.details'
                            disabled={formik.values.questions?.anyEvidenceOf?.referToAttachments}
                        />
                    </Grid>
                </DependentInput>
            </Grid>


            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        5.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please comment on your patient’s insight into his/her condition, compliance with treatment and overall control of diabetes.
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='commentOnPatient' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.commentOnPatient.value'
                        disabled={formik.values.questions?.commentOnPatient?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        6.
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant='body2'>
                        Does your patient have any other cardiovascular risk factors that you are aware of?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyRadioGroup
                        name='questions.anyOtherRiskFactors.value'
                        disabled={formik.values.questions?.anyOtherRiskFactors?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyOtherRiskFactors' />
                </Grid>

                <DependentInput condition={formik.values.questions.anyOtherRiskFactors.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please outline:
                        </Typography>

                        <MyTextField
                            name='questions.anyOtherRiskFactors.details'
                            disabled={formik.values.questions?.anyOtherRiskFactors?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>
        </Grid>
    )
}
