import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ContentCut from '@mui/icons-material/ContentCut'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'

export default function BasicMenu({ onTickAll, onUntickAll }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleExcludeSection = () => {
        handleClose()
    }

    const handleTickAll = () => {
        onTickAll()
        handleClose()
    }

    const handleUntickAll = () => {
        onUntickAll()
        handleClose()
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/* <MenuItem onClick={handleExcludeSection}>
                    <ListItemIcon>
                        <ContentCut fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Exclude section
                    </ListItemText>
                </MenuItem>
                <Divider /> */}
                <MenuItem onClick={handleTickAll}>
                    <ListItemIcon>
                        <PlaylistAddCheckIcon fontSize="small" />
                    </ListItemIcon>
                    Tick all
                </MenuItem>
                <MenuItem onClick={handleUntickAll}>
                    <ListItemIcon>
                        <PlaylistRemoveIcon fontSize="small" />
                    </ListItemIcon>
                    Untick all
                </MenuItem>
            </Menu>
        </>
    );
}
