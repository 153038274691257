import Component from './Pmar'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./pmar.css');
var css = cssModule.default;

const pmarConfig = {
    component: Component,
    css: css
}

export default pmarConfig