import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { useField } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import Tooltip from '@mui/material/Tooltip'
import FormHelperText from '@mui/material/FormHelperText'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'

import { labSearchTermAtom } from '../../../formComponents/laboratoryStudies/state'
import LabSearch from './LabSearch'

export default function LabField({ name, disabled, label, searchText }) {
    const [showLabSearch, setShowLabSearch] = useState(false)
    const setSearchTerm = useSetRecoilState(labSearchTermAtom)

    const [
        { value },
        { error },
        { setValue }
    ] = useField(name)
    const { results, unknown } = value


    const onOpenSearchClicked = () => {
        setSearchTerm(searchText)
        setShowLabSearch(true)
    }

    function onLabsSelected(labs) {
        setValue({
            ...value,
            results: labs,
            unknown: false
        })
    }

    function markResultsAsUnknown() {
        setValue({
            ...value,
            unknown: true
        })
    }

    const caption = () => {
        if (unknown === true) {
            return (
                'Results are unknown'
            )
        } else if (results.length > 0) {
            return (
                `${results.length} lab results selected`
            )
        } else {
            return (
                <Button
                    variant='contained'
                    size='small'
                    onClick={markResultsAsUnknown}
                    disabled={disabled}
                >
                    Confirm results are unknown
                </Button>
            )
        }
    }

    const tooltip = (
        <>
            <p><u>Search lab results:</u></p>
            <p>Click to open the lab search window where you can select the relevant lab items</p>
        </>
    )

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                p={1}
            >
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    <Typography variant='body2'>
                        {label}
                    </Typography>

                    <Tooltip title={tooltip} enterDelay={500}>
                        <IconButton
                            color='info'
                            onClick={onOpenSearchClicked}
                            disabled={disabled}
                        >
                            <ContentPasteSearchIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table size="small">
                    <caption>
                        {caption()}
                    </caption>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Identifier</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((lab) => {
                            return (
                                <TableRow
                                    key={lab.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {moment(lab.date).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell>{lab.identifier}</TableCell>
                                    <TableCell align="right">{lab.value} {lab.units}</TableCell>
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {(error && typeof error === 'string') && (
                <Grid item xs={12} m={1}>
                    <FormHelperText error variant='standard'>
                        {error}
                    </FormHelperText>
                </Grid>
            )}

            <LabSearch
                open={showLabSearch}
                onClose={() => setShowLabSearch(false)}
                onLabsSelected={onLabsSelected}
            />
        </>
    )

}