export default () => {

    function formatName(name) {
        var nameParts = [name?.prefix, name?.firstname, name?.surname, name?.suffix]

        var fullName = nameParts
            .filter(Boolean)    // remove empties
            .join(' ')          // join to single string
            .trim()             // trim leading/ending whitespace

        return fullName
    }

    function formatAddress(addr) {
        var addressParts = [
            addr.address1,
            addr.address2,
            addr.address3,
            addr.address4,
            addr.eircode
        ]

        var address = addressParts
            .filter(Boolean)
            .join(', ')
            .trim()

        return address
    }

    function formatAddressFromHL7(addr) {
        var addressParts = [
            addr.streetAddress,
            addr.otherDesignation,
            addr.city,
            addr.province,
            addr.country,
            addr.postCode
        ]

        var address = addressParts
            .filter(Boolean)    // remove empties
            .join(', ')         // join to single string
            .trim()             // trim leading/ending whitespace

        return address
    }

    return {
        formatName,
        formatAddress,
        formatAddressFromHL7
    }
}