const questions = {
    dateOfDiagnosis: {
        value: '',
        referToAttachments: false
    },
    exactTypeOfMS: {
        value: '',
        referToAttachments: false
    },
    dateOfLastFlareUp: {
        value: '',
        referToAttachments: false
    },
    degreeOfDisability: {
        value: '',
        referToAttachments: false
    },
    impactOfCurrentSymtoms: {
        value: '',
        referToAttachments: false
    }
}

export default questions
