import { FieldArray, useField } from 'formik'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import FormHelperText from '@mui/material/FormHelperText'
import { DateField } from '../Fields'

export default function DetailsControl({ name, disabled }) {
    const [field, meta] = useField(name)

    //console.log('Details - Field', field, meta)

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <Grid container spacing={1}>
                    {field.value.map((_, index) => (
                        <Grid item container spacing={1} key={index}>
                            <Grid item xs={8}>
                                <NoteField
                                    name={`${name}[${index}].note`}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DateField
                                    name={`${name}[${index}].date`}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant="contained"
                                    // size="small"
                                    color="error"
                                    disabled={disabled}
                                    onClick={() => arrayHelpers.remove(index)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </Button>
                                {/* <IconButton
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    disabled={disabled}
                                    onClick={() => arrayHelpers.remove(index)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton> */}
                            </Grid>
                        </Grid>
                    ))}
                    {(meta.error && typeof meta.error === 'string') && (
                        <Grid item xs={12}>
                            <FormHelperText error variant='standard'>
                                {meta.error}
                            </FormHelperText>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            size="small"
                            color="success"
                            variant="contained"
                            disabled={disabled}
                            startIcon={<AddIcon />}
                            onClick={() => arrayHelpers.push({ note: '', date: '' })}
                        >
                            Add
                        </Button>
                        {/* <IconButton
                            size="small"
                            color="success"
                            disabled={disabled}
                            onClick={() => arrayHelpers.push({ note: '', date: '' })}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton> */}
                    </Grid>
                </Grid>
            )}
        />
    )
}


function NoteField({ name, disabled }) {
    const [
        { value, onChange },    // field
        { error, touched },     // meta
        { }                     // helpers
    ] = useField(name)


    return (
        <TextField
            size="small"
            multiline
            fullWidth
            name={name}
            value={value}
            onChange={onChange}
            error={error && touched}
            helperText={touched && error}
            disabled={disabled}
        />
    )
}
