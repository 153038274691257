import { renderToStaticMarkup } from 'react-dom/server'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'

import { FORM_CODES } from '../../../constants'

import {
    pmarConfig,
    missingInformationConfig,
    tumourConfig,
    mentalHealthConfig,
    strokeConfig,
    multipleSclerosisConfig,
    sarcoidosisConfig,
    heartDiseaseConfig,
    hepatitisConfig,
    kidneyConfig,
    respiratoryConfig,
    diabetesConfig
} from './templates'

function Template({ templateType, data }) {
    // Get object containing the component for the template type specified
    const template = ({
        [FORM_CODES.pmar]: pmarConfig,
        [FORM_CODES.mir]: missingInformationConfig,
        [FORM_CODES.tumour]: tumourConfig,
        [FORM_CODES.mentalHealth]: mentalHealthConfig,
        [FORM_CODES.stroke]: strokeConfig,
        [FORM_CODES.multipleSclerosis]: multipleSclerosisConfig,
        [FORM_CODES.sarcoidosis]: sarcoidosisConfig,
        [FORM_CODES.heartDisease]: heartDiseaseConfig,
        [FORM_CODES.hepatitis]: hepatitisConfig,
        [FORM_CODES.kidney]: kidneyConfig,
        [FORM_CODES.respiratory]: respiratoryConfig,
        [FORM_CODES.diabetes]: diabetesConfig
    })[templateType]
    
    // Render component to html
    const component = <template.component data={data} />
    const componentHtml = renderToStaticMarkup(component)

    // Remove any unsafe html
    const cleanHtml = DOMPurify.sanitize(componentHtml, {
        USE_PROFILES: { html: true }
    })

    return (
        <html>
            <head>
                <style type='text/css'>
                    {template.css}
                </style>
            </head>
            <body>
                {parse(cleanHtml)}
            </body>
        </html>
    )
}

export default Template