import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import CircularProgress from '@mui/material/CircularProgress'

import { completedRequestAtom } from '../../../requestState'
import useReportGenerator from '../../../../../hooks/useReportGenerator'
import useBufferUtils from '../../../../../hooks/useBufferUtils'

const styles = {
    iframe: {
        display: 'block',
        background: '#000',
        border: 'none',
        //height: '100%',
        height: '500px',
        width: '100%'
    }
}

export default function Review() {

    const completedRequest = useRecoilValue(completedRequestAtom)

    var reportGenerator = useReportGenerator()
    var bufferUtils = useBufferUtils()

    useEffect(() => {
        reportGenerator.ensureReportGenerated()
    }, [])

    // Check if report was generated yet
    if (completedRequest.reportPdf) {
        // Convert report base64 to blob 
        // We need to convert to a blob as the iframe cannot handle when the base64 string goes above 2000 characters)
        var blob = bufferUtils.convertBase64ToBlob(completedRequest.reportPdf, 'application/pdf')

        return (
            <iframe
                src={URL.createObjectURL(blob)}
                style={styles.iframe}
            />
        )
    } else {
        return (
            <CircularProgress />
        )
    }
}
