import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import { useField } from 'formik'
import { FormControl, FormHelperText } from '@mui/material'

const labelProps = {
    typography: { variant: 'body2' }
}

export default function RadioGroupInput({ name, options, disabled, ...props }) {
    const [field] = useField(name)

    var radioOptions = null

    if (typeof options[0] === 'string') {
        // Options are strings
        radioOptions = options.map((option) => (
            <FormControlLabel
                key={option}
                value={option}
                label={option}
                componentsProps={labelProps}
                control={<Radio size="small" />}
                size="small"
            />
        ))
    } else if (typeof options[0] === 'object') {
        // Options are objects
        radioOptions = options.map(({ label, value }) => (
            <FormControlLabel
                key={value}
                value={value}
                label={label}
                componentsProps={labelProps}
                control={<Radio size="small" />}
                size="small"
            />
        ))
    }

    return (
        <FormControl error={props.error} disabled={disabled}>
            <RadioGroup name={name} {...field} row>
                {radioOptions}
            </RadioGroup>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    )
}
