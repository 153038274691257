import RenderField from '../RenderField'
import useArrayUtils from '../../../../../../../hooks/useArrayUtils'

export default function SocialWelfareCerts({ certs }) {
    const { isArrayEmpty } = useArrayUtils()

    return (
        <>
            <h5>Social Welfare Certs</h5>
            <table>
                <thead>
                    <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Illness</th>
                    </tr>
                </thead>
                <tbody>
                    {isArrayEmpty(certs) && (
                        <tr><td colSpan={3}>No information available</td></tr>
                    )}
                    {certs.map((cert, idx) => (
                        <tr key={idx}>
                            <RenderField>{cert.fromDate}</RenderField>
                            <RenderField>{cert.toDate}</RenderField>
                            <RenderField>{cert.illness}</RenderField>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
