import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Field } from 'formik'
import moment from 'moment'

import useArrayUtils from '../../../../../../../hooks/useArrayUtils'
import IncludableTableRow from '../../../../fieldComponents/table/IncludableTableRow'
import StyledIncludeTableCell from '../../../../styledComponents/StyledIncludeTableCell'
import EmptyTableRow from '../../../generic/EmptyTableRow'

function ClinicalExamination({ exams }) {
    const { isArrayEmpty } = useArrayUtils()

    return (
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Systolic</TableCell>
                    <TableCell>Diastolic</TableCell>
                    <TableCell>Pulse</TableCell>
                    <TableCell>Height</TableCell>
                    <TableCell>Weight</TableCell>
                    <TableCell>BMI</TableCell>
                    <StyledIncludeTableCell>Include</StyledIncludeTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {isArrayEmpty(exams) ? (
                    <EmptyTableRow
                        colSpan={8}
                    />
                ) : exams.map((exam, index) => (
                    <IncludableTableRow key={index} isIncluded={exam.include}>
                        <TableCell>{moment(exam.observationDateTime).format('DD/MM/yyyy')}</TableCell>
                        <RenderField>{exam.systolicBloodPressure}</RenderField>
                        <RenderField>{exam.diastolicBloodPressure}</RenderField>
                        <RenderField>{exam.pulse}</RenderField>
                        <RenderField>{exam.height}</RenderField>
                        <RenderField>{exam.weight}</RenderField>
                        <RenderField>{exam.bmi}</RenderField>
                        <StyledIncludeTableCell>
                            <Field
                                type="checkbox"
                                name={`clinicalExaminations[${index}].include`}
                            />
                        </StyledIncludeTableCell>
                    </IncludableTableRow>
                ))}
            </TableBody>
        </Table>
    )
}

function RenderField({ children }) {
    let value = null;

    // Some values will be null so we need to check for this before rendering
    if (children != null) {
        value = `${children.value} ${children.units.text}`
    }

    return (
        <TableCell>{value}</TableCell>
    )
}

export default ClinicalExamination