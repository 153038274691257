import Questions from './Questions'
import SocialWelfareCerts from './SocialWelfareCerts'
import ClinicalExamination from './ClinicalExamination'
import GeneralHistoryItem from './GeneralHistoryItem'

function GeneralHistory({ data }) {
    const generalHistory = data.generalHistory

    return (
        <>
            <h3>Medical History</h3>

            <Questions questions={generalHistory.questions} />

            <ClinicalExamination exams={generalHistory.clinicalExaminations} />

            <GeneralHistoryItem name="Active Conditions" data={generalHistory.historyOfPresentIllness} />
            <GeneralHistoryItem name="All Attendances" data={generalHistory.historyOfPastIllness} />
            <GeneralHistoryItem name="Additional Information" data={generalHistory.additionalRelevantInformation} />

            <SocialWelfareCerts certs={generalHistory.socialWelfareCerts} />
        </>
    )
}

export default GeneralHistory
