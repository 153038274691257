import useArrayUtils from '../../../../../hooks/useArrayUtils'

function Medications({ data }) {
    const { isArrayEmpty } = useArrayUtils()

    const currentMeds = data.conditionSpecific.medications.currentMedications
    //const previousMeds = data.medications.previousMedications

    return (
        <>
            {/* <h3>Medications</h3> */}
            <h5>Current Medications</h5>
            {isArrayEmpty(currentMeds) ? (
                <p>No information available</p>
            ) : currentMeds.map((med, idx) => (
                <p key={idx}>{med.value}</p>
            ))}

            {/* <h5>Previous Medications</h5>
            {isArrayEmpty(previousMeds) ? (
                <p>No information available</p>
            ) : previousMeds.map((med, idx) => (
                <p key={idx}>{med.value}</p>
            ))} */}
        </>
    )
}

export default Medications