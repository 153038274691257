import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import OutlinedCard from './OutlinedCard'
import OutputObxValue, { OutputObxDate } from '../../../fieldComponents/OutputObxValue';

export default function SocialHistorySummary() {
    const observation = useFormikContext().values.mostRecentSummary.socialHistory

    return (
        <OutlinedCard header='Social History'>
            <Grid container spacing={2}>
                <Grid item container spacing={1}>
                    <Grid item>
                        <strong>Tobacco usage:</strong>
                    </Grid>

                    <Grid item container justifyContent='space-between'>
                        <Typography variant='body2'>
                            - History of tobacco:
                        </Typography>

                        <Stack direction='row'>
                            <Typography variant='body2'>
                                <strong><OutputObxValue>{observation.historyOfTobaccoUse}</OutputObxValue></strong>
                            </Typography>
                            <Typography variant='caption'>
                                <OutputObxDate field={observation.historyOfTobaccoUse} />
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item container justifyContent='space-between'>
                        <Typography variant='body2'>
                            - Cigarettes per day:
                        </Typography>
                        <Stack direction='row'>
                            <Typography variant='body2'>
                                <strong><OutputObxValue>{observation.cigarettesSmokedPerDay}</OutputObxValue></strong>
                            </Typography>
                            <Typography variant='caption'>
                                <OutputObxDate field={observation.cigarettesSmokedPerDay} />
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item container justifyContent='space-between'>
                        <Typography variant='body2'>
                            - Years smoking:
                        </Typography>
                        <Stack direction='row'>
                            <Typography variant='body2'>
                                <strong><OutputObxValue>{observation.yearsSmoking}</OutputObxValue></strong>
                            </Typography>
                            <Typography variant='caption'>
                                <OutputObxDate field={observation.yearsSmoking} />
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid item container spacing={1}>
                    <Grid item>
                        <strong>Alcohol usage:</strong>
                    </Grid>
                    <Grid item container justifyContent='space-between'>
                        <Typography variant='body2'>
                            - Units per week:
                        </Typography>
                        <Stack direction='row'>
                            <Typography variant='body2'>
                                <strong><OutputObxValue>{observation.unitOfAlcoholPerWeek}</OutputObxValue></strong>
                            </Typography>
                            <Typography variant='caption'>
                                <OutputObxDate field={observation.unitOfAlcoholPerWeek} />
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </OutlinedCard>
    )
}