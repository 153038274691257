export default function Question(props) {
    // Extract props and default show = true if it is not specified
    const { children, answer, suffix, newLine, answerOnNewLine, show = true } = props

    if (show === false) {
        return null
    } else if (answer) {
        if (newLine === true && answerOnNewLine === true) {
            return (
                <span>
                    <strong>{children}</strong>
                    <br />
                    {answer}{suffix}
                </span>
            )
        } else if (newLine === true) {
            return (
                <span>
                    <br />
                    <strong>{children}</strong> {answer}{suffix}
                </span>
            )
        } else {
            return <span><strong>{children}</strong> {answer}{suffix}</span>
        }
    } else {
        return (
            <strong>{children}</strong>
        )
    }
}

export function QuestionsContainer({ children }) {
    return (
        <table valign='top' style={{ border: 'none' }}>
            {children}
        </table>
    )
}

export function QuestionWrapper({ number, children }) {
    return (
        <tr valign='top' style={{ border: 'none' }}>
            <td style={{ border: 'none', verticalAlign: 'top' }}>
                <strong>
                    {number ? `${number}.` : null}
                </strong>
            </td>
            <td style={{ border: 'none', verticalAlign: 'top' }}>
                {children}
            </td>
        </tr>
    )
}