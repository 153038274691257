import {
    ConditionSpecificIntroduction,
    Attachments,
    Review,
    Submit
} from '../../formComponents'

import HepatitisComponent from './HepatitisComponent'

const hepatitisConfig = {
    title: 'Hepatitis Questionnaire',
    steps: [
        {
            label: "Introduction",
            key: 'Introduction',
            component: ConditionSpecificIntroduction,
            hasForm: false,
            restrictToDoctor: false
        },
        {
            label: "Hepatitis",
            key: 'ConditionSpecific',
            component: HepatitisComponent,
            hasForm: true,
            restrictToDoctor: false
        },
        {
            label: 'File Attachments',
            key: 'Attachments',
            component: Attachments,
            hasForm: true,
            restrictToDoctor: false
        },
        {
            label: 'Review',
            key: 'Review',
            component: Review,
            hasForm: false,
            restrictToDoctor: false
        },
        {
            label: 'Submit',
            key: 'Submit',
            component: Submit,
            hasForm: false,
            restrictToDoctor: true
        }
    ]
}

export default hepatitisConfig