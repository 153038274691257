import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default function GenericDialog({
    open,
    title: Title,
    content: Content,
    buttons: Buttons
}) {

    if (!open) {
        return null
    }

    return (
        <Dialog
            open={open}
            scroll='paper'
            fullWidth
            fullScreen
        >
            <DialogTitle>
                <Title />
            </DialogTitle>
            <DialogContent dividers={true}>
                <Content />
            </DialogContent>
            <DialogActions>
                <Buttons />
            </DialogActions>
        </Dialog>
    )
}
