import { FastField, FieldArray } from 'formik'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import StyledIncludeTableCell from '../../../../../styledComponents/StyledIncludeTableCell'

import useArrayUtils from '../../../../../../../../hooks/useArrayUtils'
import EmptyTableRow from '../../../../generic/EmptyTableRow'
import NewItem from './NewItem'
import ExistingItem from './ExistingItem'
import AddItem from './AddItem'

export default function AdditionalInformation({ data }) {
    const { isArrayEmpty } = useArrayUtils()

    const binding = 'additionalRelevantInformation'

    return (
        // Wrapping this component in FastField to prevent unnecessary re-renders which was slowing down UI experience
        <FastField name={binding}>
            {({ form }) => {

                const onFieldValueChanged = (field, newValue) => {
                    form.setFieldValue(field, newValue)
                }

                return (
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '60px' }}>Date</TableCell>
                                <TableCell>Note</TableCell>
                                <TableCell align="center" padding="checkbox">Edit</TableCell>
                                <StyledIncludeTableCell>Include</StyledIncludeTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Output each note in the section */}
                            <FieldArray
                                name={binding}
                                render={arrayHelpers => (
                                    <>
                                        {isArrayEmpty(data) ? (
                                            <EmptyTableRow 
                                                colSpan={4}
                                            />
                                        ) : data.map((item, index) => {
                                            const itemBinding = `${binding}.[${index}]`

                                            if (item.addedOnPortal) {
                                                return (
                                                    <NewItem
                                                        key={index}
                                                        item={item}
                                                        onChange={(newValue) => onFieldValueChanged(itemBinding, newValue)}
                                                        onRemove={() => arrayHelpers.remove(index)}
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <ExistingItem
                                                        key={index}
                                                        item={item}
                                                        binding={itemBinding}
                                                        onChange={(newValue) => onFieldValueChanged(itemBinding, newValue)}
                                                    />
                                                )
                                            }
                                        })}

                                        <TableRow>
                                            <AddItem onAdd={item => arrayHelpers.push(item)} />
                                        </TableRow>
                                    </>
                                )}
                            />
                        </TableBody>
                    </Table>
                )
            }}
        </FastField>
    )
}
