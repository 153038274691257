import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import OutputDetails from '../components/output/OutputDetails'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function MentalHealthDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Mental Health Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>Please confirm the exact diagnosis?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.confirmExactDiagnosis.referToAttachments}>
                        <p>{questions.confirmExactDiagnosis.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='2'>
                    <Question>What were the presenting symptoms?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.presentingSymptoms.referToAttachments}>
                        <p>{questions.presentingSymptoms.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>When did symptoms first occur?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.dateOfFirstSymptoms.referToAttachments}>
                        {formatDateMonthYear(questions.dateOfFirstSymptoms.value)}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='4'>
                    <Question>Date of last symptoms?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.dateOfLastSymptoms.referToAttachments}>
                        {formatDateMonthYear(questions.dateOfLastSymptoms.value)}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='5'>
                    <Question>Within the past five years please confirm:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.past5Years.referToAttachments}>

                        <QuestionsContainer>
                            <QuestionWrapper number='a'>
                                <Question
                                    answer={questions.past5Years.subQuestions.numOfEpisodes.value}
                                >
                                    number of episodes:
                                </Question>
                            </QuestionWrapper>

                            <QuestionWrapper number='b'>
                                <Question
                                    answer={questions.past5Years.subQuestions.anyInpatientAdmissions.value}
                                >
                                    has there been any hospital admissions?
                                </Question>

                                {questions.past5Years.subQuestions.anyInpatientAdmissions.value === 'Yes' && (
                                    <>
                                        <Question
                                            answer={questions.past5Years.subQuestions.anyInpatientAdmissions.subQuestions.numOfInpatientAdmissions.value}
                                            newLine
                                        >
                                            number of inpatient admissions:
                                        </Question>

                                        <Question
                                            answer={formatDateMonthYear(questions.past5Years.subQuestions.anyInpatientAdmissions.subQuestions.dateOfLastAdmissionFrom.value)}
                                            newLine
                                        >
                                            last admission start:
                                        </Question>

                                        <Question
                                            answer={formatDateMonthYear(questions.past5Years.subQuestions.anyInpatientAdmissions.subQuestions.dateOfLastAdmissionTo.value)}
                                            newLine
                                        >
                                            last admission end:
                                        </Question>
                                    </>
                                )}
                            </QuestionWrapper>

                            <QuestionWrapper number='c'>
                                <Question
                                    answer={questions.past5Years.subQuestions.anyEpisodesOfSelfHarmEtc.value}
                                >
                                    any episodes of self-harm, suicidal ideation or attempts?
                                </Question>
                                {questions.past5Years.subQuestions.anyEpisodesOfSelfHarmEtc.value === 'Yes' && (
                                    <>
                                        <Question>If yes, please provide details and dates</Question>
                                        <OutputDetails details={questions.past5Years.subQuestions.anyEpisodesOfSelfHarmEtc.details} />
                                    </>
                                )}
                            </QuestionWrapper>
                        </QuestionsContainer>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='6'>
                    <Question>Has the mental health disorder caused any absence from work greater than two weeks in the last five years?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyRelatedAbsenceFromWork.referToAttachments}>
                        <p>{questions.anyRelatedAbsenceFromWork.value}</p>
                        {questions.anyRelatedAbsenceFromWork.value === 'Yes' && (
                            <>
                                <Question>If yes, please provide details and dates</Question>
                                <OutputDetails
                                    details={questions.anyRelatedAbsenceFromWork.details}
                                />
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='7'>
                    <Question>Are there any other relevant features which may influence the prognosis of the disorder such as any history of alcohol or substance abuse, co-existing physical illness, behaviour abnormalities etc?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyRelevantFeaturesInfluencingPrognosis.referToAttachments}>
                        <p>{questions.anyRelevantFeaturesInfluencingPrognosis.value}</p>
                        {questions.anyRelevantFeaturesInfluencingPrognosis.value === 'Yes' && (
                            <>
                                <Question>If yes, please provide details and dates</Question>
                                <OutputDetails
                                    details={questions.anyRelevantFeaturesInfluencingPrognosis.details}
                                />
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='8'>
                    <Question>Please comment on your patient’s current mental health</Question>
                    <ReferrableQuestionWrapper isReferred={questions.commentOnCurrentMentalHealth.referToAttachments}>
                        <p>{questions.commentOnCurrentMentalHealth.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}
