import { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import Select from '@mui/material/Select'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { paymentDetailsAtom } from '../../../../requestState'
import { errorAtom } from '../../../../../../state/errorState'
import { userMcnSelector } from '../../../../../../state/pmsState'
import useApi from '../../../../../../api/useApi'
import paymentMethodApi from '../../../../../../api/paymentMethods'
import useAccountUtils from '../../../../../../hooks/useAccountUtils'
import AddPaymentMethod from './AddPaymentMethod'

export default function SelectPaymentMethod() {
    const pmsUserMcn = useRecoilValue(userMcnSelector)
    const [paymentDetails, setPaymentDetails] = useRecoilState(paymentDetailsAtom)
    const [showAddScreen, setShowAddScreen] = useState(false)
    const [newAccountId, setNewAccountId] = useState(null)
    const paymentApi = useApi(paymentMethodApi.getPaymentDetails)
    const { calculateRequestFee } = useAccountUtils()

    const setErrorState = useSetRecoilState(errorAtom)

    useEffect(() => {
        loadPaymentMethods()
    }, [])

    async function loadPaymentMethods() {
        // Load payment methods for pms user
        return await paymentApi
            .request(pmsUserMcn)
            .catch(error => {
                setErrorState({
                    header: 'Failed to load payment accounts.',
                    message: error.response.data.error.message
                })
            })
    }

    useEffect(() => {
        if (paymentApi.data) {
            const { accounts, lastUsedAccountId } = paymentApi.data

            if (newAccountId) {
                // Select the newly created account by default
                onAccountChange(newAccountId)
            } else if (lastUsedAccountId) {
                // Select the last used account by default
                onAccountChange(lastUsedAccountId)
            } else if (accounts.length === 1) {
                // If no account was used previously and only one exists, select it by default
                onAccountChange(accounts[0].accountId)
            } else {
                // If no accounts exist, raise the event anyway to calculate the fee
                onAccountChange()
            }
        }
    }, [paymentApi.data, newAccountId])

    function onAccountChange(accId) {
        // Get all accounts or initialize empty array if none exist
        const accounts = paymentApi.data?.accounts ?? []

        // Find the relevant account by id
        const account = accounts.find(a => a.accountId === accId)

        // Calculate the fee based on the selected account (calulates VAT amounts etc.)
        const fee = calculateRequestFee(account)

        // Set the account & fee in state
        setPaymentDetails({
            account: account,
            fee: fee
        })
    }


    if (paymentApi.loading) {
        return <CircularProgress />
    } else if (paymentApi.data != null) {
        return (
            <>
                <Select
                    value={paymentDetails?.account?.accountId || null}
                    onChange={e => onAccountChange(e.target.value)}
                    displayEmpty
                    style={{ width: 300 }}
                >
                    {paymentApi.data.accounts.map(account => (
                        <MenuItem
                            key={account.accountId}
                            value={account.accountId}
                        >
                            <Container sx={{ mb: 1 }}>
                                <Typography variant='subtitle2'>{account.accountHolder}</Typography>
                                <Typography variant='body2'>IBAN: {account.iban}</Typography>
                                <Typography variant='body2'>{account.includeVat ? 'VAT is charged' : 'VAT not charged'}</Typography>
                            </Container>
                        </MenuItem>
                    ))}
                    <Button size="small" fullWidth endIcon={<AddIcon />} onClick={() => setShowAddScreen(true)}>
                        <h3>
                            Add new account
                        </h3>
                    </Button>
                </Select>

                <AddPaymentMethod
                    show={showAddScreen}
                    onAddComplete={accId => {
                        // Reload the accounts
                        loadPaymentMethods()
                            .then(() => {
                                // Store the new account id
                                setNewAccountId(accId)
                                setShowAddScreen(false)
                            })
                    }}
                    onClose={() => setShowAddScreen(false)}
                />
            </>
        )
    } else {
        return (
            <Select
                displayEmpty
                style={{ width: 300 }}
            />
        )
    }
}
