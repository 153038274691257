import { useSearchParams } from 'react-router-dom'

export default () => {
     let [searchParams] = useSearchParams()

    const getPmsUserFromUrl = () => {        
        // Try get PMS user details from url parameters
        const isUserDoctor = searchParams.get('isUserDoctor')
        const firstName = searchParams.get('firstName')
        const lastName = searchParams.get('lastName')
        const medCouncilNo = searchParams.get('medCouncilNo')

        // Check if all params are present
        if (isUserDoctor && firstName && lastName && medCouncilNo) {
            // If all params are present, return the PMS user details
            return {
                isUserDoctor: isUserDoctor,
                firstName: firstName,
                lastName: lastName,
                medCouncilNo: medCouncilNo
            }
        } else {
            // PMS user not passed in through query string params
            return null
        }
    }

    return {
        getPmsUserFromUrl
    }
}