import { string, object } from 'yup'

import { yesNoValidOptions } from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object().shape({

        localisedToOrigin: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(yesNoValidOptions)
        }),

        exactStaging: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        sizeOfTumour: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        detailsOfTreatment: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        anyLymphNodesInvolved: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            numLymphNodes: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        }),

        anyMetastases: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(yesNoValidOptions)
        }),

        dateTreatmentFinished: object({
            value: string()
                .when('referToAttachments', {
                    is: false,
                    then: string().ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate()
                })
        }),

        completelyEradicated: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(yesNoValidOptions)
        }),
    })
})

export default validation