import * as yup from 'yup'
import moment from 'moment'

yup.addMethod(yup.string, 'isValidDate', function () {
    return this.test('isValidDate', 'Date must be in format MMMM YYYY', (val) => {
        if (!val) {
            return false
        }

        return moment(val, 'DD/MM/yyyy', true).isValid()
    })
})

yup.addMethod(yup.string, 'isPastDate', function () {
    return this.test('isPastDate', 'Date must be in the past', (val) => {
        if (!val) {
            return false
        }

        return moment(val, 'DD/MM/yyyy').isBefore()
    })
})
