import * as yup from 'yup'

const MissingInformationValidation = yup.object({

    comments: yup.object({
        value: yup.string()
            .nullable()
            .required('Please enter a reply')
    })

})

export default MissingInformationValidation