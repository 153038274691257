import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'

import { DependentInput } from '../../fieldComponents'
import { yesNoOptions } from '../../../../../constants'

export default function TumourQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Was it localised to the tissue or organ of origin?
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.localisedToOrigin.value'
                        disabled={formik.values.questions?.localisedToOrigin.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='localisedToOrigin' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Exact Staging/histology:
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='exactStaging' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.exactStaging.value'
                        disabled={formik.values.questions?.exactStaging?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Size of tumour:
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='sizeOfTumour' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.sizeOfTumour.value'
                        disabled={formik.values.questions?.sizeOfTumour?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Were any lymph nodes involved?
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.anyLymphNodesInvolved.value'
                        disabled={formik.values.questions?.anyLymphNodesInvolved.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyLymphNodesInvolved' />
                </Grid>

                <DependentInput condition={formik.values.questions.anyLymphNodesInvolved.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, confirm how many:
                        </Typography>

                        <MyTextField
                            name='questions.anyLymphNodesInvolved.numLymphNodes'
                            disabled={formik.values.questions?.anyLymphNodesInvolved.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        5.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Were there any metastases?
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.anyMetastases.value'
                        disabled={formik.values.questions?.anyMetastases.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyMetastases' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        6.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Details of Treatment:
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='detailsOfTreatment' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.detailsOfTreatment.value'
                        disabled={formik.values.questions?.detailsOfTreatment?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        7.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date treatment finished:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.dateTreatmentFinished.value'
                        disabled={formik.values.questions?.dateTreatmentFinished?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='dateTreatmentFinished' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        8.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Completely eradicated/no recurrence
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.completelyEradicated.value'
                        disabled={formik.values.questions?.completelyEradicated.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='completelyEradicated' />
                </Grid>
            </Grid>
        </Grid>
    )
}