import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Field } from 'formik'

import useArrayUtils from '../../../../../../../hooks/useArrayUtils'
import EmptyTableRow from '../../../generic/EmptyTableRow'
import IncludableTableRow from '../../../../fieldComponents/table/IncludableTableRow'
import StyledIncludeTableCell from '../../../../styledComponents/StyledIncludeTableCell'

function SocialWelfareCerts({ data }) {
    const { isArrayEmpty } = useArrayUtils()

    return (
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Illness</TableCell>
                    <StyledIncludeTableCell>Include</StyledIncludeTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {isArrayEmpty(data) ? (
                    <EmptyTableRow
                        colSpan={4}
                    />
                ) : data.map((cert, index) => (
                    <IncludableTableRow key={index} isIncluded={cert.include}>
                        <RenderField>{cert.fromDate}</RenderField>
                        <RenderField>{cert.toDate}</RenderField>
                        <RenderField>{cert.illness}</RenderField>
                        <StyledIncludeTableCell>
                            <Field
                                type="checkbox"
                                name={`socialWelfareCerts[${index}].include`}
                            />
                        </StyledIncludeTableCell>
                    </IncludableTableRow>
                ))}
            </TableBody>
        </Table>
    )
}

function RenderField({ children }) {
    let value = null

    // Some values will be null so we need to check for this before rendering
    if (children != null) {
        value = children.value
    }

    return (
        <TableCell>{value}</TableCell>
    )
}

export default SocialWelfareCerts