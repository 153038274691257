import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import Expander from '../../../fieldComponents/expander/Expander'
import MedicationList from './MedicationList'
import { EmptyTypography } from '../../../styledComponents/StyledTableRow'
import useArrayUtils from '../../../../../../hooks/useArrayUtils'

export default function MedicationSection({ name, binding, data }) {
    const { isArrayEmpty } = useArrayUtils()

    let content = null

    if (isArrayEmpty(data)) {
        // If data is empty output message
        content = (
            <EmptyTypography variant='body2' sx={{ px: 2 }}>
                No information available
            </EmptyTypography>
        )
    } else {
        content = (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Medication</TableCell>
                        <TableCell align="center" padding="checkbox">Include</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <MedicationList binding={binding} meds={data} />
                </TableBody>
            </Table>
        )
    }

    return (
        <Expander header={name} binding={binding}>
            {content}
        </Expander>
    )
}