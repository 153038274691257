import { useSetRecoilState } from 'recoil'

import { requestLoadingAtom, requestAtom, requestViewStateAtom, patientAtom, practiceAtom } from '../views/request/requestState'
import socialHistoryAtom from '../views/request/wizard/formComponents/socialHistory/state'
import generalHistoryAtom from '../views/request/wizard/formComponents/generalHistory/state'
import laboratoryStudiesAtom from '../views/request/wizard/formComponents/laboratoryStudies/state'
import medicationsAtom from '../views/request/wizard/formComponents/medications/state'
import attachmentsAtom, { attachmentFilesAtom } from '../views/request/wizard/formComponents/attachments/state'
import conditionSpecificAtom from '../views/request/wizard/formComponents/conditionSpecific/state'
import missingInformationAtom from '../views/request/wizard/formComponents/missingInformation/state'


import { isConditionSpecific, mapConditionSpecificData } from '../hooks/formTypeUtils'

export default () => {
    const setRequestLoadingState = useSetRecoilState(requestLoadingAtom)
    const setRequestViewState = useSetRecoilState(requestViewStateAtom)
    const setRequestState = useSetRecoilState(requestAtom)
    const setPatientState = useSetRecoilState(patientAtom)
    const setPracticeState = useSetRecoilState(practiceAtom)
    const setGeneralHistoryState = useSetRecoilState(generalHistoryAtom)
    const setSocialHistoryState = useSetRecoilState(socialHistoryAtom)
    const setLaboratoryStudiesState = useSetRecoilState(laboratoryStudiesAtom)
    const setMedicationsState = useSetRecoilState(medicationsAtom)
    const setAttachmentsState = useSetRecoilState(attachmentsAtom)
    const setAttachmentsFilesState = useSetRecoilState(attachmentFilesAtom)
    const setConditionSpecificState = useSetRecoilState(conditionSpecificAtom)
    const setMissingInformationState = useSetRecoilState(missingInformationAtom)


    const setLoading = (isLoading) => setRequestLoadingState(isLoading)

    const setData = (request) => {
        // Extract view state
        if (request.viewState) {
            const viewState = JSON.parse(request.viewState)
            setRequestViewState(viewState)
        }

        // Split request details & patient data for storing in state
        const { patientData, ...requestDetails } = request

        setPracticeState(patientData.practice || {})

        // Update state for each tab
        setRequestState(requestDetails)
        setPatientState(patientData.patient)
        setGeneralHistoryState(patientData.generalHistory)
        setSocialHistoryState(patientData.socialHistory)
        setLaboratoryStudiesState(patientData.laboratoryStudies)
        setMedicationsState(patientData.medications)
        setAttachmentsState(patientData.attachments)
        setAttachmentsFilesState(requestDetails.attachments)

        // Check if request is a Condition Specific Questionnaire
        if (isConditionSpecific(request.form.type)) {
            // Map string values to JSON object
            var conditionSpecificData = mapConditionSpecificData(request.form.type, patientData.conditionSpecific)

            setConditionSpecificState(conditionSpecificData)
        }
        // Check if request is a Missing Information Request
        else if (request.form.type === 'MIR') {
            setMissingInformationState(patientData.missingInformation)
        }
    }

    return {
        setLoading,
        setData
    }
}