import { string, object, array } from 'yup'

import { yesNoValidOptions } from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object({

        typeOfDiabetes: object({
            value: string().required('Please enter details')
        }),

        dateOfDiagnosis: object({
            value: string()
                .when('referToAttachments', {
                    is: false,
                    then: string().ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate()
                })
        }),

        results: object({
            subQuestions: object().when('referToAttachments', {
                is: false,
                then: object({
                    hbA1c: object().test(
                        'hbA1c',
                        'Please select lab items using the search icon above or confirm that results are unknown',
                        async function ({ unknown, results }) {
                            // Field is valid if:
                            // 1. user confirms lab results are unknown
                            // OR
                            // 2. lab items are selected

                            if (unknown === true) {
                                return true
                            } else {
                                // Check results array contains at least one item
                                let schema = array().of(object({})).min(1)

                                return await schema.isValid(results)
                            }
                        }
                    ),
                    cholesterol: object().test(
                        'cholesterol',
                        'Please select lab items using the search icon above or confirm that results are unknown',
                        async function ({ unknown, results }) {
                            // Field is valid if:
                            // 1. user confirms lab results are unknown
                            // OR
                            // 2. lab items are selected

                            if (unknown === true) {
                                return true
                            } else {
                                // Check results array contains at least one item
                                let schema = array().of(object({})).min(1)

                                return await schema.isValid(results)
                            }
                        }
                    )
                })
            })
        }),

        anyEvidenceOf: object({
            subQuestions: object().when('referToAttachments', {
                is: false,
                then: object({
                    retinopathy: string()
                        .required('Please select a value')
                        .oneOf(yesNoValidOptions),
                    nephropathy: string()
                        .required('Please select a value')
                        .oneOf(yesNoValidOptions),
                    neuropathy: string()
                        .required('Please select a value')
                        .oneOf(yesNoValidOptions),
                    ihd: string()
                        .required('Please select a value')
                        .oneOf(yesNoValidOptions),
                    peripheralVascularDisease: string()
                        .required('Please select a value')
                        .oneOf(yesNoValidOptions),
                    details: string().when(['retinopathy', 'nephropathy', 'neuropathy', 'ihd', 'peripheralVascularDisease'], {
                        is: (retinopathy, nephropathy, neuropathy, ihd, peripheralVascularDisease) => (
                            retinopathy === 'Yes' ||
                            nephropathy === 'Yes' ||
                            neuropathy === 'Yes' ||
                            ihd === 'Yes' ||
                            peripheralVascularDisease === 'Yes'
                        ),
                        then: string().required('Please enter details')
                    })
                })
            })
        }),

        commentOnPatient: object({
            value: string()
                .required('Please enter details')
        }),

        anyOtherRiskFactors: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string().required('Please enter details')
            })
        })

    })
})

export default validation