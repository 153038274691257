import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import { MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'
import { DependentInput } from '../../fieldComponents'

import {
    sarcoidosisInitialStageOptions,
    sarcoidosisComplicationsOptions,
    sarcoidosisLesionsOptions,
    yesNoOptions
} from '../../../../../constants'

export default function SarcoidosisQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please confirm the staging of the disease at initial diagnosis:
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='initialStage' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <MyRadioGroup
                        name='questions.initialStage.value'
                        disabled={formik.values.questions?.initialStage.referToAttachments}
                        options={sarcoidosisInitialStageOptions}
                    />
                </Grid>
                <Grid item xs={1} />


                <Grid item xs={1} />
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        Other
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.initialStage.other'
                        disabled={formik.values.questions?.initialStage?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Have any complications ever manifested?
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.complications.value'
                        disabled={formik.values.questions?.complications.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='complications' />
                </Grid>

                <DependentInput condition={formik.values.questions.complications.value == 'Yes'}>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography variant='body2'>
                            If yes, please specify:
                        </Typography>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={1} />
                    <Grid item xs={9}>
                        <MyRadioGroup
                            name='questions.complications.details'
                            disabled={formik.values.questions?.complications.referToAttachments}
                            options={sarcoidosisComplicationsOptions}
                        />
                    </Grid>
                    <Grid item xs={2} />

                    <DependentInput condition={formik.values.questions.complications.details == 'Other'}>
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <Typography variant='body2'>
                                If other, please give details:
                            </Typography>
                        </Grid>
                        <Grid item xs={1} />

                        <Grid item xs={1} />
                        <Grid item xs={9}>
                            <MyTextField
                                name='questions.complications.other'
                                disabled={formik.values.questions?.complications?.referToAttachments}
                            />
                        </Grid>
                    </DependentInput>
                </DependentInput>
            </Grid>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Based on the latest specialist review, are the lesions:
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='lesions' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <MyRadioGroup
                        name='questions.lesions.value'
                        disabled={formik.values.questions?.lesions.referToAttachments}
                        options={sarcoidosisLesionsOptions}
                    />
                </Grid>
                <Grid item xs={1} />
            </Grid>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Does the current treatment regimen include any of the following:
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='treatment' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Corticosteroids
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.treatment.corticosteroids'
                        disabled={formik.values.questions?.treatment.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Anti-TNFs, DMARDs or any other second line agents
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.treatment.secondLineAgents'
                        disabled={formik.values.questions?.treatment.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <DependentInput condition={formik.values.questions.treatment.secondLineAgents == 'Yes'}>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Typography variant='body2'>
                            If yes, please confirm details:
                        </Typography>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={1} />
                    <Grid item xs={9}>
                        <MyTextField
                            name='questions.treatment.other'
                            disabled={formik.values.questions?.treatment?.referToAttachments}
                        />
                    </Grid>
                </DependentInput>
            </Grid>

        </Grid>
    )
}