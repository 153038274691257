import useArrayUtils from '../../../../../../hooks/useArrayUtils'

function Medications({ data }) {
    const { isArrayEmpty } = useArrayUtils()

    const currentMeds = data.medications.currentMedications

    return (
        <>
            <h3>Medications</h3>
            <h5>Current Medications</h5>
            {isArrayEmpty(currentMeds) ? (
                <p>No information available</p>
            ) : currentMeds.map((med, idx) => (
                <p key={idx}>{med.value}</p>
            ))}
        </>
    )
}

export default Medications