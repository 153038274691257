import { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AttachmentIcon from '@mui/icons-material/Attachment'

import useBrowserFunctions from '../../../../../../hooks/useBrowserFunctions'
import { attachmentFilesAtom } from '../state'
import { errorAtom } from '../../../../../../state/errorState'
import AttachmentViewer from './AttachmentViewer'

export default function FileAttachmentField() {
    const { requestId } = useParams()
    const setErrorState = useSetRecoilState(errorAtom)
    const [files, setFiles] = useRecoilState(attachmentFilesAtom)

    const [previewFile, setPreviewFile] = useState({
        show: false,
        content: null
    })

    const browserFunctions = useBrowserFunctions()

    function fetchFiles() {
        axios.get(`/requests/${requestId}/attachments`)
            .then(res => res.data)
            .then(data => setFiles(data))
            .catch(error => {
                setErrorState({
                    header: 'Failed to retrieve attachments.',
                    message: error.response.data.error.message
                })
            })
    }

    function handleAttach() {
        // Invoke the file attach handler in the PMS client
        browserFunctions.invokeAttachFiles()

        // Refetch files afterwards
        fetchFiles()
    }

    function handleView(file) {
        setPreviewFile({
            show: true,
            name: file.name,
            content: file.content
        })
    }

    function handleRemove(fileName) {
        // Call api to remove file & update files with response
        axios.delete(`/requests/${requestId}/attachments/${fileName}`)
            .then(res => res.data)
            .then(data => setFiles(data))
            .catch(error => {
                setErrorState({
                    header: 'Failed to delete attachment.',
                    message: error.response.data.error.message
                })
            })
    }

    const caption = `${files.length} ${files.length === 1 ? 'file' : 'files'} uploaded`


    return (
        <>
            <Grid item xs={12}>
                <Grid item container direction='row' justifyContent='space-between' alignItems='baseline' sx={{ px: 2 }}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        File Attachments
                    </Typography>

                    <Button
                        type='button'
                        variant="contained"
                        size='small'
                        onClick={handleAttach}
                        sx={{ mb: 2 }}
                        endIcon={<AttachFileIcon />}
                    >
                        Attach File
                    </Button>
                </Grid>

                <List dense>
                    {files.map((file) => (
                        <div key={file.name}>
                            <ListItem
                                secondaryAction={
                                    <Stack direction="row" spacing={1}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => handleView(file)}
                                        >
                                            View
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => handleRemove(file.name)}
                                        >
                                            Remove
                                        </Button>
                                    </Stack>
                                }
                            >
                                <ListItemIcon>
                                    <AttachmentIcon />
                                </ListItemIcon>
                                <ListItemText primary={file.name} />
                            </ListItem>
                            <Divider />
                        </div>
                    ))}
                    <ListSubheader>{caption}</ListSubheader>
                </List>
            </Grid>

            <AttachmentViewer
                show={previewFile.show}
                fileName={previewFile.name}
                fileContent={previewFile.content}
                onClose={() => setPreviewFile({ show: false, content: null })}
            />
        </>
    )
}