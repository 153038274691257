import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'

import OutlinedCard from './OutlinedCard'
import OutputObxValue, { OutputObxDate } from '../../../fieldComponents/OutputObxValue';

export default function ClinicalExamSummary() {
    const observation = useFormikContext().values.mostRecentSummary.clinicalExamination

    return (
        <OutlinedCard header='Clinical Examination'>
            <Grid container spacing={1} justifyContent='space-around'>
                <Grid item container justifyContent='space-between'>
                    <Typography variant='body2'>
                        Systolic:
                    </Typography>
                    <Stack direction='row'>
                        <Typography variant='body2'>
                            <strong><OutputObxValue>{observation.systolicBloodPressure}</OutputObxValue></strong>
                        </Typography>
                        <Typography variant='caption'>
                            <OutputObxDate field={observation.systolicBloodPressure} />
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item container justifyContent='space-between'>
                    <Typography variant='body2'>
                        Diastolic:
                    </Typography>
                    <Stack direction='row'>
                        <Typography variant='body2'>
                            <strong><OutputObxValue>{observation.diastolicBloodPressure}</OutputObxValue></strong>
                        </Typography>
                        <Typography variant='caption'>
                            <OutputObxDate field={observation.diastolicBloodPressure} />
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item container justifyContent='space-between'>
                    <Typography variant='body2'>
                        Height:
                    </Typography>
                    <Stack direction='row'>
                        <Typography variant='body2'>
                            <strong><OutputObxValue>{observation.height}</OutputObxValue></strong>
                        </Typography>
                        <Typography variant='caption'>
                            <OutputObxDate field={observation.height} />
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item container justifyContent='space-between'>
                    <Typography variant='body2'>
                        Weight:
                    </Typography>
                    <Stack direction='row'>
                        <Typography variant='body2'>
                            <strong><OutputObxValue>{observation.weight}</OutputObxValue></strong>
                        </Typography>
                        <Typography variant='caption'>
                            <OutputObxDate field={observation.weight} />
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item container justifyContent='space-between'>
                    <Typography variant='body2'>
                        BMI:
                    </Typography>
                    <Stack direction='row'>
                        <Typography variant='body2'>
                            <strong><OutputObxValue>{observation.bmi}</OutputObxValue></strong>
                        </Typography>
                        <Typography variant='caption'>
                            <OutputObxDate field={observation.bmi} />
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </OutlinedCard>
    )
}