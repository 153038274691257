import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

export default function OutlinedCard(props) {
    return (
        <Box sx={{ height: '100%' }}>
            <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                    <Typography variant="h5" component="span">
                        {props.header}
                    </Typography>
                    {props.subHeader && (
                        <Typography variant="body2" component="div">
                            {props.subHeader}
                        </Typography>                        
                    )}
                    <Typography variant="body2" component='div' sx={{ mt: 1.5 }}>
                        {props.children}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
}