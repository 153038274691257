import { string, object, array } from 'yup'

import { yesNoValidOptions } from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object().shape({

        confirmExactDiagnosis: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        presentingSymptoms: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        dateOfFirstSymptoms: object({
            value: string()
                .when('referToAttachments', {
                    is: false,
                    then: string().ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate()
                })
        }),

        dateOfLastSymptoms: object({
            value: string()
                .when('referToAttachments', {
                    is: false,
                    then: string().ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate()
                })
        }),

        past5Years: object({
            subQuestions: object().when('referToAttachments', {
                is: false,
                then: object({
                    numOfEpisodes: object({
                        value: string()
                            .nullable()
                            .required('Please enter details')
                    }),
                    anyInpatientAdmissions: object({
                        value: string().required()
                            .typeError('Please select a value')
                            .required('Please select a value')
                            .oneOf(yesNoValidOptions),
                        subQuestions: object().when('value', {
                            is: (value) => value !== 'No',
                            then: object({
                                numOfInpatientAdmissions: object({
                                    value: string()
                                        .nullable()
                                        .required('Please enter details'),
                                }),
                                dateOfLastAdmissionFrom: object({
                                    value: string()
                                        .ensure()
                                        .required('Date is required')
                                        .isValidDate()
                                        .isPastDate()
                                }),
                                dateOfLastAdmissionTo: object({
                                    value: string()
                                        .ensure()
                                        .required('Date is required')
                                        .isValidDate()
                                        .isPastDate()
                                }),
                            })
                        })
                    }),
                    anyEpisodesOfSelfHarmEtc: object({
                        value: string().required()
                            .typeError('Please select a value')
                            .required('Please select a value')
                            .oneOf(yesNoValidOptions),
                        details: array().when('value', {
                            is: (value) => value !== 'No',
                            then: array().of(
                                object().shape({
                                    note: string().required('Note is a required field.'),
                                    date: string()
                                        .ensure()
                                        .required('Date is required')
                                        .isValidDate()
                                        .isPastDate()
                                })
                            )
                        })
                    }),
                })
            })
        }),

        anyRelatedAbsenceFromWork: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: array().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: array().of(
                    object({
                        note: string().required('Note is a required field.'),
                        date: string()
                            .ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate()
                    })
                ).min(1, 'Please enter details')
            })
        }),

        anyRelevantFeaturesInfluencingPrognosis: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: array().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: array().of(
                    object({
                        note: string().required('Note is a required field.'),
                        date: string()
                            .ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate()
                    })
                ).min(1, 'Please enter details')
            })
        }),

        commentOnCurrentMentalHealth: object({
            value: string().required('Please enter details')
        })
    }),
})

export default validation