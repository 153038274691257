import moment from 'moment'

import PatientDetails from './components/PatientDetails'
import DoctorDetails from './components/DoctorDetails'
import SocialHistory from './components/socialHistory/SocialHistory'
import GeneralHistory from './components/generalHistory/GeneralHistory'
import LaboratoryStudies from './components/laboratoryStudies/LaboratoryStudies'
import Medications from './components/Medications'
import Attachments from './components/Attachments'

function Pmar({ data }) {

    if (!data) return null

    return (
        <div>
            <h2>{data.request.formName}</h2>
            <p>Report generated: {moment().format('DD/MM/yyyy')}</p>
            <p>Policy No: {data.request.primaryReferenceNumber}</p>
            <hr />
            <PatientDetails data={data} />
            <hr />
            <DoctorDetails data={data} />
            <hr />
            <SocialHistory data={data} />
            <hr />
            <GeneralHistory data={data} />
            <hr />
            <LaboratoryStudies data={data} />
            <hr />
            <Medications data={data} />
            <hr />
            <Attachments data={data} />
        </div>
    )
}

export default Pmar
