import Questions from './Questions'
import Observations from './Observations'

function SocialHistory({ data }) {
    const socialHistory = data.socialHistory

    return (
        <>
            <h3>Social History</h3>

            <Observations
                observations={socialHistory.observations}
            />
            
            <Questions
                questions={socialHistory.questions}
            />
        </>
    )
}

export default SocialHistory
