import * as yup from 'yup'

const AttachmentsValidation = yup.object({

    anyOtherComments: yup.object({
        value: yup.string()
            .typeError('Please select a value')
            .required('Please select a value')
            .oneOf(['true', 'false'])
    }),

    comments: yup.object()
        .when('anyOtherComments.value', {
            is: 'true',
            then: yup.object({
                value: yup.string()
                    .nullable()
                    .required('Please enter a comment')
            })
        }),
})

export default AttachmentsValidation