// import { Address, ContactDetails, PersonName } from '../../../components'
import useStringUtils from '../../../../../hooks/useStringUtils'

function DoctorDetails({ data }) {
    const { formatAddress } = useStringUtils()

    const practice = data.request.practice
    const doctor = data.request.doctor
    const address = formatAddress(practice)

    return (
        <>
            <h3>Doctor Details</h3>
            <span>Dr. {doctor.fullName}</span>
            <span>Address: {address}</span>
            <span>Phone: {practice.phone}</span>
            <span>Email: {practice.email}</span>
            <span>MCRN: {doctor.medCouncilNo}</span>
        </>
    )

    // Alan Jennings - 18/07/2023
    // I had previously used details (doctor name, address, contact details) from the PMS system as we did not import the details supplied from the Insurer system.
    // I have made changes to import these from the Insurer system when fetching requests so now I am outputting the Insurer details.
    // Leaving the old way here commented just in case of any errors. Can be deleted later if no issues.

    //const practice = data.practice

    // return (
    //     <>
    //         <h3>Doctor Details</h3>
    //         <PersonName name={practice.doctorName} />
    //         <Address address={practice.address} />
    //         <ContactDetails {...practice.contactDetails} />
    //         <span>MCRN: {practice.doctorMCN}</span>
    //     </>
    // )
}

export default DoctorDetails