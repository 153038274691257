const questions = {
    natureOfCondition: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    diagnosis: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            dateofDiagnosis: '',
            dateOfLastExacerbation: '',
            constantOrRecurrent: '',
            lengthOfLastSymptoms: ''
        }
    },
    condition: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            severity: '',
            functionalCapacity: '',
            symptomsProgression: '',
            describeNYHAStage: ''
        }
    },    
    recentResults: {
        value: '',
        referToAttachments: false,
        details: [{ note: '', date: '' }]
    },
    inpatientTreatmentRequired: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    smoking: {
        value: '',
        referToAttachments: false,
        subQuestions: {
            isSmoker: '',
            cigarettesPerDay: '',
            isPreviousSmoker: '',
            previousSmokerDetails: '',
            dateCeasedSmoking: ''
        }
    },
    patientSurgery: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    anyAssociatedConditionsOrComplications: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    anyTimeOffWorkCaused: {
        value: '',
        referToAttachments: false,
        details: ''
    }
}

export default questions
