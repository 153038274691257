import { FieldArray, Field } from 'formik'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete'

import AddMedicationItem from './AddMedicationItem'
import IncludableTableRow from '../../../fieldComponents/table/IncludableTableRow';

export default function MedicationList({ binding, meds }) {
    if (!Array.isArray(meds) || !meds.length) {
        return (
            <TableRow>
                <TableCell colSpan={2}>No medications to show</TableCell>
            </TableRow>
        )
    } else {
        return (
            <FieldArray
                name={binding}
                render={arrayHelpers => (
                    <>
                        {meds.map((medication, index) => (
                            <IncludableTableRow
                                key={index}
                                isIncluded={medication.include}
                            >
                                <TableCell scope="row">
                                    {medication.value}
                                </TableCell>
                                <TableCell align="center" padding="checkbox">
                                    {medication.addedOnPortal ? (
                                        <IconButton size="small" color="error" onClick={() => arrayHelpers.remove(index)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    ) : (
                                        <Field
                                            type="checkbox"
                                            name={`${binding}[${index}].include`}
                                        />
                                    )}
                                </TableCell>
                            </IncludableTableRow>
                        ))}
                        <TableRow>
                            <AddMedicationItem onAdd={medication => arrayHelpers.push(medication)} />
                        </TableRow>
                    </>
                )}
            />
        )
    }
}
