import Component from './Hepatitis'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./hepatitis.css');
var css = cssModule.default;

const hepatitisConfig = {
    component: Component,
    css: css
}

export default hepatitisConfig