const questions = {
    cvaType: {
        value: '',
        referToAttachments: false
    },
    dateOfDiagnosis: {
        value: '',
        referToAttachments: false
    },
    moreThenOneEvent: {
        value: '',
        referToAttachments: false,
        details: [{ note: '', date: '' }]
    },
    furtherRelevantDetails: {
        value: '',
        referToAttachments: false
    },
    patientsPresentingSymptoms: {
        value: '',
        referToAttachments: false
    },
    didSymptomsCompletelyResolve: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    mostRecentMriOrCtScanNotes: {
        value: '',
        referToAttachments: false
    },
    otherRiskFactors: {
        value: '',
        referToAttachments: false,
        details: ''
    }
}

export default questions
