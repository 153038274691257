import useArrayUtils from '../../../../../hooks/useArrayUtils'

export default function MissingInformationDetails({ data }) {
    const { isArrayEmpty } = useArrayUtils()
    const isAttachments = !isArrayEmpty(data.attachmentFiles)

    return (
        <>
            <h3>Missing Information Details</h3>

            <p><b>Underwriters note:</b></p>
            <p>{data.request.insurerNotes}</p>

            <p><b>Please enter your reply here:</b></p>
            <p>{data.missingInformation.comments.value}</p>

            <hr />

            <h3>File Attachments</h3>
            <>
                <h5>Attachment list:</h5>
                {isAttachments && (
                    <ol>
                        {data.attachmentFiles.map(a => <li key={a}>{a}</li>)}
                    </ol>
                )}
                {!isAttachments && (
                    <p>No files have been attached for this report</p>
                )}
            </>
        </>
    )
}
