import { useRecoilValue } from 'recoil'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import moment from 'moment'

import { requestAtom } from '../../requestState'

export default function RequestInfoBar() {
    // Get request context to access request data
    const request = useRecoilValue(requestAtom)

    return (
        <Paper elevation={0} sx={{ px: 2, py: 1 }} square>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='center'
                spacing={4}
                sx={{ mb: 2 }}
            >
                <Typography variant="body2" color="text.secondary">
                    Patient:
                </Typography>
                <Typography variant="body1">
                    <b>{request.patient.name}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Date of Birth:
                </Typography>
                <Typography variant="body1">
                    <b>{moment(request.patient.dateOfBirth).format('DD/MM/yyyy')}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Gender:
                </Typography>
                <Typography variant="body1">
                    <b>{request.patient.gender}</b>
                </Typography>
            </Stack>
        </Paper>
    )
}
