import useApi from '../api/useApi'
import healthOneCallbacks from '../api/healthOneCallbacks'

export default () => {
    const saveForLater = useApi(healthOneCallbacks.saveForLater)
    const complete = useApi(healthOneCallbacks.complete)

    const invokeSaveForLater = async (requestId) => {
        await saveForLater.request(requestId)
    }

    const invokeComplete = async (requestId) => {
        await complete.request(requestId)
    }

    return {
        invokeSaveForLater,
        invokeComplete
    }
}