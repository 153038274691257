import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogTitle from '@mui/material/DialogTitle'

const styles = {
    iframe: {
        display: 'block',
        background: '#000',
        border: 'none',
        height: '100%',
        width: '100%'
    }
}

export default function ClientAuthorizationComponent({ open, document, onClose }) {

    if (document === null)
    {
        // If no document to show, return null
        return null
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll='paper'
            fullWidth
            fullScreen
        >
            {/* <DialogTitle>
                Patient Authorization
            </DialogTitle> */}
            <DialogContent dividers={true}>
                <iframe
                    src={`data:${document.contentType};base64,${document.content}`}
                    style={styles.iframe}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}