import Component from './Tumour'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./tumour.css');
var css = cssModule.default;

const tumourConfig = {
    component: Component,
    css: css
}

export default tumourConfig