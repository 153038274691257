import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function TumourDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Tumour Specific Questions</h3>

            <QuestionsContainer>
                
                <QuestionWrapper number='1'>
                    <Question>Was it localised to the tissue or organ of origin?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.localisedToOrigin.referToAttachments}>
                        <p>{questions.localisedToOrigin.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='2'>
                    <Question>Exact Staging/histology:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.exactStaging.referToAttachments}>
                        <p>{questions.exactStaging.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Size of tumour:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.sizeOfTumour.referToAttachments}>
                        <p>{questions.sizeOfTumour.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='4'>
                    <Question>Were any lymph nodes involved?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyLymphNodesInvolved.referToAttachments}>
                        <p>{questions.anyLymphNodesInvolved.value}</p>
                        {questions.anyLymphNodesInvolved.value === 'Yes' && (
                            <>
                                <Question>If yes, confirm how many:</Question>
                                <p>{questions.anyLymphNodesInvolved.numLymphNodes}</p>
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='5'>
                    <Question>Were there any metastases?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.anyMetastases.referToAttachments}>
                        <p>{questions.anyMetastases.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='6'>
                    <Question>Details of Treatment:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.detailsOfTreatment.referToAttachments}>
                        <p>{questions.detailsOfTreatment.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='7'>
                    <Question>Date treatment finished:</Question>
                    <ReferrableQuestionWrapper isReferred={questions.dateTreatmentFinished.referToAttachments}>
                        <p>{formatDateMonthYear(questions.dateTreatmentFinished.value)}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='8'>
                    <Question>Completely eradicated/no recurrence</Question>
                    <ReferrableQuestionWrapper isReferred={questions.completelyEradicated.referToAttachments}>
                        <p>{questions.completelyEradicated.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}