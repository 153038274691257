import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import OutputDetails from '../components/output/OutputDetails'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function StrokeDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Stroke Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>Please confirm the exact type of CVA that your patient has suffered from?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.cvaType.referToAttachments}>
                        <p>{questions.cvaType.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='2'>
                    <Question>Date of diagnosis?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.dateOfDiagnosis.referToAttachments}>
                        <p>{formatDateMonthYear(questions.dateOfDiagnosis.value)}</p>
                    </ReferrableQuestionWrapper>

                    <Question>Was there more than one event?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.moreThenOneEvent.referToAttachments}>
                        <p>{questions.moreThenOneEvent.value}</p>
                        {questions.moreThenOneEvent.value === 'Yes' && (
                            <>
                                <Question>If yes, please provide details and dates:</Question>
                                <OutputDetails
                                    details={questions.moreThenOneEvent.details}
                                />
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Please provide any further relevant details in relation to the cause of the CVA?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.furtherRelevantDetails.referToAttachments}>
                        <p>{questions.furtherRelevantDetails.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='4'>
                    <Question>
                        Please describe your patients presenting symptoms?
                    </Question>
                    <ReferrableQuestionWrapper isReferred={questions.patientsPresentingSymptoms.referToAttachments}>
                        <p>{questions.patientsPresentingSymptoms.value}</p>
                    </ReferrableQuestionWrapper>

                    <Question>Did symptoms completely resolve?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.patientsPresentingSymptoms.referToAttachments}>
                        <p>{questions.didSymptomsCompletelyResolve.value}</p>

                        <Question
                            show={questions.didSymptomsCompletelyResolve.value === 'No'}
                            answer={questions.didSymptomsCompletelyResolve.details}
                        >
                            If no, please describe the residual symptoms and the resulting degree of disability:
                        </Question>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='5'>
                    <Question>
                        Please confirm the date & results of most recent MRI or CT scans?
                    </Question>
                    <ReferrableQuestionWrapper isReferred={questions.mostRecentMriOrCtScanNotes.referToAttachments}>
                        <p>{questions.mostRecentMriOrCtScanNotes.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='6'>
                    <Question>Does your patient have any other circulatory or vascular risk factors that you are aware of?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.otherRiskFactors.referToAttachments}>
                        <p>{questions.otherRiskFactors.value}</p>

                        {questions.otherRiskFactors.value === 'Yes' && (
                            <>
                                <Question>
                                    If yes, please confirm details
                                </Question>
                                <p>{questions.otherRiskFactors.details}</p>
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}