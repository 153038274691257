import TableRow from '@mui/material/TableRow'

import { DisabledTableRow, EditingTableRow } from '../../styledComponents/StyledTableRow'


export default function IncludableTableRow({ isIncluded, isEditing, children, props }) {

    if (isIncluded === false) {
        return (
            <DisabledTableRow {...props}>
                {children}
            </DisabledTableRow>
        )
    } else if (isEditing === true) {
        return (
            <EditingTableRow {...props}>
                {children}
            </EditingTableRow>
        )
    } else {
        return (
            <TableRow {...props}>
                {children}
            </TableRow>
        )
    }
}