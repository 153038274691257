import { Field, FieldArray, useFormikContext } from 'formik'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

import useArrayUtils from '../../../../../../hooks/useArrayUtils'
import OutlinedCard from './OutlinedCard'
import AddMedicationItem from '../../../formComponents/medications/component/AddMedicationItem'
import IncludableTableRow from '../../../fieldComponents/table/IncludableTableRow'

export default function MedicationsSummary() {
    const { isArrayEmpty } = useArrayUtils()

    const meds = useFormikContext().values.medications.currentMedications

    let content = null

    if (isArrayEmpty(meds)) {
        content = (
            <TableRow>
                <TableCell colSpan={2}>No medications to show</TableCell>
            </TableRow>
        )
    } else {
        content = (
            <FieldArray
                name='medications.currentMedications'
                render={arrayHelpers => (
                    <>
                        {meds.map((medication, index) => (
                            <IncludableTableRow
                                key={index}
                                isIncluded={medication.include}
                            >
                                <TableCell scope="row">
                                    {medication.value}
                                </TableCell>
                                <TableCell align="center" padding="checkbox">
                                    {medication.addedOnPortal ? (
                                        <IconButton size="small" color="error" onClick={() => arrayHelpers.remove(index)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    ) : (
                                        <Field
                                            type="checkbox"
                                            name={`medications.currentMedications[${index}].include`}
                                        />
                                    )}
                                </TableCell>
                            </IncludableTableRow>
                        ))}
                        <TableRow>
                            <AddMedicationItem onAdd={medication => arrayHelpers.push(medication)} />
                        </TableRow>
                    </>
                )}
            />
        )
    }

    return (
        <OutlinedCard header='Current Medications' subHeader='Please only select medications related to this condition'>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Medication</TableCell>
                        <TableCell align="center" padding="checkbox">Include</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {content}
                </TableBody>
            </Table>
        </OutlinedCard>
    )
}