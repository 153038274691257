import useStringUtils from '../../../../hooks/useStringUtils'

export default function Address({ address }) {
    const { formatAddressFromHL7 } = useStringUtils()

    const fullAddress = formatAddressFromHL7(address)

    return (
        <span>
            Address: {fullAddress}
        </span>
    )
}