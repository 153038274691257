import Component from './Sarcoidosis'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./sarcoidosis.css');
var css = cssModule.default;

const sarcoidosisConfig = {
    component: Component,
    css: css
}

export default sarcoidosisConfig