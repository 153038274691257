import moment from 'moment'

export default function RenderField({ label, field }) {
    let content = null

    if (field === null) {
        // Return message stating field has no value
        content = (
            <td colSpan={2}>No record</td>
        )
    } else {
        const date = moment(field.observationDateTime).format('DD/MM/yyyy')

        if (field.units?.text) {
            // Display value including units
            content = (
                <>
                    <td>{field.value} {field.units.text}</td>
                    <td>{date}</td>
                </>
            )
        } else {
            // Display value without units
            content = (
                <>
                    <td>{field.value}</td>
                    <td>{date}</td>
                </>
            )
        }
    }

    return (
        <>
            <td>{label}</td>
            {content}
        </>
    )
}