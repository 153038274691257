import { useState, useEffect } from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import Stack from '@mui/material/Stack'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'

import IncludableTableRow from '../../../../../fieldComponents/table/IncludableTableRow'
import { splitDateAndNote, combineDateAndNote } from '../../generalHistoryUtils'


export default function ExistingItem({ item, binding, onChange }) {

    const [isEditing, setIsEditing] = useState(false)
    const [initialValues, setInitialValues] = useState({
        date: null,
        note: null
    })

    useEffect(() => {
        const { date, note } = splitDateAndNote(item.value)

        setInitialValues({
            date: date,
            note: note
        })
    }, [item])

    const validationSchema = Yup.object({
        date: Yup.string()
            .ensure()
            .required('Date is required')
            .test('isValidDate', 'Date must be in format DD/MM/YYYY', (val) => {
                if (!val) {
                    return false
                }

                return moment(val, 'DD/MM/yyyy', true).isValid()
            }),
        note: Yup.string().required('Note is required')
    })

    function moveCaretToEnd(e) {
        // Move caret to end of text when input gets focus (appears at start by default)
        var tempValue = e.target.value
        e.target.value = ''
        e.target.value = tempValue
    }

    function onSubmit(values, { resetForm }) {
        // Make a deep copy of item
        const updatedItem = JSON.parse(JSON.stringify(item))

        // Update item value
        updatedItem.value = combineDateAndNote(values.date, values.note)
        onChange(updatedItem)

        // Reset form values
        resetForm(initialValues)

        // Set editing flag to false
        setIsEditing(false)
    }

    return (
        <IncludableTableRow isIncluded={item.include} isEditing={isEditing}>
            {/*
                Formik instance for validation when editing the row item.
                This is nested within the parent formik instance.
            */}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {(formik) => {
                    if (isEditing) {
                        return (
                            <>
                                <TableCell>
                                    {formik.values.date}
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        multiline
                                        fullWidth
                                        name='note'
                                        onFocus={moveCaretToEnd}
                                        value={formik.values.note}
                                        onChange={formik.handleChange}
                                        error={formik.errors.note && formik.touched.note}
                                        helperText={formik.touched.note && formik.errors.note}
                                    />
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <Stack direction="row">
                                        <IconButton
                                            color='success'
                                            onClick={formik.handleSubmit}
                                        >
                                            <DoneIcon />
                                        </IconButton>
                                        <IconButton
                                            color='error'
                                            onClick={() => {
                                                // Reset form values
                                                formik.resetForm()
                                                // Leave edit mode
                                                setIsEditing(false)
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Stack>
                                </TableCell>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <TableCell>
                                    {formik.values.date}
                                </TableCell>
                                <TableCell>
                                    {formik.values.note}
                                </TableCell>
                                <TableCell align="center" padding="checkbox">
                                    <IconButton
                                        disabled={item.include === false}
                                        color='primary'
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </>
                        )
                    }
                }}
            </Formik>

            {!isEditing && (
                <TableCell align="center" padding="checkbox">
                    {/*
                        Checkbox to allow including/excluding the note.
                        This binds to the parent formik instance.
                        Hide then when in edit mode
                    */}
                    <Field
                        type="checkbox"
                        name={`${binding}.include`}
                    />
                </TableCell>
            )}
        </IncludableTableRow>
    )
}