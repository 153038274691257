import { useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export default function FormikErrorNotifier({ isSubmitting, isValid }) {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (isSubmitting && isValid === false) {
            setOpen(true)
        }
    }, [isSubmitting, isValid])

    function handleClose(e, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false)
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert elevation={6} variant="filled" severity="error">
                Some fields are invalid, please check for errors before submitting again.

                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                    sx={{ ml: 1 }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Alert>
        </Snackbar>
    )
}
