import { useState, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { useReactToPrint } from 'react-to-print'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import PrintIcon from '@mui/icons-material/PrintRounded'
import Typography from '@mui/material/Typography'
import moment from 'moment'

import IrishLifeTheme from './IrishLifeTheme'
import Logo from './Logo'
import { iconCalculator, iconDocument, iconQuestions, imgSignature } from './images'
import ClientAuth from './ClientAuthorizationComponent'

import { requestAtom, patientAtom } from '../../../requestState'


const IntroductionComponent = ({ openingContent, feeContent, contactContent }) => {
    const [showAuthorization, setShowAuthorization] = useState(false)

    const request = useRecoilValue(requestAtom)
    const patient = useRecoilValue(patientAtom)

    const pageStyle = `
        @page {
            margin: 20mm 20mm;
        }`;

    // Create ref needed to handle the printing of the component
    const ref = useRef()

    // Pass ref into print hook
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        pageStyle: pageStyle,
        documentTitle: 'Patient Authorization'
    })

    const openAuthorization = () => setShowAuthorization(true)
    const closeAuthorization = () => setShowAuthorization(false)

    const ViewClientAuthButton = (props) => {
        if (!props.show) {
            return null
        }

        return (
            <Button onClick={props.onClick} fullWidth sx={{ my: 3 }}>
                <Grid container alignItems='center'>
                    <Grid item xs={2} align='center'>
                        <img src={iconDocument} alt='authorization' height='38px' />
                    </Grid>
                    <Grid item xs={10} >
                        <Typography variant="body2" textAlign='left' sx={{ fontWeight: 'bold' }}>
                            Click here to view the signed patient authorisation
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
        )
    }

    return (
        <IrishLifeTheme>
            <IconButton
                aria-label="delete"
                onClick={handlePrint}
                color='primary'
                align='right'
                style={{ float: 'right' }}
            >
                <PrintIcon />
            </IconButton>

            <ClientAuth
                open={showAuthorization}
                document={request.clientAuthDoc}
                onClose={() => closeAuthorization()}
            />

            <div ref={ref}>
                <Logo encodedImg={request.logo} />

                <Box sx={{ width: '100%', my: 2 }}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Stack>
                            <Typography variant="body2Bold" gutterBottom>
                                Life Proposed:
                            </Typography>
                            <Typography variant="body2">
                                {request.patient.name}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography variant="body2Bold" gutterBottom>
                                Customer's address:
                            </Typography>
                            <Typography variant="body2">
                                {request.patient.address1}
                            </Typography>
                            <Typography variant="body2">
                                {request.patient.address2}
                            </Typography>
                            <Typography variant="body2">
                                {request.patient.address3}
                            </Typography>
                            <Typography variant="body2">
                                {request.patient.address4}
                            </Typography>
                            <Typography variant="body2">
                                {request.patient.eircode}
                            </Typography>
                        </Stack>
                        <Stack
                            justifyContent="space-between"
                            sx={{ width: '120px', height: '110px' }}
                        >
                            <div>
                                <Typography variant="body2Bold" gutterBottom>
                                    Date of birth:
                                </Typography>
                                <Typography variant="body2">
                                    {moment(patient.dateOfBirth).format('DD/MM/yyyy')}
                                </Typography>
                            </div>

                            <div>
                                <Typography variant="body2Bold" gutterBottom>
                                    Plan number:
                                </Typography>
                                <Typography variant="body2">
                                    {request.primaryReferenceNumber}
                                </Typography>
                            </div>
                        </Stack>
                    </Stack>
                </Box>

                <Stack sx={{ mt: 3, mb: 3 }}>
                    <Typography variant="body2">
                        Dr. {request.doctor.firstName} {request.doctor.lastName}
                    </Typography>
                    <Typography variant="body2">
                        {request.practice.address1}
                    </Typography>
                    <Typography variant="body2">
                        {request.practice.address2}
                    </Typography>
                    <Typography variant="body2">
                        {request.practice.address3}
                    </Typography>
                    <Typography variant="body2">
                        {request.practice.address4}
                    </Typography>
                    <Typography variant="body2">
                        {request.practice.eircode}
                    </Typography>
                </Stack>

                <Typography variant="body2" gutterBottom sx={{ mb: 3 }}>
                    {moment(request.createdDateTime).format('DD/MM/yyyy')}
                </Typography>

                <Typography variant="body2Bold" gutterBottom>
                    Dear Dr. {request.doctor.lastName}
                </Typography>

                <Typography variant="body2" gutterBottom>
                    {openingContent}
                </Typography>

                <Box sx={{ backgroundColor: '#F1EFEC' }} mb={4} padding={3}>
                    <Typography variant="body2Bold">
                        This is a copy of the Irish Life Customer Authorisation your patient has agreed to:<br />
                        <br />
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                        We need your relevant personal information and personal health information for underwriting decisions. This will determine whether we can offer cover and on what terms. We also need your relevant personal information and personal health information to assess and pay claims.<br />
                        <br />
                        If relevant, we will share your personal health information with reinsurers and Chief Medical Officers for underwriting and claims decisions. We can use your personal information and personal health information for any subsequent applications to Irish Life.<br />
                        <br />
                        In addition to the personal health information we collect from you, we may request and receive your relevant personal health information from GPs, consultants, hospitals, or other health professionals, and share your relevant personal health information with GPs, consultants, hospitals, or other health professionals, if needed.<br />
                    </Typography>
                </Box>

                <Typography variant="body2" gutterBottom>
                    An insurance application cannot be processed without this customer authorisation being in place. We would welcome your completion of the report based on this authorisation.<br />

                    <ViewClientAuthButton
                        show={request.clientAuthDoc != null}
                        onClick={openAuthorization}
                    />

                    Under the provision of the Disability Bill 2005, you should not include results of any genetic tests undertaken by the patient, in this form. However, you can include if the patient is having treatment for or has experienced symptoms of a genetic condition.<br />
                    <br />
                </Typography>

                <Grid container alignItems='center' sx={{ my: 3 }}>
                    <Grid item xs={2} align='center'>
                        <img src={iconCalculator} alt='signature' height='45px' />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body2" gutterBottom>
                            {feeContent}
                        </Typography>
                    </Grid>

                    <Grid item xs={2} align='center'>
                        <img src={iconQuestions} alt='questions' height='45px' />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body2" gutterBottom>
                            {contactContent}
                        </Typography>
                    </Grid>
                </Grid>

                <img src={imgSignature} alt='signature' />
                <br />
                <Typography variant="body2" gutterBottom>
                    Martin Duffy<br />
                    Head of Underwriting & Protection Claims<br />
                    Irish Life Assurance<br />
                </Typography>
            </div>
        </IrishLifeTheme>
    )
}

export default IntroductionComponent
