import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import DetailsControl from '../../formComponents/conditionSpecific/details/DetailsControl'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'

import { DependentInput } from '../../fieldComponents'
import { yesNoOptions } from '../../../../../constants'

export default function HeartDiseaseQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please confirm angiogram result?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='angiogramResult' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid container item xs={10} rowSpacing={1}>
                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            a.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            Date of
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <DateField
                            name='questions.angiogramResult.subQuestions.dateOf'
                            disabled={formik.values.questions?.angiogramResult?.referToAttachments}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            b.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            Blood vessels occluded
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MyTextField
                            name='questions.angiogramResult.subQuestions.bloodVesselsOccluded'
                            disabled={formik.values.questions?.angiogramResult?.referToAttachments}
                        />
                    </Grid>

                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            c.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            % of blockage in each vessel
                        </Typography>
                    </Grid>
                    <Grid item container xs={6} spacing={1}>
                        <Grid item xs>
                            <MyTextField
                                label='LMS %'
                                name='questions.angiogramResult.subQuestions.percentageBlockageInVessels.lms'
                                disabled={formik.values.questions?.angiogramResult?.referToAttachments}
                            />
                        </Grid>
                        <Grid item xs>
                            <MyTextField
                                label='LAD %'
                                name='questions.angiogramResult.subQuestions.percentageBlockageInVessels.lad'
                                disabled={formik.values.questions?.angiogramResult?.referToAttachments}
                            />
                        </Grid>
                        <Grid item xs>
                            <MyTextField
                                label='RCA %'
                                name='questions.angiogramResult.subQuestions.percentageBlockageInVessels.rca'
                                disabled={formik.values.questions?.angiogramResult?.referToAttachments}
                            />
                        </Grid>
                        <Grid item xs>
                            <MyTextField
                                label='CX %'
                                name='questions.angiogramResult.subQuestions.percentageBlockageInVessels.cx'
                                disabled={formik.values.questions?.angiogramResult?.referToAttachments}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        How was this medically managed?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='medicallyManaged' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid container item xs={10} rowSpacing={1}>
                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            a.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            Angioplasty
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MyRadioGroup
                            name='questions.medicallyManaged.subQuestions.angioplasty'
                            disabled={formik.values.questions?.medicallyManaged.referToAttachments}
                            options={yesNoOptions}
                        />
                    </Grid>

                    <DependentInput condition={formik.values.questions.medicallyManaged.subQuestions.angioplasty == 'Yes'}>
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <Typography variant='body2'>
                                If yes, please confirm date and number of vessels treated
                            </Typography>
                        </Grid>
                        <Grid item xs={1} />

                        <Grid item xs={1} />
                        <Grid item xs={5}>
                            <DateField
                                label='Date'
                                name='questions.medicallyManaged.subQuestions.angioplastyDetails.date'
                                disabled={formik.values.questions?.medicallyManaged?.referToAttachments}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MyTextField
                                label='Number of vessels treated'
                                name='questions.medicallyManaged.subQuestions.angioplastyDetails.numberOfVessels'
                                disabled={formik.values.questions?.medicallyManaged?.referToAttachments}
                            />
                        </Grid>
                        <Grid item xs={1} />
                    </DependentInput>

                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            b.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            Bypass
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MyRadioGroup
                            name='questions.medicallyManaged.subQuestions.bypass'
                            disabled={formik.values.questions?.medicallyManaged.referToAttachments}
                            options={yesNoOptions}
                        />
                    </Grid>

                    <DependentInput condition={formik.values.questions.medicallyManaged.subQuestions.bypass == 'Yes'}>
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <Typography variant='body2'>
                                If yes, please confirm date and number of vessels treated
                            </Typography>
                        </Grid>
                        <Grid item xs={1} />

                        <Grid item xs={1} />
                        <Grid item xs={5}>
                            <DateField
                                label='Date'
                                name='questions.medicallyManaged.subQuestions.bypassDetails.date'
                                disabled={formik.values.questions?.medicallyManaged?.referToAttachments}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MyTextField
                                label='Number of vessels treated'
                                name='questions.medicallyManaged.subQuestions.bypassDetails.numberOfVessels'
                                disabled={formik.values.questions?.medicallyManaged?.referToAttachments}
                            />
                        </Grid>
                        <Grid item xs={1} />
                    </DependentInput>

                    <Grid item xs={1}>
                        <Typography variant='body2'>
                            c.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body2'>
                            Medically treated
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MyRadioGroup
                            name='questions.medicallyManaged.subQuestions.medicallyTreated'
                            disabled={formik.values.questions?.medicallyManaged.referToAttachments}
                            options={yesNoOptions}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Are there any other relevant cardiac risk factors such as smoking, BMI or other cardiac or vascular disease?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyOtherRiskFactors' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <MyRadioGroup
                        name='questions.anyOtherRiskFactors.value'
                        disabled={formik.values.questions?.anyOtherRiskFactors?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <DependentInput condition={formik.values.questions.anyOtherRiskFactors.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please provide details and dates
                        </Typography>

                        <DetailsControl
                            name='questions.anyOtherRiskFactors.details'
                            disabled={formik.values.questions?.anyOtherRiskFactors?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please comment on your patients current cardiac health?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='commentOnCurrentCardiacHealth' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.commentOnCurrentCardiacHealth.value'
                        disabled={formik.values.questions?.commentOnCurrentCardiacHealth?.referToAttachments}
                    />
                </Grid>
            </Grid>

        </Grid>
    )
}