import { atom, selector } from "recoil"
import moment from 'moment'

const laboratoryStudiesAtom = atom({
    key: 'laboratoryStudies',
    default: {}
})


export const labSearchTermAtom = atom({
    key: 'labSearchTerm',
    default: ''
})

export const labsSearchSelector = selector({
    key: 'labsSearch',
    get: ({ get }) => {
        const labs = get(laboratoryStudiesAtom);
        const searchTerm = get(labSearchTermAtom)

        let combinedLabItems = []

        // Combine results for all lab items into single array
        for (let i = 0; i < labs.length; i++) {
            combinedLabItems = combinedLabItems.concat(labs[i].results)
        }

        // Filter array if search term is specified
        if (searchTerm) {
            combinedLabItems = combinedLabItems.filter(i => i.identifier.text.toLowerCase().includes(searchTerm.toLowerCase()))
        }

        // Shape results
        combinedLabItems = combinedLabItems.map(
            ({ observationDateTime, identifier, value, units }) =>
            (
                {
                    identifier: identifier.text,
                    date: moment(observationDateTime),
                    value: value,
                    units: units.text
                }
            )
        )

        return combinedLabItems
    },
});

export default laboratoryStudiesAtom