import useArrayUtils from '../../../../../hooks/useArrayUtils'

function Attachments({ data }) {
    const { isArrayEmpty } = useArrayUtils()

    const isAnyOtherComments = data.attachments.anyOtherComments.value === 'true'
    const isAttachments = !isArrayEmpty(data.attachmentFiles)

    return (
        <>
            <h3>File Attachments</h3>
            <>
                <h5>Attachment list:</h5>
                {isAttachments && (
                    <ol>
                        {data.attachmentFiles.map(a => <li key={a}>{a}</li>)}
                    </ol>
                )}
                {!isAttachments && (
                    <p>No files have been attached for this report</p>
                )}
            </>

            <hr />
            
            <p><b>Any other comments?</b> {isAnyOtherComments ? 'Yes' : 'No'}</p>
            {isAnyOtherComments && (
                <>
                    <p><b>If yes please outline:</b></p>
                    <p style={{ whiteSpace: 'pre-line' }}>{data.attachments.comments.value}</p>
                </>
            )}
        </>
    )
}

export default Attachments