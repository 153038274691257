import useDateUtils from '../../../../../../../hooks/useDateUtils'

export default function Questions({ questions }) {
    const { formatDate } = useDateUtils()

    const isNicotineReplacementUse = questions.nicotineReplacementTherapy.value === 'true'
    const isDrugMisuse = questions.historyOfDrugsUse.value === 'true'

    const formatYesNoUnknown = (value) => {
        if (value === 'true') return 'Yes'
        else if (value === 'false') return 'No'
        else return 'Unknown'
    }

    const formatNullableDate = (label, value) => {
        if (value) {
            return <p>{label}: {formatDate(value)}</p>
        } else {
            return null
        }
    }

    return (
        <>
            <p>Any use of Nicotine replacement products? {formatYesNoUnknown(questions.nicotineReplacementTherapy.value)}</p>
            {isNicotineReplacementUse
                ? (
                    <>
                        <p>Most recently recorded: {formatDate(questions.nicotineReplacementMostRecentlyRecordedDate.value)}</p>
                    </>
                ) : ''
            }
            <p>Is there any indication of past or present misuse of alcohol or drugs? {formatYesNoUnknown(questions.historyOfDrugsUse.value)}</p>
            {isDrugMisuse
                ? (
                    <>
                        <p>If yes, please outline: {questions.historyOfDrugsUseNotes.value}</p>
                        {formatNullableDate('Alcohol start', questions.historyOfAlcoholUseStartDate.value)}
                        {formatNullableDate('Alcohol end', questions.historyOfAlcoholUseEndDate.value)}
                        {formatNullableDate('Drugs start', questions.historyOfDrugsUseStartDate.value)}
                        {formatNullableDate('Drugs end', questions.historyOfDrugsUseEndDate.value)}
                    </>
                ) : ''
            }
        </>
    )
}
