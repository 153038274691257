import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function MultipleSclerosisDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Multiple Sclerosis Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>Date of diagnosis?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.dateOfDiagnosis.referToAttachments}>
                        <p>{formatDateMonthYear(questions.dateOfDiagnosis.value)}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='2'>
                    <Question>Exact type of Multiple Sclerosis?</Question >
                    <ReferrableQuestionWrapper isReferred={questions.exactTypeOfMS.referToAttachments}>
                        <p>{questions.exactTypeOfMS.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Date of last attack/flare up?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.dateOfLastFlareUp.referToAttachments}>
                        <p>{formatDateMonthYear(questions.dateOfLastFlareUp.value)}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='4'>
                    <Question>Degree of disability?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.degreeOfDisability.referToAttachments}>
                        <p>{questions.degreeOfDisability.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='5'>
                    <Question>Please describe the impact of current symptoms on activities of daily living</Question>
                    <ReferrableQuestionWrapper isReferred={questions.impactOfCurrentSymtoms.referToAttachments}>
                        <p>{questions.impactOfCurrentSymtoms.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}