// Generic constants
export const FORM_CODES = Object.freeze({
    pmar: 'FrmPMAR',
    mir: 'MIR',
    heart: 'IEHeart',
    tumour: 'IETumour',
    mentalHealth: 'IEMentalHealth',
    stroke: 'IEStroke',
    multipleSclerosis: 'IEMS',
    sarcoidosis: 'IESarcoidosis',
    heartDisease: 'IEHeart',
    hepatitis: 'IEHepatitis',
    kidney: 'IEKidney',
    respiratory: 'IERespiratory',
    diabetes: 'IEDiabetes'
})

export const CONDITION_SPECIFIC_FORM_CODES = Object.freeze({
    heart: 'IEHeart',
    tumour: 'IETumour',
    mentalHealth: 'IEMentalHealth',
    stroke: 'IEStroke',
    multipleSclerosis: 'IEMS',
    sarcoidosis: 'IESarcoidosis',
    heartDisease: 'IEHeart',
    hepatitis: 'IEHepatitis',
    kidney: 'IEKidney',
    respiratory: 'IERespiratory',
    diabetes: 'IEDiabetes'
})

const appendReferToAttachmentsOption = (options) => [...options, 'Refer to attachments']

export const yesNoOptions = ['Yes', 'No']
export const yesNoValidOptions = appendReferToAttachmentsOption(yesNoOptions)

// Stroke form constants
export const cvaTypeOptions = ['Ischaemic stroke', 'Haemorrhagic stroke', 'Lacunar stroke', 'Embolic stroke', 'Transient ischaemic attack', 'Other']
export const cvaTypeValidOptions = appendReferToAttachmentsOption(cvaTypeOptions)

// Multiple Sclerosis form constants
export const msTypeOptions = ['Benign', 'Relapsing/remitting', 'Progressive']
export const msTypeValidOptions = appendReferToAttachmentsOption(msTypeOptions)

export const msDegreeOfDisabilityOptions = ['Mild', 'Moderate', 'Severe']
export const msDegreeOfDisabilityValidOptions = appendReferToAttachmentsOption(msDegreeOfDisabilityOptions)

// Sarcoidosis form constants
export const sarcoidosisInitialStageOptions = [
    'Stage 0, extrathoracic disease, without treatment',
    'Stage 1 (ATS Stage I), hilar lymphadenopathy, without treatment',
    'Stage 2A (ATS Stage II) or 2B (ATS Stage III), pulmonary infiltrates with or without hilar adenopathy, without treatment',
    'Stage 3 (ATS Stage IV)'
]
export const sarcoidosisInitialStageValidOptions = appendReferToAttachmentsOption(sarcoidosisInitialStageOptions)

export const sarcoidosisComplicationsOptions = ['Hypercalcaemia', 'Progressive disease in any organs other than the lungs', 'Other']
export const sarcoidosisComplicationsValidOptions = appendReferToAttachmentsOption(sarcoidosisComplicationsOptions)

export const sarcoidosisLesionsOptions = ['More pronounced', 'Unchanged', 'Regressing', 'Resolved']
export const sarcoidosisLesionsValidOptions = appendReferToAttachmentsOption(sarcoidosisLesionsOptions)

// Hepatitis form constants
export const hepatitisTypeOptions = ['A', 'B', 'C']
export const hepatitisTypeValidOptions = appendReferToAttachmentsOption(hepatitisTypeOptions)

// Respiratory Disorders form constants
export const respiratoryConditionOptions = ['Asthma', 'Emphysema', 'Chronic bronchitis', 'COPD']
export const respiratoryConditionValidOptions = appendReferToAttachmentsOption(respiratoryConditionOptions)

export const respiratoryConstantRecurrentOptions = ['Constant', 'Recurrent']
export const respiratoryConstantRecurrentValidOptions = appendReferToAttachmentsOption(respiratoryConstantRecurrentOptions)

export const respiratorySeverityOptions = ['Mild', 'Moderate', 'Severe']
export const respiratorySeverityValidOptions = appendReferToAttachmentsOption(respiratorySeverityOptions)

export const respiratorySymptomsOptions = ['Improving', 'The same', 'Getting worse']
export const respiratorySymptomsValidOptions = appendReferToAttachmentsOption(respiratorySymptomsOptions)

