import { useRecoilValue } from 'recoil'

import useModelUtils from './useModelUtils'
import { requestAtom, patientAtom, practiceAtom } from '../views/request/requestState'
import socialHistoryAtom from '../views/request/wizard/formComponents/socialHistory/state'
import generalHistoryAtom from '../views/request/wizard/formComponents/generalHistory/state'
import laboratoryStudiesAtom from '../views/request/wizard/formComponents/laboratoryStudies/state'
import medicationsAtom from '../views/request/wizard/formComponents/medications/state'
import attachmentsAtom, { attachmentFileNamesSelector } from '../views/request/wizard/formComponents/attachments/state'
import conditionSpecificAtom from '../views/request/wizard/formComponents/conditionSpecific/state'
import missingInformationAtom from '../views/request/wizard/formComponents/missingInformation/state'

export default () => {
    const modelUtils = useModelUtils()

    const requestState = useRecoilValue(requestAtom)
    const patientState = useRecoilValue(patientAtom)
    const practiceState = useRecoilValue(practiceAtom)
    const generalHistoryState = useRecoilValue(generalHistoryAtom)
    const socialHistoryState = useRecoilValue(socialHistoryAtom)
    const laboratoryStudiesState = useRecoilValue(laboratoryStudiesAtom)
    const medicationsState = useRecoilValue(medicationsAtom)
    const attachmentsState = useRecoilValue(attachmentsAtom)
    const attachmentFileNamesState = useRecoilValue(attachmentFileNamesSelector)
    const conditionSpecificState = useRecoilValue(conditionSpecificAtom)
    const missingInformationState = useRecoilValue(missingInformationAtom)

    const build = () => {
        const values = {
            request: {
                formName: requestState.form.name,
                primaryReferenceNumber: requestState.primaryReferenceNumber,
                practice: requestState.practice, // Practice details from insurer
                doctor: requestState.doctor,
                insurerNotes: requestState.insurerNotes
            },
            patient: patientState,
            practice: practiceState, // Practice details from PMS system
            generalHistory: generalHistoryState,
            socialHistory: socialHistoryState,
            laboratoryStudies: laboratoryStudiesState,
            medications: medicationsState,
            attachments: attachmentsState,
            attachmentFiles: attachmentFileNamesState,
            conditionSpecific: conditionSpecificState,
            missingInformation: missingInformationState
        }

        // Clone the values into a new object so we don't modify the formik model (JSON parse + stringify creates a deep copy of the object)
        // We need to keep items with 'include = false' in the model, so we can't do any filtering on the actual formik model
        let deepCopyValues = JSON.parse(JSON.stringify(values))

        // Filter out fields with include = false
        const updatedValues = modelUtils.filterExcludedItems(deepCopyValues)

        return updatedValues
    }

    return {
        build
    }
}