import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import SelectPaymentMethod from './paymentMethod/SelectPaymentMethod'
import { paymentDetailsAtom } from '../../../requestState'
import useReportGenerator from '../../../../../hooks/useReportGenerator'

export default function Submit() {
    var reportGenerator = useReportGenerator()

    useEffect(() => {
        // Ensure report PDF is generated (to avoid issue saving blank PDF's when report was 'Saved for Later' and reopened on this tab)
        reportGenerator.ensureReportGenerated()
    }, [])

    const { fee } = useRecoilValue(paymentDetailsAtom)

    const formatCurrency = (num) => `€${num.toFixed(2)}`

    return (
        <Grid container spacing={2} sx={{ m: 1, mb: 3 }}>
            <Grid item xs={4}>
                <Typography variant='body2'>
                    Select payee account:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <SelectPaymentMethod />
            </Grid>
            <Grid item xs={4}>
                <Typography variant='body2'>
                    Fee:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography variant='body2'>
                    {formatCurrency(fee.net)}
                </Typography>
            </Grid>
            {fee.includeVat && (
                <>
                    <Grid item xs={4}>
                        <Typography variant='body2'>
                            VAT @ {fee.vatRate}:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body2'>
                            {formatCurrency(fee.vatAmount)}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant='body2'>
                            Total:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant='body2'>
                            {formatCurrency(fee.gross)}
                        </Typography>
                    </Grid>
                </>
            )}

            <Grid item>
                <Typography variant='body2'>
                    Reports submitted before the 12th of each month will be paid on the 15th, reports submitted before the 25th of each month will be paid on the last day of the month.
                </Typography>
            </Grid>
        </Grid>
    )
}