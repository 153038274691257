import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { FastField, useFormikContext } from 'formik'

import { DependentInput, RadioGroupInput } from '../../fieldComponents'

const radioOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" }
]

export default function Questions() {
    const { values, touched, errors } = useFormikContext()

    return (
        <Grid container spacing={2} sx={{ m: 1, mb: 3 }}>
            <Grid item xs={6}>
                <Typography variant='body2'>
                    Are there any comments you would like to add to this report?
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <RadioGroupInput
                    name='anyOtherComments.value'
                    options={radioOptions}
                    error={errors?.anyOtherComments?.value && touched?.anyOtherComments?.value}
                    helperText={touched?.anyOtherComments?.value && errors?.anyOtherComments?.value}
                />
            </Grid>

            <DependentInput condition={values.anyOtherComments.value === 'true'}>
                <Grid item xs={12}>
                    <Typography variant='body2'>
                        If yes, please outline:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FastField
                        as={TextField}
                        size='small'
                        fullWidth
                        multiline
                        name='comments.value'
                        error={errors?.comments?.value && touched?.comments?.value}
                        helperText={touched?.comments?.value && errors?.comments?.value}
                    />
                </Grid>
            </DependentInput>
        </Grid>
    )
}