export default function ReferrableQuestionWrapper(props) {
    if (props.isReferred) {
        return <p>Refer to attachments</p>
    } else {
        return (
            <>
                {props.children}
            </>
        )
    }
}
