import { string, object, array } from 'yup'

import { yesNoValidOptions, cvaTypeValidOptions } from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object().shape({

        cvaType: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(cvaTypeValidOptions)
        }),

        dateOfDiagnosis: object({
            value: string()
                .when('referToAttachments', {
                    is: false,
                    then: string().ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate()
                })
        }),

        moreThenOneEvent: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: array().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: array().of(
                    object({
                        note: string().required('Note is a required field.'),
                        date: string()
                            .ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate()
                    })
                ).min(1, 'Please enter details')
            })
        }),

        furtherRelevantDetails: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        patientsPresentingSymptoms: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        didSymptomsCompletelyResolve: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value === 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        }),

        mostRecentMriOrCtScanNotes: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        otherRiskFactors: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value === 'Yes',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        })
    })
})

export default validation