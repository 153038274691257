import { string, object } from 'yup'

import {
    yesNoValidOptions,
    sarcoidosisInitialStageValidOptions,
    sarcoidosisComplicationsValidOptions,
    sarcoidosisLesionsValidOptions
} from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object().shape({

        initialStage: object({
            value: string()
                .oneOf(sarcoidosisInitialStageValidOptions),
            other: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && (value === '' || value == null),
                then: string()
                    .required('Please enter other details when no stage is selected')
            })
        }),

        complications: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value === 'Yes',
                then: string()
                    .required('Please select a value')
                    .oneOf(sarcoidosisComplicationsValidOptions)
            }),
            other: string().when(['referToAttachments', 'value', 'details'], {
                is: (referToAttachments, value, details) => referToAttachments === false && value === 'Yes' && details === 'Other',
                then: string().required('Please enter details')
            })
        }),

        lesions: object({
            value: string()
                .required('Please select a value')
                .oneOf(sarcoidosisLesionsValidOptions)
        }),

        treatment: object({
            corticosteroids: string().when('referToAttachments', {
                is: false,
                then: string()
                    .required('Please select a value')
                    .oneOf(yesNoValidOptions)
            }),
            secondLineAgents: string().when('referToAttachments', {
                is: false,
                then: string()
                    .required('Please select a value')
                    .oneOf(yesNoValidOptions)
            }),
            other: string().when(['referToAttachments', 'secondLineAgents'], {
                is: (referToAttachments, secondLineAgents) => referToAttachments === false && secondLineAgents === 'Yes',
                then: string().required('Please enter details')
            })
        })
    })
})

export default validation