import { useRecoilValue } from 'recoil'
import { requestAtom } from '../views/request/requestState'

export default () => {
    const request = useRecoilValue(requestAtom)
    const vatRate = 23

    const calculateRequestFee = (account) => {

        const fee = request.form.amount

        if (account?.includeVat) {
            // Calculate vat amount
            const vatAmount = (fee / 100) * vatRate
            const gross = fee + vatAmount

            return {
                includeVat: true,
                vatRate: `${vatRate}%`,
                net: fee,
                vatAmount: vatAmount,
                gross: gross
            }
        } else {
            return {
                includeVat: false,
                vatRate: '0%',
                net: fee,
                vatAmount: 0,
                gross: fee
            }
        }
    }

    return {
        calculateRequestFee
    }
}