import { string, object } from 'yup'

import { msTypeValidOptions, msDegreeOfDisabilityValidOptions } from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object().shape({

        dateOfDiagnosis: object({
            value: string()
                .when('referToAttachments', {
                    is: false,
                    then: string().ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate()
                })
        }),

        exactTypeOfMS: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(msTypeValidOptions)
        }),

        dateOfLastFlareUp: object({
            value: string()
                .when('referToAttachments', {
                    is: false,
                    then: string().ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate()
                })
        }),

        degreeOfDisability: object({
            value: string().required()
                .typeError('Please select a value')
                .required('Please select a value')
                .oneOf(msDegreeOfDisabilityValidOptions)
        }),

        impactOfCurrentSymtoms: object({
            value: string()
                .nullable()
                .required('Please enter details')
        })
    })
})

export default validation