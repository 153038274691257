const questions = {
    confirmExactDiagnosis: {
        value: '',
        referToAttachments: false
    },
    presentingSymptoms: {
        value: '',
        referToAttachments: false
    },
    dateOfFirstSymptoms: {
        value: '',
        referToAttachments: false
    },
    dateOfLastSymptoms: {
        value: '',
        referToAttachments: false
    },
    past5Years: {
        referToAttachments: false,
        subQuestions: {
            numOfEpisodes: {
                value: ''
            },
            anyInpatientAdmissions: {
                value: '',
                subQuestions: {
                    numOfInpatientAdmissions: {
                        value: ''
                    },
                    dateOfLastAdmissionFrom: {
                        value: ''
                    },
                    dateOfLastAdmissionTo: {
                        value: ''
                    }
                }
            },
            anyEpisodesOfSelfHarmEtc: {
                value: '',
                details: [{ note: '', date: '' }]
            }
        }
    },
    anyRelatedAbsenceFromWork: {
        value: '',
        referToAttachments: false,
        details: [{ note: '', date: '' }]
    },
    anyRelevantFeaturesInfluencingPrognosis: {
        value: '',
        referToAttachments: false,
        details: [{ note: '', date: '' }]
    },
    commentOnCurrentMentalHealth: {
        value: '',
        referToAttachments: false
    }
}

export default questions