import { useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Formik } from 'formik'
import Yup from '@raisin/yup-validations'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

import { DependentInput } from '../../../fieldComponents'
import { requestIdSelector } from '../../../../requestState'
import { pmsUserAtom } from '../../../../../../state/pmsState'
import { errorAtom } from '../../../../../../state/errorState'
import paymentMethodApi from '../../../../../../api/paymentMethods'

export default function AddPaymentMethod({ show, onAddComplete, onClose }) {
    const [loading, setLoading] = useState(false)

    const requestId = useRecoilValue(requestIdSelector)
    const pmsUser = useRecoilValue(pmsUserAtom)
    const setErrorState = useSetRecoilState(errorAtom)

    if (!show) {
        return null
    }

    const initialValues = {
        accountHolder: '',
        includeVat: false,
        vatNumber: '',
        iban: ''
    }

    const validationSchema = Yup.object({
        accountHolder: Yup.string()
            .required('Please enter the name of account holder'),
        includeVat: Yup.boolean(),
        vatNumber: Yup.string()
            .when('includeVat', {
                is: true,
                then: Yup.string()
                    .required('Please enter a VAT number')
                    .matches('[A-Za-z0-9]', "Must contain only digits and letters")
                    .min(8, 'Must be a minimum of 8 characters')
                    .max(11, 'Must be a maximum 11 characters')
            }),
        iban: Yup.string()
            .required("Please enter an IBAN")
            .iban("Invalid IBAN specified")
    })

    function onSubmit(values) {
        // Append mcn for logged in user
        const body = {
            ...values,
            requestId: requestId,
            medicalCouncilNo: pmsUser.mcn,
            firstName: pmsUser.firstName,
            lastName: pmsUser.lastName
        }

        setLoading(true)

        paymentMethodApi.postPaymentMethod(body)
            .then(r => onAddComplete(r.data.accountId))
            .catch(error => {
                console.error('Error creating payment account', error.response.data.error.message)
                setErrorState({
                    header: 'Failed to create payment account.',
                    message: error.response.data.error.message
                })
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {formik => {
                return (
                    <Dialog
                        open={true} 
                        fullWidth
                        maxWidth='xs'
                    >
                        <DialogTitle>Add new account</DialogTitle>
                        <DialogContent>
                            <Stack spacing={2} sx={{ m: 2, p: 2 }}>
                                <TextField
                                    label='Account Holder/Name'
                                    size='small'
                                    name='accountHolder'
                                    value={formik.values.accountHolder}
                                    onChange={formik.handleChange}
                                    error={formik.errors.accountHolder && formik.touched.accountHolder}
                                    helperText={formik.touched.accountHolder && formik.errors.accountHolder}
                                />

                                <TextField
                                    label='IBAN'
                                    size='small'
                                    name='iban'
                                    value={formik.values.iban}
                                    onChange={formik.handleChange}
                                    error={formik.errors.iban && formik.touched.iban}
                                    helperText={formik.touched.iban && formik.errors.iban}
                                />

                                <div>
                                    <FormControlLabel
                                        label="Include VAT"
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                size='small'
                                                checked={formik.values.includeVat}
                                                onChange={e => formik.setFieldValue('includeVat', e.target.checked)}
                                            />
                                        }
                                    />
                                </div>

                                <DependentInput condition={formik.values.includeVat == true}>
                                    <TextField
                                        label='Vat Number'
                                        size='small'
                                        name='vatNumber'
                                        value={formik.values.vatNumber}
                                        onChange={formik.handleChange}
                                        error={formik.errors.vatNumber && formik.touched.vatNumber}
                                        helperText={formik.touched.vatNumber && formik.errors.vatNumber}
                                    />
                                </DependentInput>
                            </Stack>

                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                color="success"
                                onClick={formik.handleSubmit}
                            >
                                Save
                            </LoadingButton>
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="error"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }}
        </Formik >
    )
}
