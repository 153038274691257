const questions = {
    localisedToOrigin: {
        value: '',
        referToAttachments: false
    },
    exactStaging: {
        value: '',
        referToAttachments: false
    },
    sizeOfTumour: {
        value: '',
        referToAttachments: false
    },
    anyLymphNodesInvolved: {
        value: '',
        referToAttachments: false,
        numLymphNodes: ''
    },
    anyMetastases: {
        value: '',
        referToAttachments: false
    },
    detailsOfTreatment: {
        value: '',
        referToAttachments: false
    },
    dateTreatmentFinished: {
        value: '',
        referToAttachments: false
    },
    completelyEradicated: {
        value: '',
        referToAttachments: false
    },
}

export default questions
