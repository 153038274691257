import useDateUtils from '../../../../../../../hooks/useDateUtils'

export default function Questions({ questions }) {
    const { formatDate } = useDateUtils()

    const isMainPractitioner = questions.mainPractitioner.value === 'true'
    const isAwaitingInvestigations = questions.awaitingInvestigations.value === 'true'

    return (
        <>
            <p>Are you the patient's main practitioner? {isMainPractitioner ? 'Yes' : 'No'}</p>
            {!isMainPractitioner
                ? <p>If no, please provide an explanation: {questions.mainPractitionerNotes.value}</p>
                : ''
            }
            <p>How far do your records go back: {formatDate(questions.earliestRecordDate.value)}</p>
            <p>Are the results of any referrals or tests awaited? {isAwaitingInvestigations ? 'Yes' : 'No'}</p>
            {isAwaitingInvestigations
                ? <p>If yes, please outline: {questions.awaitingInvestigationsNotes.value}</p>
                : ''
            }
        </>
    )
}
