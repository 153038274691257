export default function RenderField({ children }) {
    let value = null

    // Some values will be null so we need to check for this before rendering
    if (children != null) {
        value = children.value
    }

    return (
        <td>{value}</td>
    )
}

export function RenderFieldWithUnits({ children }) {
    let value = null;

    // Some values will be null so we need to check for this before rendering
    if (children != null) {
        value = `${children.value} ${children.units.text}`
    }

    return (
        <td>{value}</td>
    )
}