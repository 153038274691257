export default () => {

    function convertBase64StringToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    function convertArrayBufferToBase64String(buffer) {
        let binary = ""
        const bufferArray = new Uint8Array(buffer);
        bufferArray.forEach(function (index) {
            binary += String.fromCharCode(index)
        })

        return window.btoa(binary);
    }

    function convertBase64ToBlob(base64, contentType) {
        contentType = contentType || ''
        const sliceSize = 512;

        // Convert base64 to binary
        const byteCharacters = window.atob(base64)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }

        // Create the blob
        const blob = new Blob(byteArrays, {
            type: contentType
        })

        return blob;
    }

    return {
        convertBase64StringToArrayBuffer,
        convertArrayBufferToBase64String,
        convertBase64ToBlob
    }
}