import React from 'react'
import { useRecoilValue } from 'recoil'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SaveIcon from '@mui/icons-material/Save'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'

import { isUserTypeDoctorSelector } from '../../../../state/pmsState'
import useStepperFunctions from './useStepperFunctions'

export default function StepperNavigation({
    steps,
    activeStep,
    handlePrevious,
    handleNext,
    handleSave,
    handleSubmit
}) {
    const { isLastStep } = useStepperFunctions(steps)

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', p: 2 }}>
            <Button
                variant="contained"
                size="small"
                disabled={activeStep === 0}
                onClick={handlePrevious}
                startIcon={<NavigateBeforeIcon />}
            >
                Previous
            </Button>

            <Button
                variant="contained"
                size="small"
                disabled={activeStep === 0}
                onClick={handleSave}
                endIcon={<SaveIcon />}
            >
                Save for Later
            </Button>

            <NextButton
                isLastStep={isLastStep()}
                handleNext={handleNext}
                handleSubmit={handleSubmit}
            />
        </Box>
    )
}

const NextButton = ({ isLastStep, handleNext, handleSubmit }) => {
    const isUserDoctorType = useRecoilValue(isUserTypeDoctorSelector)

    if (!isLastStep) {
        return (
            <Button
                variant="contained"
                size="small"
                onClick={handleNext}
                endIcon={<NavigateNextIcon />}
            >
                Next
            </Button>
        )
    } else if (isLastStep && isUserDoctorType) {
        return (
            <Button
                variant="contained"
                size="small"
                endIcon={<ForwardToInboxIcon />}
                onClick={handleSubmit}
            >
                <span>Submit</span>
            </Button>
        )
    } else {
        return null
    }
}