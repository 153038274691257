import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import DatePicker from '../../../fieldComponents/table/TableDatePicker'


export default function AddMedicationItem({ onAdd }) {

    const initialValues = {
        date: null,
        note: ''
    }

    const validationSchema = Yup.object({
        date: Yup.string()
            .ensure()
            .required('Date is required')
            .test('isValidDate', 'Date must be in format DD/MM/YYYY', (val) => {
                if (!val) {
                    return false
                }

                return moment(val, 'DD/MM/yyyy', true).isValid()
            }),
        note: Yup.string().required('Medication is required')
    })

    function onSubmit(values, { resetForm }) {
        const medication = {
            value: `[${values.date}] ${values.note}`,
            addedOnPortal: true,
        }

        onAdd(medication)
        resetForm(initialValues)
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(formik) => {
                return (
                    <>
                        <TableCell>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <DatePicker
                                        date={formik.values.date}
                                        onChange={date => formik.setFieldValue('date', date)}
                                        error={formik.errors.date && formik.touched.date}
                                        helperText={formik.touched.date && formik.errors.date}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        size="small"
                                        multiline
                                        fullWidth
                                        name='note'
                                        value={formik.values.note}
                                        onChange={formik.handleChange}
                                        error={formik.errors.note && formik.touched.note}
                                        helperText={formik.touched.note && formik.errors.note}
                                    />
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell align="center" padding="checkbox">
                            <IconButton
                                size="small"
                                color="success"
                                onClick={formik.handleSubmit}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </TableCell>
                    </>)
            }}
        </Formik>
    )
}