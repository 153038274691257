import Question, { QuestionsContainer, QuestionWrapper } from '../components/output/Question'
import ReferrableQuestionWrapper from '../components/output/ReferrableQuestionWrapper'
import { formatDateMonthYear } from '../../../../../hooks/useDateUtils'

export default function HepatitisDetails({ data }) {

    var questions = data.conditionSpecific.questions

    return (
        <>
            <h3>Hepatitis Specific Questions</h3>

            <QuestionsContainer>

                <QuestionWrapper number='1'>
                    <Question>Date of diagnosis?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.dateOfDiagnosis.referToAttachments}>
                        <p>{formatDateMonthYear(questions.dateOfDiagnosis.value)}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='2'>
                    <Question>Please confirm exact type of hepatitis</Question>
                    <ReferrableQuestionWrapper isReferred={questions.typeOfHepatitis.referToAttachments}>
                        <p>{questions.typeOfHepatitis.value}</p>

                        {questions.typeOfHepatitis.value === '' && questions.typeOfHepatitis.details && (
                            <Question
                                answer={questions.typeOfHepatitis.details}
                            >
                                Other
                            </Question>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='3'>
                    <Question>Please provide details of most recent serology results</Question>
                    <ReferrableQuestionWrapper isReferred={questions.detailsOfSerologyResults.referToAttachments}>
                        <p>{questions.detailsOfSerologyResults.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='4'>
                    <Question>Has your patient had a liver ultrasound or fibroscan?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.hadLiverScan.referToAttachments}>
                        <p>{questions.hadLiverScan.value}</p>
                        {questions.hadLiverScan.value === 'Yes' && (
                            <>
                                <Question>If yes, please confirm results</Question>
                                <p>{questions.hadLiverScan.details}</p>
                            </>
                        )}
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='5'>
                    <Question>Please provide date and results of most recent liver function and alpha feta protein tests</Question>
                    <ReferrableQuestionWrapper isReferred={questions.detailsOfLiverFunction.referToAttachments}>
                        <p>{questions.detailsOfLiverFunction.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

                <QuestionWrapper number='6'>
                    <Question>How often do they attend the liver clinic?</Question>
                    <ReferrableQuestionWrapper isReferred={questions.howOftenAttendingLiverClinic.referToAttachments}>
                        <p>{questions.howOftenAttendingLiverClinic.value}</p>
                    </ReferrableQuestionWrapper>
                </QuestionWrapper>

            </QuestionsContainer>
        </>
    )
}