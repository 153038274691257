import { forwardRef } from 'react'
import { Formik, Form } from 'formik'

import FormikErrorNotifier from './FormikErrorNotifier'

function FormikWrapper({ initialValues, validation, onSubmit, children, setState }, ref) {

    //TODO: AJ - Commented parts of the method below to fix temporarily
    // Skipping the check for isDirty and just saving data everytime now even if it has not changed,
    // as it was causing a bug on the Condition Specific questionnaires when reopening an In Progress report
    // The bug caused nothing to happen When clicking Next button
    function onFormSubmit(values) {
        // // Check if form is dirty (values have changed)
        // const isDirty = values !== initialValues

        // if (isDirty) {
        // If dirty we need to update the state
        setState(values)

        // Raise submit with new values
        onSubmit(values)
        // } else {
        //     // Raise submit with no values as none have changed
        //     onSubmit(null)
        // }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onFormSubmit}
            enableReinitialize
            innerRef={ref}
        >
            {props => {
                return (
                    <Form id='myForm'>
                        <FormikErrorNotifier isSubmitting={props.isSubmitting} isValid={props.isValid} />
                        {children(props)}
                    </Form>
                )
            }}
        </Formik>
    )
}


export default forwardRef(FormikWrapper)