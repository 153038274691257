import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import DetailsControl from '../../formComponents/conditionSpecific/details/DetailsControl'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'

import { DependentInput } from '../../fieldComponents'

import { yesNoOptions, cvaTypeOptions } from '../../../../../constants'

export default function StrokeQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please confirm the exact type of CVA that your patient has suffered from?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='cvaType' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyRadioGroup
                        name='questions.cvaType.value'
                        disabled={formik.values.questions?.cvaType.referToAttachments}
                        options={cvaTypeOptions}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant='body2'>
                        Date of diagnosis?
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <DateField
                        name='questions.dateOfDiagnosis.value'
                        disabled={formik.values.questions?.dateOfDiagnosis?.referToAttachments}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='dateOfDiagnosis' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1} />

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Was there more than one event?
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.moreThenOneEvent.value'
                        disabled={formik.values.questions?.moreThenOneEvent?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='moreThenOneEvent' />
                </Grid>

                <DependentInput condition={formik.values.questions.moreThenOneEvent.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please provide details and dates:
                        </Typography>

                        <DetailsControl
                            name='questions.moreThenOneEvent.details'
                            disabled={formik.values.questions?.moreThenOneEvent?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please provide any further relevant details in relation to the cause of the CVA?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='furtherRelevantDetails' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.furtherRelevantDetails.value'
                        disabled={formik.values.questions?.furtherRelevantDetails?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please describe your patients presenting symptoms?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='patientsPresentingSymptoms' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.patientsPresentingSymptoms.value'
                        disabled={formik.values.questions?.patientsPresentingSymptoms?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Did symptoms completely resolve?
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MyRadioGroup
                        name='questions.didSymptomsCompletelyResolve.value'
                        disabled={formik.values.questions?.didSymptomsCompletelyResolve?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='didSymptomsCompletelyResolve' />
                </Grid>


                <DependentInput condition={formik.values.questions.didSymptomsCompletelyResolve.value == 'No'}>
                    <Grid item xs={1} />
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If no, please describe the residual symptoms and the resulting degree of disability:
                        </Typography>

                        <MyTextField
                            name='questions.didSymptomsCompletelyResolve.details'
                            disabled={formik.values.questions?.didSymptomsCompletelyResolve?.referToAttachments}
                        />
                    </Grid>
                </DependentInput>

            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        5.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please confirm the date & results of most recent MRI or CT scans?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='mostRecentMriOrCtScanNotes' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.mostRecentMriOrCtScanNotes.value'
                        disabled={formik.values.questions?.mostRecentMriOrCtScanNotes?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        6.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Does your patient have any other circulatory or vascular risk factors that you are aware of?
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='otherRiskFactors' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <MyRadioGroup
                        name='questions.otherRiskFactors.value'
                        disabled={formik.values.questions?.otherRiskFactors?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <DependentInput condition={formik.values.questions.otherRiskFactors.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm details
                        </Typography>

                        <MyTextField
                            name='questions.otherRiskFactors.details'
                            disabled={formik.values.questions?.otherRiskFactors?.referToAttachments}
                        />
                    </Grid>
                </DependentInput>
            </Grid>

        </Grid>
    )
}