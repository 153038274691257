import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'

import { msTypeOptions, msDegreeOfDisabilityOptions } from '../../../../../constants'

export default function MultipleSclerosisQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date of diagnosis?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.dateOfDiagnosis.value'
                        disabled={formik.values.questions?.dateOfDiagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='dateOfDiagnosis' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Exact type of Multiple Sclerosis?
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='exactTypeOfMS' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs>
                    <MyRadioGroup
                        name='questions.exactTypeOfMS.value'
                        disabled={formik.values.questions?.exactTypeOfMS.referToAttachments}
                        options={msTypeOptions}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date of last attack/flare up?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.dateOfLastFlareUp.value'
                        disabled={formik.values.questions?.dateOfLastFlareUp?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='dateOfLastFlareUp' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Degree of disability?
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='degreeOfDisability' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs>
                    <MyRadioGroup
                        name='questions.degreeOfDisability.value'
                        disabled={formik.values.questions?.degreeOfDisability.referToAttachments}
                        options={msDegreeOfDisabilityOptions}
                    />
                </Grid>

            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        5.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please describe the impact of current symptoms on activities of daily living
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='impactOfCurrentSymtoms' />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                    <MyTextField
                        name='questions.impactOfCurrentSymtoms.value'
                        disabled={formik.values.questions?.impactOfCurrentSymtoms?.referToAttachments}
                    />
                </Grid>
            </Grid>

        </Grid>
    )
}