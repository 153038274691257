import moment from 'moment'

export default function OutputObxValue({ children }) {
    if (children === null) {
        // Some values will be null so we need to check for this before rendering
        return null
    } else if (children.units?.text !== null) {
        // Some fields will have units
        return `${children.value} ${children.units.text}`
    } else {
        // Otherwise return the value only
        return children.value
    }
}

export function OutputObxDate({ field }) {
    // Check for null fields
    if (field) {
        return (
            <>....on {moment(field.observationDateTime).format('DD/MM/yyyy')}</>
        )
    } else {
        return 'no record'
    }
}