import { atom, selector } from 'recoil'

export const pmsUserAtom = atom({
    key: 'pmsUser',
    default: {
        firstName: '',
        lastName: '',
        mcn: '',
        isDoctor: false
    }
})

export const isUserTypeDoctorSelector = selector({
    key: 'isUserTypeDoctor',
    get: ({ get }) => get(pmsUserAtom).isDoctor
})

export const userMcnSelector = selector({
    key: 'pmsUserMcn',
    get: ({ get }) => get(pmsUserAtom).mcn
})
