import client from './client'

const getRequest = (requestId) => client.get(`/requests/${requestId}`)

const updateRequest = (requestId, body) => client.patch(`/requests/${requestId}`, body)

const submitRequest = (requestId, body) => client.patch(`/requests/${requestId}/submit`, body)

export default {
    getRequest,
    updateRequest,
    submitRequest
}