import { Routes, Route } from 'react-router'

import Layout from '../layout'
import { Request, RequestError } from '../views'


export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path='request/error' element={<RequestError />} />
                <Route path='requests/:requestId' element={<Request />} />
            </Route>
        </Routes>
    )
}