export default () => {

    function isArrayEmpty(arr) {
        if (Array.isArray(arr) && !arr.length) {
            return true
        } else {
            return false
        }
    }

    return {
        isArrayEmpty
    }
}