import { renderToStaticMarkup } from 'react-dom/server'
import pdfMake from 'pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts"
import htmlToPdfMake from 'html-to-pdfmake'
import pdfMerger from 'pdf-merger-js/browser'

import Template from '../views/request/preview/Template'
import invoicePdfDocDefinition from '../views/request/invoice/invoiceDocDefinition'


pdfMake.vfs = pdfFonts.pdfMake.vfs

export default () => {

    /**
     * Generates pdf of report data
     * @param data Report data for request
     * @returns Pdf report as a Buffer
     */
    const generateOutputPdfBuffer = async (data) => {
        const { formType, title, formData } = data

        // Get the react component for the output template
        const template = <Template templateType={formType} data={formData} />
        // Generate html from the react component
        const templateAsHtml = renderToStaticMarkup(template)

        // Configure pdf metadata
        const pdfInfo = { title: title }

        // Generate the PDF from the html
        const pdfContent = htmlToPdfMake(templateAsHtml)
        const dd = { info: pdfInfo, content: pdfContent }
        const pdf = pdfMake.createPdf(dd)

        // Get the PDF as a buffer
        const buffer = await getBuffer(pdf)

        // Return the buffer
        return buffer
    }

    /**
     * Wraps third party callback based function getBuffer, so it can be called as a promise based function
     * @param pdf Third party pdf object
     * @returns Promise based result of calling getBuffer function
     */
    const getBuffer = (pdf) => {
        return new Promise((resolve, reject) => {
            try {
                pdf.getBuffer(buffer => resolve(buffer))
            } catch (error) {
                reject(error)
            }
        })
    }

    /**
     * Merge multiple pdfs into one.
     * @param buffers Accepts an array of Buffers
     * @param title Title of the
     * @returns A single Buffer
     */
    const mergePdfs = async (buffers, title) => {
        const merger = new pdfMerger()

        for (const buffer of buffers) {
            await merger.add(buffer)
        }

        await merger.setMetadata({ title: title });

        return await merger.saveAsBuffer()
    }

    /**
     * Generates pdf using from supplied data & invoice template
     * @param data Invoice data for request
     * @param callback Callback to receive pdf in base64 format
     */
    const generateInvoicePdf = (data, callback) => {
        // Generate the document definition using the data
        const dd = invoicePdfDocDefinition(data)

        // Generate the PDF from the document definition
        const pdf = pdfMake.createPdf(dd)

        // Get base 64 string from the PDF
        pdf.getBase64(base64 => callback(base64))
    }

    return {
        generateOutputPdfBuffer,
        mergePdfs,
        generateInvoicePdf,
    }
}