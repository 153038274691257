import { string, object } from 'yup'

import { yesNoValidOptions } from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object({

        diagnosis: object({
            subQuestions: object()
                .when('referToAttachments', {
                    is: false,
                    then: object({
                        exactDiagnosis: string().required('Please enter details'),
                        anyUnderlyingCauses: string().required('Please enter details'),
                        presentingSymptoms: string().required('Please enter details')
                    })
                })
        }),

        symptoms: object({
            subQuestions: object()
                .when('referToAttachments', {
                    is: false,
                    then: object({
                        dateOfDiagnosis: string().ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate(),
                        dateOfLastSymptoms: string().ensure()
                            .required('Date is required')
                            .isValidDate()
                            .isPastDate(),
                        natureOfLastSymptoms: string().required('Please enter details')
                    })
                })
        }),

        rftBloodPressureUrinalysisResults: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        otherInvestigationResults: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        inPatientTreatmentRequired: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        }),

        anyAssociatedMedicalConditions: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        }),

        anyTimeOffWorkCaused: object({
            value: string()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string()
                    .nullable()
                    .required('Please enter details')
            })
        })

    })
})

export default validation