import { useRecoilValue } from 'recoil'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'

import { labsSearchSelector } from '../../../formComponents/laboratoryStudies/state'


export default function LabList(props) {
    const labResults = useRecoilValue(labsSearchSelector)

    const rows = labResults.map((r, idx) => ({ id: idx, ...r }))

    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'date', headerName: 'Date', width: 120, valueFormatter: params => moment(params?.value).format("DD/MM/YYYY"), },
        { field: 'identifier', headerName: 'Identifier', flex: 1 },
        { field: 'value', headerName: 'Value', flex: 0.7, valueGetter: (params) => `${params.row.value} ${params.row.units}`, }
    ]

    const onSelectionChanged = (ids) => {
        const selectedRows = rows.filter(r => ids.includes(r.id))

        props.onSelectionChange(selectedRows)
    }

    return (
        <div style={{ height: '90%', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                    sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                }}
                checkboxSelection
                onSelectionModelChange={onSelectionChanged}
            />
        </div>
    )
}