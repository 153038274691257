import moment from 'moment'
import RenderField from '../RenderField'

import useArrayUtils from '../../../../../../../hooks/useArrayUtils'

export default function Observations({ observations }) {
    const { isArrayEmpty } = useArrayUtils()

    return (
        <table>
            <thead>
                <tr>
                    <th />
                    <th colSpan={3}>Tobacco</th>
                    <th colSpan={2}>Alcohol</th>
                </tr>
                <tr>
                    <th>Date</th>
                    <th>History of Tobacco</th>
                    <th>Cigs per day</th>
                    <th>Years smoking</th>
                    <th>History of Alcohol</th>
                    <th>Units per week</th>
                </tr>
            </thead>
            <tbody>
                {isArrayEmpty(observations) && (
                    <tr><td colSpan={6}>No information available</td></tr>
                )}
                {observations.map((observation, idx) => (
                    <tr key={idx}>
                        <td><strong>{moment(observation.observationDateTime).format('DD/MM/yyyy')}</strong></td>
                        <RenderField>{observation.historyOfTobaccoUse}</RenderField>
                        <RenderField>{observation.cigarettesSmokedPerDay}</RenderField>
                        <RenderField>{observation.yearsSmoking}</RenderField>
                        <RenderField>{observation.historyOfAlcoholUse}</RenderField>
                        <RenderField>{observation.unitOfAlcoholPerWeek}</RenderField>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
