import moment from 'moment'

import { Address, PersonName } from '../../../components'

function PatientDetails({ data }) {
    const patient = data.patient

    return (
        <>
            <h3>Patient Details</h3>
            <PersonName name={patient.patientName} />
            <Address address={patient.address} />
            <span>Date of Birth: {moment(patient.dateOfBirth).format('DD/MM/yyyy')}</span>
        </>
    )
}

export default PatientDetails