import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { FastField, useFormikContext } from 'formik'

import { DependentInput, RadioGroupInput, DateInput } from '../../../fieldComponents'

const radioOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
    { value: "unknown", label: "Unknown" }
]

export default function Questions() {
    const { values, touched, errors } = useFormikContext()

    return (
        <Grid container spacing={2} sx={{ m: 1, mb: 3 }}>
            <Grid item xs={4}>
                <Typography variant='body2'>
                    Any use of Nicotine replacement products?
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <RadioGroupInput
                    name='questions.nicotineReplacementTherapy.value'
                    options={radioOptions}
                    error={errors?.questions?.nicotineReplacementTherapy?.value && touched?.questions?.nicotineReplacementTherapy?.value}
                    helperText={touched?.questions?.nicotineReplacementTherapy?.value && errors?.questions?.nicotineReplacementTherapy?.value}
                />
            </Grid>

            <DependentInput condition={values.questions.nicotineReplacementTherapy.value === 'true'}>
                <Grid item xs={4}>
                    <Typography variant='body2'>
                        Most recently recorded
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FastField
                        as={DateInput}
                        name='questions.nicotineReplacementMostRecentlyRecordedDate.value'

                        error={errors?.questions?.nicotineReplacementMostRecentlyRecordedDate?.value && touched?.questions?.nicotineReplacementMostRecentlyRecordedDate?.value}
                        helperText={touched?.questions?.nicotineReplacementMostRecentlyRecordedDate?.value && errors?.questions?.nicotineReplacementMostRecentlyRecordedDate?.value}
                    />
                </Grid>
            </DependentInput>

            <Grid item xs={4}>
                <Typography variant='body2'>
                    Is there any indication of past or present misuse of alcohol or drugs?
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <RadioGroupInput
                    name='questions.historyOfDrugsUse.value'
                    options={radioOptions}

                    error={errors?.questions?.historyOfDrugsUse?.value && touched?.questions?.historyOfDrugsUse?.value}
                    helperText={touched?.questions?.historyOfDrugsUse?.value && errors?.questions?.historyOfDrugsUse?.value}
                />
            </Grid>

            <DependentInput condition={values.questions.historyOfDrugsUse.value === 'true'}>
                <Grid item xs={4}>
                    <Typography variant='body2'>
                        If yes, please outline
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FastField
                        as={TextField}
                        size='small'
                        fullWidth
                        multiline
                        name='questions.historyOfDrugsUseNotes.value'

                        error={errors?.questions?.historyOfDrugsUseNotes?.value && touched?.questions?.historyOfDrugsUseNotes?.value}
                        helperText={touched?.questions?.historyOfDrugsUseNotes?.value && errors?.questions?.historyOfDrugsUseNotes?.value}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Typography variant='body2'>
                        Alcohol Misuse Start Date
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FastField
                        as={DateInput}
                        name='questions.historyOfAlcoholUseStartDate.value'

                        error={errors?.questions?.historyOfAlcoholUseStartDate?.value && touched?.questions?.historyOfAlcoholUseStartDate?.value}
                        helperText={touched?.questions?.historyOfAlcoholUseStartDate?.value && errors?.questions?.historyOfAlcoholUseStartDate?.value}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Typography variant='body2'>
                        Alcohol Misuse End Date
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FastField
                        as={DateInput}
                        name='questions.historyOfAlcoholUseEndDate.value'

                        error={errors?.questions?.historyOfAlcoholUseEndDate?.value && touched?.questions?.historyOfAlcoholUseEndDate?.value}
                        helperText={touched?.questions?.historyOfAlcoholUseEndDate?.value && errors?.questions?.historyOfAlcoholUseEndDate?.value}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Typography variant='body2'>
                        Drug Misuse Start Date
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FastField
                        as={DateInput}
                        name='questions.historyOfDrugsUseStartDate.value'

                        error={errors?.questions?.historyOfDrugsUseStartDate?.value && touched?.questions?.historyOfDrugsUseStartDate?.value}
                        helperText={touched?.questions?.historyOfDrugsUseStartDate?.value && errors?.questions?.historyOfDrugsUseStartDate?.value}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Typography variant='body2'>
                        Drug Misuse End Date
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FastField
                        as={DateInput}
                        name='questions.historyOfDrugsUseEndDate.value'

                        error={errors?.questions?.historyOfDrugsUseEndDate?.value && touched?.questions?.historyOfDrugsUseEndDate?.value}
                        helperText={touched?.questions?.historyOfDrugsUseEndDate?.value && errors?.questions?.historyOfDrugsUseEndDate?.value}
                    />
                </Grid>
            </DependentInput>
        </Grid>
    )
}