import { atom, selector } from 'recoil'

const attachmentsAtom = atom({
    key: 'attachments',
    default: {}
})

export const attachmentFilesAtom = atom({
    key: 'attachmentFiles',
    default: []
})

export const attachmentFileNamesSelector = selector({
    key: 'attachmentFileNames',
    get: ({ get }) => get(attachmentFilesAtom).map(a => a.name)
})

export default attachmentsAtom