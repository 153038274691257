import Component from './MentalHealth'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./mentalHealth.css');
var css = cssModule.default;

const mentalHealthConfig = {
    component: Component,
    css: css
}

export default mentalHealthConfig