import { useRecoilValue } from 'recoil'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { requestAtom } from 'src/views/request/requestState'
import { InsurerNote } from '../../fieldComponents'
import Questions from './Questions'
import FileAttachmentField from '../attachments/FileAttachments/FileAttachmentField'

function MissingInformationBody() {

    // Get request context to access insurer note for request
    const request = useRecoilValue(requestAtom)

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="subtitle2" mx={1}>
                    Thank you for your recent report.
                </Typography>
            </Grid>

            {/* Output insurer notes */}
            <Grid item xs={12}>
                <InsurerNote note={request.insurerNotes} />
            </Grid>

            <Grid item xs={12}>
                <Questions />
            </Grid>

            <FileAttachmentField />
        </Grid>
    )
}

export default MissingInformationBody
