import { DatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { useField } from 'formik'

export default function DateInput(props) {
    const { error, helperText } = props
    const [field, _, { setValue }] = useField(props.name)
    
    return (
        <DatePicker
            {...props}
            value={field.value}
            onChange={(val) => {
                if (val) {
                    val = val.toDate()
                }

                setValue(val)
            }}
            inputFormat="DD/MM/yyyy"
            renderInput={(params) => <TextField {...params} size="small" error={error} helperText={helperText} />}
        />
    )
}