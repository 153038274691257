const questions = {
    initialStage: {
        value: '',
        referToAttachments: false,
        other: ''
    },
    complications: {
        value: '',
        referToAttachments: false,
        details: '',
        other: ''
    },
    lesions: {
        value: '',
        referToAttachments: false
    },
    treatment: {
        value: '',
        referToAttachments: false,
        corticosteroids: '',
        secondLineAgents: '',
        other: ''
    }
}

export default questions
