import {
    Review,
    Submit,
    MissingInformation
} from '../../formComponents'

const missingInformationConfig = {
    title: 'Missing Information Request',
    steps: [
        {
            label: "Missing Information",
            key: 'MissingInformation',
            component: MissingInformation,
            hasForm: true,
            restrictToDoctor: false
        },
        {
            label: 'Review',
            key: 'Review',
            component: Review,
            hasForm: false,
            restrictToDoctor: false
        },
        {
            label: 'Submit',
            key: 'Submit',
            component: Submit,
            hasForm: false,
            restrictToDoctor: true
        }
    ]
}

export default missingInformationConfig