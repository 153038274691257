const questions = {
    dateOfDiagnosis: {
        value: '',
        referToAttachments: false
    },
    typeOfHepatitis: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    detailsOfSerologyResults: {
        value: '',
        referToAttachments: false
    },
    hadLiverScan: {
        value: '',
        referToAttachments: false,
        details: ''
    },
    detailsOfLiverFunction: {
        value: '',
        referToAttachments: false
    },
    howOftenAttendingLiverClinic: {
        value: '',
        referToAttachments: false
    }
}

export default questions
