import useStringUtils from '../../../../hooks/useStringUtils'

export default function PersonName({ name }) {
    const { formatName } = useStringUtils()

    const fullname = formatName(name)

    return (
        <span>{fullname}</span>
    )
}