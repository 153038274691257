import { forwardRef } from 'react'
import { useRecoilState } from 'recoil'

import atom from '../../formComponents/conditionSpecific/state'
import FormikWrapper from '../../formComponents/generic/FormikWrapper'
import ConditionSpecific from '../../formComponents/conditionSpecific/ConditionSpecific'

import validation from './validation'
import Questions from './Questions'


function MultipleSclerosisComponent(props, ref) {
    const [state, setState] = useRecoilState(atom)

    // Read initial values from state
    var initialValues = JSON.parse(JSON.stringify(state))

    function onSubmit(e) {
        const stringifiedQuestions = JSON.stringify(e.questions)

        const model = {
            mostRecentSummary: e.mostRecentSummary,
            medications: e.medications,
            questions: stringifiedQuestions
        }

        props.onSubmit(model)
    }

    return (
        <FormikWrapper
            initialValues={initialValues}
            validation={validation}
            onSubmit={onSubmit}
            setState={setState}
            ref={ref}
        >
            {() => (
                <ConditionSpecific
                    conditionSpecificNote='This questionnaire is specific to your patient’s Multiple Sclerosis history only. Please attach any relevant reports you may possess.'
                    Questions={<Questions />}
                />
            )}
        </FormikWrapper>
    )
}

export default forwardRef(MultipleSclerosisComponent)
