import Component from './Kidney'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./kidney.css');
var css = cssModule.default;

const kidneyConfig = {
    component: Component,
    css: css
}

export default kidneyConfig