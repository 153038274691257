import Component from './Respiratory'

/* eslint import/no-webpack-loader-syntax: off */
var cssModule = require('!!raw-loader!./respiratory.css');
var css = cssModule.default;

const respiratoryConfig = {
    component: Component,
    css: css
}

export default respiratoryConfig