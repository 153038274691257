import { string, object } from 'yup'

import { hepatitisTypeValidOptions, yesNoValidOptions } from '../../../../../constants'

import '../../../../../validations'

const validation = object().shape({
    questions: object({

        dateOfDiagnosis: object({
            value: string()
                .when('referToAttachments', {
                    is: false,
                    then: string().ensure()
                        .required('Date is required')
                        .isValidDate()
                        .isPastDate()
                })
        }),

        typeOfHepatitis: object({
            value: string()
                .nullable()
                .oneOf(hepatitisTypeValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && (value === '' || value == null),
                then: string()
                    .nullable()
                    .required('Please enter other details when no type is selected')
            })
        }),

        detailsOfSerologyResults: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        hadLiverScan: object({
            value: string().required()
                .required('Please select a value')
                .oneOf(yesNoValidOptions),
            details: string().when(['referToAttachments', 'value'], {
                is: (referToAttachments, value) => referToAttachments === false && value !== 'No',
                then: string().required('Please confirm results.')
            })
        }),

        detailsOfLiverFunction: object({
            value: string()
                .nullable()
                .required('Please enter details')
        }),

        howOftenAttendingLiverClinic: object({
            value: string()
                .nullable()
                .required('Please enter details')
        })
        
    })
})

export default validation