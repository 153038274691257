import { useFormikContext } from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ReferToAttachmentsButton from '../../formComponents/conditionSpecific/ReferToAttachmentsButton'
import DetailsControl from '../../formComponents/conditionSpecific/details/DetailsControl'
import { DateField, MyTextField, MyRadioGroup } from '../../formComponents/conditionSpecific/Fields'

import { DependentInput } from '../../fieldComponents'
import {
    yesNoOptions,
    respiratoryConditionOptions,
    respiratoryConstantRecurrentOptions,
    respiratorySeverityOptions,
    respiratorySymptomsOptions
} from '../../../../../constants'

export default function RespiratoryQuestions() {
    const formik = useFormikContext()

    return (
        <Grid container rowSpacing={5}>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        1.
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Nature of respiratory condition:
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='natureOfCondition' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <MyRadioGroup
                        name='questions.natureOfCondition.value'
                        disabled={formik.values.questions?.natureOfCondition.referToAttachments}
                        options={respiratoryConditionOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        Other
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <MyTextField
                        name='questions.natureOfCondition.details'
                        disabled={formik.values.questions?.natureOfCondition?.referToAttachments}
                    />
                </Grid>
            </Grid>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        2.
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date of diagnosis:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.diagnosis.subQuestions.dateofDiagnosis'
                        disabled={formik.values.questions?.diagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='diagnosis' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Date of last exacerbation:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateField
                        name='questions.diagnosis.subQuestions.dateOfLastExacerbation'
                        disabled={formik.values.questions?.diagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={2} />

                <Grid item xs={1} />
                <Grid item xs={9}>
                    <MyRadioGroup
                        name='questions.diagnosis.subQuestions.constantOrRecurrent'
                        disabled={formik.values.questions?.diagnosis?.referToAttachments}
                        options={respiratoryConstantRecurrentOptions}
                    />
                </Grid>
                <Grid item xs={2} />

                <Grid item xs={1} />
                <Grid item xs={6}>
                    <Typography variant='body2'>
                        Length of last symptoms
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyTextField
                        name='questions.diagnosis.subQuestions.lengthOfLastSymptoms'
                        disabled={formik.values.questions?.diagnosis?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={2} />
            </Grid>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        3.
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant='body2'>
                        Is the condition:
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <MyRadioGroup
                        name='questions.condition.subQuestions.severity'
                        disabled={formik.values.questions?.condition.referToAttachments}
                        options={respiratorySeverityOptions}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='condition' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please describe your patient’s functional capacity/exercise tolerance
                    </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <MyTextField
                        name='questions.condition.subQuestions.functionalCapacity'
                        disabled={formik.values.questions?.condition.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={2}>
                    <Typography variant='body2'>
                        Are symptoms:
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <MyRadioGroup
                        name='questions.condition.subQuestions.symptomsProgression'
                        disabled={formik.values.questions?.condition.referToAttachments}
                        options={respiratorySymptomsOptions}
                    />
                </Grid>
                <Grid item xs={1} />

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please describe your patient’s stage as per the NYHA ?
                    </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <MyTextField
                        name='questions.condition.subQuestions.describeNYHAStage'
                        disabled={formik.values.questions?.condition.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
            </Grid>

            <Grid item container rowSpacing={1}>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        4.
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='body2'>
                        Please confirm the date & results of last CXR, PFTs (FEV1 and/or PEF), CT scan:
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='recentResults' />
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={10}>
                    <DetailsControl
                        name='questions.recentResults.details'
                        disabled={formik.values.questions?.recentResults?.referToAttachments}
                    />
                </Grid>
                <Grid item xs={1} />
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        5.
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant='body2'>
                        Has your patient required inpatient treatment for their respiratory condition?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyRadioGroup
                        name='questions.inpatientTreatmentRequired.value'
                        disabled={formik.values.questions?.inpatientTreatmentRequired?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='inpatientTreatmentRequired' />
                </Grid>

                <DependentInput condition={formik.values.questions.inpatientTreatmentRequired.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm details
                        </Typography>

                        <MyTextField
                            name='questions.inpatientTreatmentRequired.details'
                            disabled={formik.values.questions?.inpatientTreatmentRequired?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        6.
                    </Typography>
                </Grid>
                <Grid item container xs={10} rowSpacing={1}>
                    <Grid item container>
                        <Grid item xs={8}>
                            <Typography variant='body2'>
                                Does your patient smoke?
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <MyRadioGroup
                                name='questions.smoking.subQuestions.isSmoker'
                                disabled={formik.values.questions?.smoking?.referToAttachments}
                                options={yesNoOptions}
                            />
                        </Grid>
                    </Grid>

                    <DependentInput condition={formik.values.questions.smoking.subQuestions.isSmoker == 'Yes'}>
                        <Grid item container>
                            <Grid item xs={8}>
                                <Typography variant='body2'>
                                    If yes, how many per day?
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <MyTextField
                                    name='questions.smoking.subQuestions.cigarettesPerDay'
                                    disabled={formik.values.questions?.smoking?.referToAttachments}
                                />
                            </Grid>
                        </Grid>
                    </DependentInput>

                    <DependentInput condition={formik.values.questions.smoking.subQuestions.isSmoker == 'No'}>
                        <Grid item container>
                            <Grid item xs={8}>
                                <Typography variant='body2'>
                                    Are they a previous smoker?
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <MyRadioGroup
                                    name='questions.smoking.subQuestions.isPreviousSmoker'
                                    disabled={formik.values.questions?.smoking?.referToAttachments}
                                    options={yesNoOptions}
                                />
                            </Grid>
                        </Grid>

                        <DependentInput condition={formik.values.questions.smoking.subQuestions.isPreviousSmoker == 'Yes'}>
                            <Grid item xs={8}>
                                <Typography variant='body2'>
                                    If yes, how many per day for how long?
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <MyTextField
                                    name='questions.smoking.subQuestions.previousSmokerDetails'
                                    disabled={formik.values.questions?.smoking?.referToAttachments}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <Typography variant='body2'>
                                    Date patient ceased smoking:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <DateField
                                    name='questions.smoking.subQuestions.dateCeasedSmoking'
                                    disabled={formik.values.questions?.smoking?.referToAttachments}
                                />
                            </Grid>
                        </DependentInput>
                    </DependentInput>
                </Grid>
                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='smoking' />
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        7.
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant='body2'>
                        Has your patient required surgery?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyRadioGroup
                        name='questions.patientSurgery.value'
                        disabled={formik.values.questions?.patientSurgery?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='patientSurgery' />
                </Grid>

                <DependentInput condition={formik.values.questions.patientSurgery.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm details
                        </Typography>

                        <MyTextField
                            name='questions.patientSurgery.details'
                            disabled={formik.values.questions?.patientSurgery?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        8.
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant='body2'>
                        Any associated medical conditions or complications?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyRadioGroup
                        name='questions.anyAssociatedConditionsOrComplications.value'
                        disabled={formik.values.questions?.anyAssociatedConditionsOrComplications?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyAssociatedConditionsOrComplications' />
                </Grid>

                <DependentInput condition={formik.values.questions.anyAssociatedConditionsOrComplications.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm details
                        </Typography>

                        <MyTextField
                            name='questions.anyAssociatedConditionsOrComplications.details'
                            disabled={formik.values.questions?.anyAssociatedConditionsOrComplications?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

            <Grid item container>
                <Grid item xs={1}>
                    <Typography variant='body2'>
                        9.
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant='body2'>
                        Has the condition caused any time off work?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <MyRadioGroup
                        name='questions.anyTimeOffWorkCaused.value'
                        disabled={formik.values.questions?.anyTimeOffWorkCaused?.referToAttachments}
                        options={yesNoOptions}
                    />
                </Grid>

                <Grid item xs={1}>
                    <ReferToAttachmentsButton name='anyTimeOffWorkCaused' />
                </Grid>

                <DependentInput condition={formik.values.questions.anyTimeOffWorkCaused.value == 'Yes'}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <Typography variant='body2' gutterBottom>
                            If yes, please confirm details
                        </Typography>

                        <MyTextField
                            name='questions.anyTimeOffWorkCaused.details'
                            disabled={formik.values.questions?.anyTimeOffWorkCaused?.referToAttachments}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </DependentInput>
            </Grid>

        </Grid>
    )
}