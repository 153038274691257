import { ThemeProvider, createTheme } from '@mui/material/styles'

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    '&.Mui-selected, &.Mui-selected:hover': {
                        color: "white",
                        backgroundColor: '#5261AC'
                    }
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    '&.Mui-disabled': {
                        backgroundColor: '#e4e4e4',
                    },
                },
            },
        }
    },
    palette: {
        primary: {
            main: '#5261AC'
        }
    }
});

export default function AppTheme({ children }) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}